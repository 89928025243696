<template v-slot:top>
  <v-card elevation="3" outlined tile class="mr-4 ml-4 mt-4">
    <v-toolbar color="grey lighten-3 mb-2" flat>
      <v-toolbar-title>Income Export Report</v-toolbar-title>
    </v-toolbar>

    <!-- Filter Start -->
    <v-row class="pa-4">
      <v-col cols="12" md="3">
        <v-autocomplete
          v-model="accountType"
          :items="accountTypes"
          label="Select Account Type"
          dense
          filled
          @input="fetchTypedAccounts(accountType)"
          required
        ></v-autocomplete>
      </v-col>
      <v-col cols="12" md="3">
        <v-autocomplete
          v-model="account"
          :items="accounts"
          label="Select Account"
          required
          dense
          filled
        ></v-autocomplete>
      </v-col>
      <v-col cols="12" md="3">
        <v-dialog
          ref="dialog"
          v-model="modal"
          :return-value.sync="dates"
          persistent
          width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="dateRangeText"
              label="Select date range"
              readonly
              dense
              filled
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker v-model="dates" range scrollable>
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="modal = false"> Cancel </v-btn>
            <v-btn text color="primary" @click="$refs.dialog.save(dates)">
              OK
            </v-btn>
          </v-date-picker>
        </v-dialog>
      </v-col>
      <v-col cols="12" md="3">
        <v-btn
          class="ma-2"
          :loading="loading4"
          :disabled="disableSearch"
          color="info"
          depressed
          tile
          @click="fetchAccountData(account)"
        >
          Search
          <template v-slot:loader>
            <span class="custom-loader">
              <v-icon light>mdi-cached</v-icon>
            </span>
          </template>
        </v-btn>

        <v-btn
          class="ma-2"
          :loading="loading6"
          color="info"
          @click="markAsIncome"
          target="_blank"
          :disabled="disabledIncome"
          depressed
          tile
        >
          Mark as Income
          <template v-slot:loader>
            <span class="custom-loader">
              <v-icon light>mdi-cached</v-icon>
            </span>
          </template>
        </v-btn>
      </v-col>

      <v-col cols="9">
        <v-autocomplete
          class="ml-auto"
          style="width: 240px"
          v-model="downloadType"
          :items="getDownloadType"
          label="Select Download Type"
          required
          dense
          filled
        ></v-autocomplete>
      </v-col>
      <v-col cols="3">
        <v-btn
          class="ma-2"
          :loading="loading2"
          :disabled="disableSearch || disableDriveButtons"
          color="success darken-2"
          @click="downloadFileType"
          target="_blank"
          depressed
          tile
        >
          Download
          <template v-slot:loader>
            <span class="custom-loader">
              <v-icon light>mdi-cached</v-icon>
            </span>
          </template>
        </v-btn>

        <v-btn
          class="ma-2"
          :loading="loading7"
          :disabled="disableSearch || disableDriveButtons"
          color="primary darken-5"
          @click="$refs.googleDrive.getRootFolder()"
          target="_blank"
          depressed
          tile
        >
          Save to Google Drive
          <template v-slot:loader>
            <span class="custom-loader">
              <v-icon light>mdi-cached</v-icon>
            </span>
          </template>
        </v-btn>
      </v-col>
    </v-row>
    <!-- Filter End -->

    <hr class="grey mb-4" style="opacity: 0.05" />

    <!-- Balance Sheet Start -->
    <h3 align="center">Balance Sheet</h3>
    <v-row class="pa-3">
      <v-col cols="12" md="6">
        <p class="text-center text-decoration-underline h4">Credits</p>
        <v-simple-table>
          <template v-slot:default>
            <tbody>
              <tr style="cursor: pointer">
                <th>Opening Balance</th>
                <td>{{ openingBalance }}</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
        <!-- @click:row="handleClick" -->
        <v-data-table
          :headers="toHeaders"
          :footer-props="{
            'items-per-page-options': [10, 20, 30, 40, -1],
          }"
          :items-per-page="40"
          item-key="tr_id"
          :items="toTransaction"
          v-model="multiToSelect"
          show-select
        >
          <template v-slot:[`item.amount`]="{ item }">
            <v-chip>{{ item.amount }}</v-chip>
          </template>

          <template v-slot:[`item.actions`]="{ item }">
            <v-icon small class="mr-2" @click="openCTCPopup(item)"> mdi-pencil </v-icon>

            <v-dialog v-model="ctcDialog" persistent max-width="290">
              <v-card>
                <v-card-text>
                  <v-card-title>Edit CTC</v-card-title>
                 <v-text-field
                    v-model="editCTCData.ctc"
                    label="CTC"
                    required
                  ></v-text-field>

                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="green darken-1"
                    text
                    @click="closeCTCPopup()"
                  >
                    Close
                  </v-btn>
                  <v-btn color="green darken-1" text @click="saveCTC()">
                    Edit
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </template>

          <template slot="body.append">
            <tr class="grey lighten-5">
              <td></td>
              <td></td>
              <th>Total Credits</th>
              <td>
                <v-chip color="green" dark>
                  {{ sumToField("amount") }}
                </v-chip>
              </td>
              <td></td>
            </tr>
          </template>
          <template slot="body.append" v-if="carryFwd">
            <tr class="grey lighten-5">
              <td></td>
              <td></td>
              <th>Opening Balance</th>
              <td>
                <v-chip color="gray" dark>
                  {{ carryFwd.amount }}
                </v-chip>
              </td>
              <td></td>
            </tr>
          </template>
          <template slot="body.append">
            <tr class="grey lighten-5">
              <td></td>
              <td></td>
              <th>Carry Forward</th>
              <td>
                <v-chip color="orange" dark>
                  {{ totalFiels("amount") }}
                </v-chip>
              </td>
              <td></td>
            </tr>
          </template>
          <template v-slot:[`item.go_to`]="{ item }">
            <v-icon v-if="item.type" @click="handleClick(item)">
              mdi-arrow-right
            </v-icon>
          </template>

          <template v-slot:[`item.purpose_type`]="{ item }">
            {{ getPurposeType(item.purpose_type) || "-" }} </template
          >>
        </v-data-table>
      </v-col>
      <v-col cols="12" md="6">
        <p class="text-center text-decoration-underline h4 mt-4">Debits</p>
        <!-- @click:row="handleClick" -->
        <v-data-table
          :headers="fromheaders"
          :footer-props="{
            'items-per-page-options': [10, 20, 30, 40, -1],
          }"
          :items-per-page="40"
          :items="fromTransaction"
          show-select
          item-key="tr_id"
          v-model="multiFromSelect"
        >
          <template v-slot:[`item.amount`]="{ item }">
            <v-chip>{{ item.amount }}</v-chip>
          </template>

          <template slot="body.append">
            <tr class="grey lighten-5">
              <td></td>
              <td></td>
              <th>Total Debits</th>
              <td>
                <v-chip color="red" dark>
                  {{ sumFromField("amount") }}
                </v-chip>
              </td>
              <td></td>
            </tr>
          </template>

          <template v-slot:[`item.go_to`]="{ item }">
            <v-icon @click="handleClick(item)"> mdi-arrow-right </v-icon>
          </template>

          <template v-slot:[`item.purpose_type`]="{ item }">
            {{ getPurposeType(item.purpose_type) || "-" }}
            <span v-if="checkAccounyType == 3">
              <v-btn icon color="success" v-if="item.added_to_report">
                <v-icon>mdi-check</v-icon>
              </v-btn>
              <v-btn
                v-else
                icon
                @click="addToReport(item.tr_id)"
                color="secondary"
              >
                <v-icon>mdi-check</v-icon>
              </v-btn>
            </span>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <!-- Balance Sheet End -->

    <GoogleDrive minimized-popup-title="Google Drive (I.E.)" :data="getUploadData" type="income" upload="uploadOnGoogleDrive" ref="googleDrive"></GoogleDrive>
  </v-card>
</template>

<script>
import GoogleDrive from './../google-drive/GoogleDrive.vue'

export default {
  components: {
    GoogleDrive
  },
  beforeCreate() {
    if (!this.$permission.userPermissions(["view-dashboard-page"]))
      this.$router.push({ name: "dashboard" });
  },
  beforeMount() {
    this.fetchAccountTypes();
  },
  data: () => ({
    ctcDialog: false,
    editCTCData: {
      id: null,
      ctc: 0,
    },
    downloadType: -1,
    multiToSelect: [],
    multiFromSelect: [],
    openingBalance: 0,
    accountType: null,
    accountTypes: [],
    accounts: [],
    account: null,
    loading: true,
    toHeaders: [
      {
        text: "Debited from",
        align: "start",
        filterable: false,
        value: "from_account.master_account.name",
      },
      { text: "Type", value: "purpose_type" },
      { text: "Amount", value: "amount" },
      { text: "", value: "go_to" },
      { text: "Actions", value: "actions", sortable: false }
    ],
    fromheaders: [
      {
        text: "Credited To",
        align: "start",
        filterable: false,
        value: "to_account.master_account.name",
      },
      { text: "Type", value: "purpose_type" },
      { text: "Amount", value: "amount" },
      { text: "", value: "go_to" },
    ],
    toTransaction: [],
    fromTransaction: [],
    expense: [],
    carryFwd: 0,
    dates: [],
    modal: false,
    menu2: false,
    loader: null,
    loading2: false,
    loading3: false,
    loading4: false,
    loading5: false,
    loading6: false,
    loading7: false,
    checkAccounyType: null,
  }),
  computed: {
    getDownloadType() {
      let data = [
        {
          text: "Select download type",
          value: -1,
        },
        {
          text: "PDF",
          value: 0,
        },
        {
          text: "EXCEL",
          value: 1,
        },
      ];

      if (this.isCreditExcel) {
        data.push({
          text: "CREDIT EXCEL",
          value: 2,
        });
      }

      return data;
    },
    disableSearch() {
      if (
        this.accountType != null &&
        this.account != null &&
        this.dates.length > 1
      ) {
        return false;
      }
      return true;
    },
    disableDriveButtons() {
      return this.downloadType == -1;
    },
    disabledIncome() {
      return this.multiToSelect.length == 0 && this.multiFromSelect.length == 0;
    },
    dateRangeText() {
      return this.dates.join(" ~ ");
    },
    isCreditExcel() {
      return this.accountType == 3 && this.fromTransaction.length > 0;
    },
    getUploadData() {
      return {
        account: this.account,
        downloadType: this.downloadType,
        dates: this.dates
      }
    }
  },
  watch: {
    loader() {
      const l = this.loader;
      this[l] = !this[l];

      setTimeout(() => (this[l] = false), 300);

      this.loader = null;
    },
  },
  methods: {
    openCTCPopup(item){
      this.ctcDialog = true;
      this.editCTCData.id = item.tr_id;
      this.editCTCData.ctc = item.amount;
    },
    closeCTCPopup(){
      this.ctcDialog = false;
      this.editCTCData.id = null;
      this.editCTCData.ctc = 0;
    },
    saveCTC(){
      this.editCTCData.ctc = Number(this.editCTCData.ctc);

      this.$http
        .put("ctc/" + this.editCTCData.id, {
            ctc: this.editCTCData.ctc
          }, {
            headers: {
              Authorization: "Bearer " + localStorage.getItem('access_token')
            }
          }
        )
        .then((response) => {
          if(response.status == 200){
            this.ctcDialog = false;
            let toTransaction = [...this.toTransaction];
            toTransaction = toTransaction.map(item => {
              if(item.tr_id == this.editCTCData.id){
                item.amount = this.editCTCData.ctc;
              }
              return item;
            })
            this.toTransaction = toTransaction;
          }else{
            this.closeCTCPopup()
          }
        })
        .catch(({ response }) => {
          this.closeCTCPopup()
        });
    },
    addToReport(id) {
      this.$http
        .get(`add-to-credit-report/${id}`, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("access_token"),
          },
        })
        .then((response) => {
          this.fetchAccountData(this.account);
          this.$toast.success(response.data.data.message.join(" , "));
        })
        .catch(({ response }) => {
          this.$toast.error(response.data.error.message.join(""));
        });
    },
    getPurposeType(id) {
      if (id === 0) return "Normal";
      if (id === 1) return "Salary";
      if (id === 2) return "Upad";
      if (id === 3) return "Income";
      if (id === 4) return "Self Credit";
      if (id === -1) return "Expense";
    },
    markAsIncome() {
      let fromTransaction = this.multiFromSelect.filter((item) => {
        if (item.type == 1) {
          return true;
        }
      });
      let toTransaction = this.multiToSelect.filter((item) => {
        if (item.type == 1) {
          return true;
        }
      });

      fromTransaction = fromTransaction.map((item) => item.tr_id);
      toTransaction = toTransaction.map((item) => item.tr_id);
      let assignIncomeArr = fromTransaction.concat(toTransaction);
      let formData = new FormData();
      formData.append("income_arr", assignIncomeArr);
      this.loading6 = true;
      this.$http
        .post("transaction/assign-income", formData, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("access_token"),
          },
        })
        .then((response) => {
          if (response.status == 200) {
            this.fetchAccountData(this.account);
            this.$toast.success(response.data.data.message.join(" , "));
          }
          this.loading6 = false;
        })
        .catch((error) => {
          console.error(error);
          this.$toast.error("Something went wrong");
          this.loading6 = false;
        });
    },
    downloadPdf() {
      this.loading2 = true;
      this.$http
        .get(`income-export/report/download/${this.account}?0=${this.dates}`, {
          responseType: "blob",
          headers: {
            Authorization: "Bearer " + localStorage.getItem("access_token"),
          },
        })
        .then((response) => {
          const file = new Blob([response.data], { type: "application/pdf" });
          // Build a URL from the file
          const fileURL = URL.createObjectURL(file);
          // Open the URL on new Window
          window.open(fileURL);
          this.loading2 = false;
        })
        .catch((error) => {
          console.log(error);
          this.loading2 = false;
        });
    },
    downloadFileType() {
      if (this.downloadType == 0) {
        this.downloadPdf();
      }

      if (this.downloadType == 1) {
        this.downloadExcel();
      }

      if (this.downloadType == 2) {
        this.downloadCreditExcel();
      }
    },
    downloadExcel() {
      this.loading2 = true;
      this.$http
        .get(
          `income-export/report/download/${this.account}/1?0=${this.dates}`,
          {
            responseType: "blob",
            headers: {
              Authorization: "Bearer " + localStorage.getItem("access_token"),
            },
          }
        )
        .then((response) => {
          const account = this.accounts.find((item) => {
            return item.value == this.account;
          });
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          const fileName =
            account.text +
            "_" +
            this.dateRangeText.replace(/~/g, "_to_") +
            "_credit_debit.xlsx";
          link.setAttribute(
            "download",
            fileName.replace(/\s/g, "_").replace(/-/g, "_")
          );
          document.body.appendChild(link);
          link.click();
          this.loading2 = false;
        })
        .catch((error) => {
          console.log(error);
          this.loading2 = false;
        });
    },
    downloadCreditExcel() {
      this.loading5 = true;
      this.$http
        .get(
          `income-export/report/download/${this.account}/2?0=${this.dates}`,
          {
            responseType: "blob",
            headers: {
              Authorization: "Bearer " + localStorage.getItem("access_token"),
            },
          }
        )
        .then((response) => {
          const account = this.accounts.find((item) => {
            return item.value == this.account;
          });
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          const fileName =
            account.text +
            "_" +
            this.dateRangeText.replace(/~/g, "_to_") +
            "_credit.xlsx";
          link.setAttribute(
            "download",
            fileName.replace(/\s/g, "_").replace(/-/g, "_")
          );
          document.body.appendChild(link);
          link.click();
          this.loading5 = false;
        })
        .catch((error) => {
          console.log(error);
          this.loading5 = false;
        });
    },
    handleClick(value) {
      let routeData = null;
      if (value.type == 1) {
        routeData = this.$router.resolve({
          name: "transactions",
          params: { trId: value.tr_id },
        });
      } else if (value.type == 2) {
        routeData = this.$router.resolve({
          name: "expense",
          params: { exId: value.ex_id },
        });
      }
      if (routeData) {
        window.open(routeData.href, "_blank");
      }
    },
    fetchAccountTypes() {
      this.$http
        .get("account/types", {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("access_token"),
          },
        })
        .then((response) => {
          this.accountTypes = [];
          const accountType = response.data.data.accountType;
          accountType.forEach((element) => {
            this.accountTypes.push({ value: element.id, text: element.name });
          });
        })
        .catch((error) => {
          console.log("error", error.response);
        });
    },
    fetchTypedAccounts(type) {
      const data = {
        accountTypeId: type,
      };
      this.$http
        .post("typed/accounts", data, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("access_token"),
          },
        })
        .then((response) => {
          const accountType = response.data.data.accounts;
          this.accounts = [];
          accountType.forEach((element) => {
            this.accounts.push({
              value: element.id,
              text: this.getItemName(element.master_account),
            });
          });
        })
        .catch((error) => {
          console.log("error", error.response);
        });
    },
    getItemName(item) {
      if (item.account_type_id == 1 && item.acc_number != null)
        return (
          item.name +
          " " +
          (item.last_name || "") +
          " (" +
          item.acc_number.substring(item.acc_number.length - 4) +
          ")"
        );
      return item.name + " " + (item.last_name || "");
    },
    fetchAccountData(id) {
      this.loading4 = true;
      this.$http
        .get("analytics/income-export-report/" + id, {
          params: {
            0: this.dates,
          },
          headers: {
            Authorization: "Bearer " + localStorage.getItem("access_token"),
          },
        })
        .then((response) => {
          this.multiFromSelect = [];
          this.multiToSelect = [];
          this.fromTransaction = [];
          this.toTransaction = [];
          this.checkAccounyType = this.accountType;
          
          response.data.data.to_transaction.forEach((element) => {
            this.toTransaction.push({
              tr_id: element.id,
              type: 1,
              amount: element.amount,
              purpose_type: element.purpose_type,
              from_account: {
                master_account: {
                  name: `${element.date} - ${this.getItemName(
                    this.accountType == 6 ? element.to_account.master_account : element.from_account.master_account
                  )} ${
                    element.purpose_type == 1
                      ? "(salary month : " + element.salary_month + ")"
                      : ""
                  }`,
                },
              },
            });
          });

          response.data.data.ctcTransactions.forEach((element) => {
            this.toTransaction.push({
              amount: element.ctc,
              tr_id: element.id,
              from_account: {
                master_account: {
                  name: `${element.date} - CTC`,
                },
              },
            });
          });

          response.data.data.profitTransactions.forEach((element) => {
            this.toTransaction.push({
              amount: element.profit,
              from_account: {
                master_account: {
                  name: `${element.date} - Profit`,
                },
              },
            });
          });

          response.data.data.selfCreditTransactions.forEach((element) => {
            this.toTransaction.push({
              tr_id: element.id,
              type: 1,
              amount: element.amount,
              purpose_type: element.purpose_type,
              from_account: {
                master_account: {
                  name: `${element.date} - ${this.getItemName(
                    element.from_account.master_account
                  )}`,
                },
              },
            });
          });

          response.data.data.from_transaction.forEach((element) => {
            this.fromTransaction.push({
              tr_id: element.id,
              type: 1,
              amount: element.amount,
              purpose_type: element.purpose_type,
              added_to_report: element.added_to_report,
              to_account: {
                master_account: {
                  name: `${element.date} - ${
                    element.purpose_type != 0 && this.accountType != 1
                      ? this.getItemName(element.from_account.master_account)
                      : this.getItemName(element.to_account.master_account)
                  } ${
                    element.purpose_type == 1
                      ? "(Salary month : " + element.salary_month + ")"
                      : ""
                  } ${element.purpose_type == 2 ? "(Upad)" : ""}`,
                },
              },
            });
          });

          if (response.data.data.expense) {
            response.data.data.expense.forEach((element) => {
              this.fromTransaction.push({
                ex_id: element.id,
                type: 2,
                amount: element.amount,
                purpose_type: -1,
                to_account: {
                  master_account: {
                    name: `${element.date} - (${element.notes})`,
                  },
                },
              });
            });
          }
          this.expense = response.data.data.expense;
          this.openingBalance = response.data.data.openingBalance;
          this.loading4 = false;
        })
        .catch((error) => {
          if (error.response.data.error) {
            this.$toast.error(error.response.data.error.message.join(", "));
          } else {
            this.$toast.error("something went wrong");
          }
          console.log("error", error.response.data.error.message.join(", "));
          this.loading4 = false;
        });
    },
    getTransactionPerpose(id) {
      if (id === 0) return "Normal";
      if (id === 1) return "Salary";
      if (id === 2) return "Withdrawal";
    },
    sumToField(key) {
      // sum data in give key (property)
      return this.toTransaction.reduce((a, b) => a + (b[key] || 0), 0);
    },
    sumFromField(key) {
      // sum data in give key (property)
      return this.fromTransaction.reduce((a, b) => a + (b[key] || 0), 0);
    },
    totalFiels(key) {
      let total =
        this.openingBalance + (this.sumToField(key) - this.sumFromField(key));
      if (this.carryFwd) total += this.carryFwd.amount;
      // sum data in give key (property)
      return total;
    },
  },
  created() {
    this.loading = false;
  },
};
</script>

<style>
.custom-loader {
  animation: loader 1s infinite;
  display: flex;
}
@-moz-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-o-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>
