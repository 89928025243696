<template>
  <div class="mr-4 ml-4 mt-4">

  <v-data-table
    :headers="headers"
    :items="profitData"
    :loading="loading"
    :search="search"
    sort-by="calories"
    class="elevation-1"
  >
    <template v-slot:top>
      <v-toolbar color="grey lighten-3 mb-4" flat>
        <v-toolbar-title>Profit</v-toolbar-title>
        <v-divider class="mx-4" inset vertical></v-divider>
        <v-spacer></v-spacer>
        <v-dialog v-model="dialog" max-width="500px">
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="green" dark tile class="mb-2" v-bind="attrs" v-on="on">
              + Add Profit
            </v-btn>
          </template>
          <v-card>
            <v-card-title>
              <span class="text-h5">{{ formTitle }}</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12">
                    <v-autocomplete
                      v-model="partnerAccountId"
                      :items="partnerAccounts"
                      dense
                      filled
                      label="Select Partner Account"
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      v-model.number="profit"
                      label="Profit"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-menu
                      ref="profitDatemenu"
                      v-model="profitDatemenu"
                      :close-on-content-click="false"
                      :return-value.sync="profitDate"
                      transition="scale-transition"
                      readonly
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="profitDate"
                          label="Date"
                          prepend-icon="mdi-calendar"
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker v-model="profitDate" no-title scrollable>
                        <v-spacer></v-spacer>
                        <v-btn
                          text
                          color="primary"
                          @click="profitDatemenu = false"
                        >
                          Cancel
                        </v-btn>
                        <v-btn
                          text
                          color="primary"
                          @click="$refs.profitDatemenu.save(profitDate)"
                        >
                          OK
                        </v-btn>
                      </v-date-picker>
                    </v-menu>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="close"> Cancel </v-btn>
              <v-btn color="blue darken-1" text @click="save"> Save </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="dialogDelete" max-width="500px">
          <v-card>
            <v-card-title class="text-h5"
              >Are you sure you want to delete this item?</v-card-title
            >
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeDelete"
                >Cancel</v-btn
              >
              <v-btn color="blue darken-1" text @click="deleteItemConfirm"
                >OK</v-btn
              >
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
      <v-text-field
        v-model="search"
        label="Search"
        class="mx-4"
      ></v-text-field>
    </template>
    <template v-slot:[`item.actions`]="{ item }">
      <v-icon small class="mr-2" @click="editItem(item)"> mdi-pencil </v-icon>
      <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon>
    </template>
  </v-data-table>
  </div>
</template>

<script>
export default {
  beforeCreate() {
    if (!this.$permission.userPermissions(["view-rojmel-page"]))
      this.$router.push({ name: "dashboard" });
  },

  data: () => ({
    dialog: false,
    dialogDelete: false,
    headers: [
      {
        text: "Date",
        align: "start",
        sortable: false,
        value: "date",
      },
      { text: "Name", value: "name" },
      { text: "Amount", value: "amount" },
      { text: "Actions", value: "actions", sortable: false },
    ],
    desserts: [],
    editedIndex: -1,
    editedItem: {
      partnerAccountId: 0,
      profit: 0,
      profitDate: null,
    },
    defaultItem: {
      partnerAccountId: 0,
      profit: 0,
      profitDate: null,
    },
    partnerAccountId: 0,
    profit: 0,
    profitDate: null,
    partnerAccounts: [],
    profitDatemenu: false,
    profitData: [],
    editId: null,
    loading: false,
    search: ''
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "Add Profit" : "Edit Profit";
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  created() {
    this.initialize();
  },

  methods: {
    async initialize() {
      await this.$http
        .get("partner-account-list", {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("access_token"),
          },
        })
        .then((response) => {
          const accounts = response.data.data.accounts;
          this.partnerAccounts = [];
          accounts.forEach((account) => {
            const item = {
              text: this.getItemName(account.master_account),
              value: account.id,
            };

            this.partnerAccounts.push(item);
          });
        });

      this.loading = true;
      await this.$http
        .get("get-profit-data", {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("access_token"),
          },
        })
        .then((response) => {
          const profits = response.data.data.profits;

          this.profitData = [];

          profits.forEach((element) => {
            const item = {
              id: element.id,
              name: this.getItemName(element.account.master_account),
              amount: element.profit,
              date: element.date,
              partner_account_id: element.account_id,
            };

            this.profitData.push(item);
          });

          this.loading = false;
        })
        .catch((error) => {
          console.error(error);
          this.$toast.error("Something went wrong");
          this.loading = false;
        });
    },

    getItemName(item) {
      if (item.account_type_id == 1 && item.acc_number != null)
        return (
          item.name +
          " " +
          (item.last_name || "") +
          " (" +
          item.acc_number.substring(item.acc_number.length - 4) +
          ")"
        );
      return item.name + " " + (item.last_name || "");
    },

    editItem(item) {
      this.editedIndex = this.profitData.indexOf(item);
      this.partnerAccountId = item.partner_account_id;
      this.profit = item.amount;
      this.profitDate = item.date;
      this.editId = item.id;
      this.dialog = true;
    },

    deleteItem(item) {
      this.editId = item.id;
      this.editedIndex = this.profitData.indexOf(item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      this.$http
        .delete("delete-profit/" + this.editId, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("access_token"),
          },
        })
        .then((response) => {
          this.$toast.success(response.data.data.message[0]);
          this.initialize();
        })
        .catch((error) => {
          this.$toast.error("Something went wrong");
          console.log(error);
        });
      this.profitData.splice(this.editedIndex, 1);
      this.closeDelete();
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        (this.partnerAccountId = 0),
          (this.profit = 0),
          (this.profitDate = null),
          (this.editedIndex = -1);
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    save() {
      if (
        this.partnerAccountId == 0 ||
        this.profit == 0 ||
        this.profitDate == null
      )
        return;
      if (this.editedIndex > -1) {
        this.$http
          .post(
            "update-profit/" + this.editId,
            {
              partner_account_id: this.partnerAccountId,
              profit: this.profit,
              profit_date: this.profitDate,
            },
            {
              headers: {
                Authorization: "Bearer " + localStorage.getItem("access_token"),
              },
            }
          )
          .then((response) => {
            this.$toast.success(response.data.data.message[0]);
            this.initialize();
          })
          .catch((error) => {
            this.$toast.error("Something went wrong");
            console.log(error);
          });
      } else {
        this.$http
          .post(
            "add-profit",
            {
              partner_account_id: this.partnerAccountId,
              profit: this.profit,
              profit_date: this.profitDate,
            },
            {
              headers: {
                Authorization: "Bearer " + localStorage.getItem("access_token"),
              },
            }
          )
          .then((response) => {
            this.$toast.success(response.data.data.message[0]);
            this.initialize();
          })
          .catch((error) => {
            this.$toast.error("Something went wrong");
            console.log(error);
          });
      }
      this.close();
    },
  },
};
</script>

<style>
</style>