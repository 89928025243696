<template v-slot:top>
  <div>
    <v-card
      elevation="1"
      outlined
      tile
      class="mr-4 ml-4 mt-4"
    >
      <v-toolbar color="grey lighten-3 mb-4" flat>
          <v-toolbar-title>Salary Slip</v-toolbar-title>
          <v-divider class="mx-2" inset vertical></v-divider>
      </v-toolbar>
      <v-row class="pa-3">
        <v-col cols="12" md="3">
          <v-autocomplete
            v-model="accountTypeId"
            :items="accountTypes"
            label="Select Account"
            required
            @input="fetchEmployeesAccount()"
            dense
            filled
          ></v-autocomplete>
        </v-col>
        <v-col cols="12" md="3">
          <v-autocomplete
            v-model="employeeAccountId"
            :items="employeeAccounts"
            label="Select Employee"
            @input="clearData()"
            required
            dense
            filled
          ></v-autocomplete>
        </v-col>
        <v-col cols="12" md="3">
          <v-autocomplete
            v-model="selectYear"
            :items="getYearList"
            label="Select Year"
            dense
            filled
          ></v-autocomplete>
        </v-col>
        <v-col cols="12" md="3">
          <v-btn
            depressed
            tile
            class="ma-2"
            :loading="loading"
            :disabled="disableSearch"
            color="info"
            @click="fetchSalarySlipData()"
          >
            Search
            <template v-slot:loader>
              <span class="custom-loader">
                <v-icon light>mdi-cached</v-icon>
              </span>
            </template>
          </v-btn>
          <v-btn
            depressed
            tile
            :disabled="disableBulkSlip"
            color="success darken-5"
            class="ma-2"
            @click="downloadSalarySlip(multiSelect, true)"
            :loading="loading5"
          >
            Bulk Salary Slip
          </v-btn>
        </v-col>
      </v-row>
      <hr class="grey mb-4" style="opacity: 0.05">
      <v-data-table
        v-model="multiSelect"
        :headers="headers"
        :footer-props="{ 'items-per-page-options': [10, 20, 30, 40, 50, -1] }"
        :items-per-page="50"
        :items="transactions"
        :loading="loading"
        show-select
        loading-text="Loading... Please wait"
      >
        <template v-slot:[`item.amount`]="{ item }">
          <v-chip>
            {{ item.amount }}
          </v-chip>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-btn
            small
            outlined
            depressed
            tile
            class="mr-2"
            @click="goToTransaction(item)"
          >
            Go to Transaction
          </v-btn>

          <!-- :href="`${$apiURL}salary-slip/generate-pdf/${employeeAccountId}/${item.id}`" -->
          <v-btn
            small
            dark
            depressed
            tile
            color="info"
            class="mr-2"
            @click="downloadSalarySlip([item.id])"
            target="_blank"
          >
            Download PDF
          </v-btn>
        </template>
        <template v-slot:body.append>
          <tr class="grey lighten-5">
            <td></td>
            <td></td>
            <td></td>
            <td><v-chip color="success" text-color="white">{{ totalSalaryAmount }}</v-chip></td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
        </template>
      </v-data-table>
      <v-row class="pa-3">
        <v-col cols="12" md="3" v-if="exportLetter || (multiSelect.length > 0 && selectYear > 0)">
          <v-autocomplete
            v-model="partnerAccountId"
            :items="partnerAccounts"
            label="Authorize"
            required
            dense
            filled
          ></v-autocomplete>
        </v-col>
        <v-col cols="12" md="4">
          <v-btn
            v-if="exportLetter"
            color="teal white--text"
            class="ma-2"
            @click="generateExperienceLetter"
            :disabled="disableLetter"
            :loading="loading4"
            depressed
            tile
          >
            Export Experience Letter
          </v-btn>
          <v-btn
            v-if="multiSelect.length > 0 && selectYear > 0"
            color="teal white--text darken-2"
            class="ma-2"
            @click="generateForm16"
            :disabled="disableGenerateForm16"
            :loading="loading2"
            depressed
            tile
          >
            Generate FORM-16
          </v-btn>
        </v-col>
      </v-row>
    </v-card>
    <v-overlay style="z-index:9999" :value="overlay">
      <v-progress-circular indeterminate size="32"></v-progress-circular>
    </v-overlay>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      loading2: false,
      loading3: false,
      loading4: false,
      loading5: false,
      accountTypeId: null,
      accountTypes: [],
      multiSelect: [],
      employeeAccountId: null,
      employeeAccounts: [],
      partnerAccounts: [],
      partnerAccountId: null,
      selectYear: 0,
      transactions: [],
      exportLetter: false,
      overlay: false,
      headers: [
        { text: "Date", value: "date" },
        { text: "From Account", value: "fromAccountName" },
        { text: "Amount", value: "amount" },
        { text: "Salary Month", value: "salary_month" },
        { text: "Last Donwloaded Date", value: "downloaded_at" },
        { text: "Actions", value: "actions", align: "right", sortable: false },
      ],
    };
  },
  beforeCreate() {
    if (!this.$permission.userPermissions(["view-salary-slip-page"]))
      this.$router.push({ name: "dashboard" });
  },
  created() {
    this.fetchAccountTypes();
  },
  methods: {
    fetchAccountTypes() {
      this.$http
        .get("salary-slip/account-type", {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("access_token"),
          },
        })
        .then((response) => {
          const accountType = response.data.data.accountType;
          this.accountTypes = [];
          accountType.forEach((element) => {
            this.accountTypes.push({ value: element.id, text: element.name });
          });

          const partnerAccount = response.data.data.partnerAccount;
          this.partnerAccounts = [];
          partnerAccount.forEach((element) => {
            this.partnerAccounts.push({
              value: element.id,
              text: this.getItemName(element.master_account),
            });
          });
        })
        .catch((error) => {
          console.error(error);
        });
    },
    clearData() {
      this.partnerAccountId = null
      this.selectYear = 0
      this.transactions = []
      this.exportLetter = false
      this.multiple = []
    },
    fetchEmployeesAccount() {
      this.$http
        .get(`salary-slip/employee-account/${this.accountTypeId}`, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("access_token"),
          },
        })
        .then((response) => {
          const accounts = response.data.data.accounts;
          this.employeeAccounts = [];
          accounts.forEach((account) => {
            this.employeeAccounts.push({
              value: account.id,
              text: this.getItemName(account.master_account),
            });
          });
        })
        .catch((error) => {
          console.log(error);
        });
    },
    fetchSalarySlipData() {
      this.loading = true;
      this.$http
        .get(`salary-slip/get-salary-data/${this.employeeAccountId}`, {
          params: {
            year: this.selectYear,
            authorizeId: this.partnerAccountId,
          },
          headers: {
            Authorization: "Bearer " + localStorage.getItem("access_token"),
          },
        })
        .then((response) => {
          this.multiSelect = [];
          const transaction = response.data.data.transaction;
          this.exportLetter = response.data.data.experienceLetter;

          this.transactions = [];
          transaction.forEach((element) => {
            const item = {
              id: element.id,
              date: element.date,
              fromAccountName: this.getItemName(
                element.from_account.master_account
              ),
              amount: element.amount,
              downloaded_at: element.downloaded_at,
              salary_month: element.salary_month,
            };
            this.transactions.push(item);
          });
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
        });
    },
    goToTransaction(value) {
      let routeData = this.$router.resolve({
        name: "transactions",
        params: { trId: value.id },
      });
      if (routeData) {
        window.open(routeData.href, "_blank");
      }
    },
    getItemName(item) {
      if (item.account_type_id == 1 && item.acc_number != null)
        return (
          item.name +
          " (" +
          item.acc_number.substring(item.acc_number.length - 4) +
          ")"
        );
      return item.name + " " + (item.last_name || "");
    },
    downloadSalarySlip(ids, multi = false) {
      if(multi) {
        this.loading5 = true;
        ids = ids.map(item => {
          return item.id;
        })
      } else {
        this.overlay = true;
      }
      this.$http
        .post(
          `salary-slip/generate-pdf/${this.employeeAccountId}`,
          {
              ids: ids
          },
          {
            responseType: "blob",
            headers: {
              Authorization: "Bearer " + localStorage.getItem("access_token"),
            },
          }
        )
        .then((response) => {
          const file = new Blob([response.data], { type: "application/pdf" });
          //Build a URL from the file
          const fileURL = URL.createObjectURL(file);
          //Open the URL on new Window
          window.open(fileURL);
          this.loading5 = false;
          this.overlay = false;
        })
        .catch(async({ response }) => {
          const data = JSON.parse(await response.data.text());
          this.$toast.error(data.error.message.join(", "));
          this.loading5 = false;
          this.overlay = false;
        });
    },
    generateForm16() {
      this.loading2 = true;
      this.$http
        .post(
          `generate-form-16`,
          {
            user_id: this.employeeAccountId,
            select_ids: this.multiSelect,
            year: this.selectYear,
            authorize_id: this.partnerAccountId
          },
          {
            responseType: "blob",
            headers: {
              Authorization: "Bearer " + localStorage.getItem("access_token"),
            },
          }
        )
        .then((response) => {
          const file = new Blob([response.data], { type: "application/pdf" });
          //Build a URL from the file
          const fileURL = URL.createObjectURL(file);
          //Open the URL on new Window
          window.open(fileURL);
          this.loading2 = false;
        })
        .catch((error) => {
          console.log(error);
          this.loading2 = false;
        });
    },
    generateExperienceLetter() {
      this.loading4 = true;
      this.$http
        .get(
          `experience-letter/${this.employeeAccountId}`,
          {
            params: {
              authorize_id: this.partnerAccountId
            },
            responseType: "blob",
            headers: {
              Authorization: "Bearer " + localStorage.getItem("access_token"),
            },
          }
        )
        .then((response) => {
          const file = new Blob([response.data], { type: "application/pdf" });
          //Build a URL from the file
          const fileURL = URL.createObjectURL(file);
          //Open the URL on new Window
          window.open(fileURL);
          this.loading4 = false;
        })
        .catch((error) => {
          console.log(error);
          this.loading4 = false;
        });
    },
  },
  computed: {
    disableSearch() {
      return this.employeeAccountId === null;
    },
    disableGenerateForm16() {
      return this.partnerAccountId === null || this.selectYear === 0;
    },
    disableLetter() {
      return this.partnerAccountId === null || this.employeeAccountId === null;
    },
    disableBulkSlip() {
      return this.multiSelect.length == 0;
    },
    totalSalaryAmount() {
      let amount = 0;
      this.transactions.forEach((item) => {
        amount += item.amount;
      })
      return amount;
    },
    getYearList() {
      let years = [
        {
          text: "All",
          value: 0,
        },
      ];
      for (let i = 2019; i <= new Date().getFullYear(); i++) {
        years.push({
          text: i,
          value: i,
        });
      }
      return years;
    },
  },
};
</script>
