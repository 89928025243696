<template>
  <div>
    <!-- Bank Statement Upload Start -->
    <v-card elevation="1" outlined tile class="mr-4 ml-4 mt-4">
      <!-- Header Start -->
      <v-toolbar color="grey lighten-3 mb-4" flat>
        <v-toolbar-title>Bank Statement</v-toolbar-title>
        <v-divider class="mx-2" inset vertical></v-divider>
      </v-toolbar>
      <!-- Header End -->

      <!-- Bank Statement Create Start -->
      <v-row class="pa-3">
        <v-col cols="12" md="2">
          <v-autocomplete
            dense
            filled
            v-model="partnerAccountId"
            :items="partnerAccounts"
            @input="getAccountActiveBank()"
            prepend-icon="mdi-account"
            label="Select Partner Account"
          ></v-autocomplete>
        </v-col>
        <v-col cols="12" md="2">
          <v-autocomplete
            dense
            filled
            v-model="partnerBankAccountId"
            :items="partnerBankAccounts"
            prepend-icon="mdi-account"
            label="Select Bank Account"
          ></v-autocomplete>
        </v-col>
        <v-col cols="12" md="3">
          <v-file-input
            label="Upload Statement (Excel)"
            dense
            filled
            v-model="excelFile"
            prepend-icon="mdi-file-plus"
          ></v-file-input>
        </v-col>
        <v-col cols="12" md="5">
          <v-btn
            :loading="loading"
            :disabled="disabled"
            color="info"
            class="ma-2 white--text"
            @click="uploadStatement"
            depressed
            tile
          >
            Upload
            <v-icon right dark> mdi-cloud-upload </v-icon>
          </v-btn>
          <v-btn
            :disabled="disabledDrive"
            class="ma-2 green white--text lighten-1"
            @click="$refs.googleDrive.getRootFolder()"
            depressed
            tile
          >
            Save to Google Drive
            <v-icon right dark> mdi-cloud-upload </v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <!-- Bank Statement Create End -->
    </v-card>
    <!-- Bank Statement Upload End -->

    <!-- Bank Statement Filter Start -->
    <v-card elevation="1" outlined tile class="mr-4 ml-4 mt-4">
      <v-toolbar color="grey lighten-3 mb-4" flat>
        <v-toolbar-title>Filter Bank Statement</v-toolbar-title>
        <v-divider class="mx-2" inset vertical></v-divider>
      </v-toolbar>
      <v-row class="pa-3">
        <v-col cols="12" md="2">
          <v-autocomplete
            dense
            filled
            v-model="filterPartnerAccountId"
            :items="partnerAccounts"
            @input="getAccountActiveBank(true)"
            label="Select Partner Account"
            prepend-icon="mdi-account"
          ></v-autocomplete>
        </v-col>

        <v-col cols="12" md="2">
          <v-autocomplete
            dense
            filled
            v-model="filterPartnerBankAccountId"
            :items="filterPartnerBankAccounts"
            prepend-icon="mdi-account"
            label="Select Bank Account"
          ></v-autocomplete>
        </v-col>

        <v-col cols="12" md="3">
          <v-dialog
            ref="dialog"
            v-model="dateModal"
            :return-value.sync="inputMonth"
            persistent
            width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="filterInputMonth"
                label="Select Month"
                prepend-icon="mdi-calendar"
                readonly
                dense
                filled
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker v-model="inputMonth" range scrollable>
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="dateModal = false">
                Cancel
              </v-btn>
              <v-btn
                text
                color="primary"
                @click="$refs.dialog.save(inputMonth)"
              >
                OK
              </v-btn>
            </v-date-picker>
          </v-dialog>
        </v-col>

        <v-col cols="12" md="3">
          <v-btn
            :loading="loading2"
            :disabled="disabledFilter"
            color="info"
            class="ma-2 white--text"
            @click="search"
            depressed
            tile
          >
            Search
            <v-icon right dark> mdi-filter </v-icon>
          </v-btn>
          <v-btn
            :disabled="disabledFilter"
            color="info"
            outlined
            class="ma-2 white--text"
            @click="resetFilter"
            depressed
            tile
          >
            Reset
          </v-btn>
        </v-col>
      </v-row>
      <v-data-table 
        :headers="headers"
        :items="filterData"
        :loading="loadingTable"
        :item-class="itemRowBackground"
      >
        <template v-slot:[`item.date`]="{ item }">
            <span class="font-weight-bold">{{ item.date }}</span>
        </template>

        <template v-slot:[`item.bank_name`]="{ item }">
            <span class="font-weight-bold">{{ item.bank_name }}</span>
        </template>

        <template v-slot:[`item.amount`]="{ item }">
          <v-chip :color="item.is_approve ? 'success' : 'red'" dark tile>
            {{ item.amount }}
          </v-chip>
        </template>

        <template v-slot:[`item.is_approve`]="{ item }">
          <v-btn width="100" v-if="item.is_approve" depressed tile small color="green" disabled>Approved</v-btn>
          <v-btn width="100" color="red" dark @click="approveStatus(item.id)" depressed tile small v-else>Approve</v-btn>
        </template>

         <template v-slot:[`item.model`]="{ item }">
            <v-icon v-if="item.model" @click="goToTransaction(item)">
              mdi-arrow-right
            </v-icon>
          </template>

          <template v-slot:[`item.actions`]="{ item }">
            <v-icon color="red" @click="removeRow(item.id)">
              mdi-delete
            </v-icon>
          </template>
      </v-data-table>
    </v-card>
    <!-- Bank Statement Filter End -->

    <v-overlay style="z-index:9999" :value="overlay">
      <v-progress-circular indeterminate size="32"></v-progress-circular>
    </v-overlay>

    <GoogleDrive minimized-popup-title="Google Drive (B.S.)" type="bankStatement" :data="getUploadData" upload="uploadStatementOnGoogleDrive" ref="googleDrive"></GoogleDrive>
  </div>
</template>

<script>
import GoogleDrive from './../google-drive/GoogleDrive.vue'

export default {
  components: {
    GoogleDrive
  },
  beforeCreate() {
    if (!this.$permission.userPermissions(["view-salary-slip-page"]))
      this.$router.push({ name: "dashboard" });
  },
  created() {
    this.fetchPartnerAccounts();
  },
  data() {
    return {
      headers: [
        { text: "Date", value: "date" },
        { text: "Bank Account", value: "bank_name" },
        { text: "Description", value: "description" },
        { text: "Ref no.", value: "ref_no" },
        { text: "CR | DR", value: "type" },
        { text: "Amount", value: "amount" },
        { text: "", value: "is_approve" },
        { text: "", value: "model" },
        { text: "", value: "actions" },

      ],
      partnerAccounts: [],
      partnerAccountId: 0,
      partnerBankAccounts: [],
      partnerBankAccountId: 0,
      filterPartnerAccountId: 0,
      filterPartnerBankAccountId: 0,
      filterPartnerBankAccounts: [],
      excelFile: null,
      dateModal: false,
      inputMonth: [],
      filterData: [],
      loading: false,
      loading2: false,
      loadingTable: false,
      overlay: false,
    };
  },
  methods: {
    itemRowBackground(item) {
      return !item.is_approve ? 'bg-red' : ''
    },
    // Fetch Partner Accounts
    fetchPartnerAccounts() {
      this.$http
        .get("bank-partner-account-list", {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("access_token"),
          },
        })
        .then((response) => {
          const data = response.data.data;
          this.partnerAccounts = data.accounts.map((item) => {
            return {
              text: this.getItemName(item.master_account) + ' (' + item.branch.name + ')',
              value: item.master_account.id,
            };
          });
        })
        .catch((error) => {
          this.$toast.error("Select went wrong");
          console.error(error);
        });
    },

    // Account Active Bank
    getAccountActiveBank(isFilter = false) {
      this.$http
        .get(
          `bank-statement/get-bank-account/${
            isFilter ? this.filterPartnerAccountId : this.partnerAccountId
          }`,
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("access_token"),
            },
          }
        )
        .then((response) => {
          const data = response.data.data;
          const accounts = data.bankAccounts.map((item) => {
            return {
              text: item.bank_name,
              value: item.id,
            };
          });

          if (isFilter) {
            this.filterPartnerBankAccounts = accounts;
          } else {
            this.partnerBankAccounts = accounts;
          }
        })
        .catch((error) => {
          this.$toast.error("Select went wrong");
          console.error(error);
        });
    },

    // Upload Statement
    uploadStatement() {
      this.loading = true;

      let data = new FormData();
      data.append("partner_account_id", this.partnerAccountId);
      data.append("partner_bank_account_id", this.partnerBankAccountId);
      data.append("statement", this.excelFile);

      this.$http
        .post("upload-statement", data, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("access_token"),
          },
        })
        .then((response) => {
          this.loading = false;
          this.$toast.success(response.data.data.message.join(", "));
        })
        .catch(({ response }) => {
          this.loading = false;
          this.$toast.error(response.data.error.message.join(", "));
        });
    },

    // Filter
    search() {
      this.loading2 = true;
      this.loadingTable = true;
      this.$http.post('bank-statement/filter', {
        account_id: this.filterPartnerAccountId,
        bank_account_id: this.filterPartnerBankAccountId,
        date: this.inputMonth
      }, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("access_token"),
        }
      }).then((response) => {
        const data = response.data.data.statement;
        
        this.filterData = []
        data.forEach((item) => {
          const el = {
            id: item.id,
            date: item.formated_date,
            bank_name: item.bank_account.bank_name,
            description: item.description,
            ref_no: item.ref_no,
            type: item.type_text,
            amount: item.amount,
            is_approve: item.is_approve,
            transaction_id: item.transaction_id,
            model: item.model,
          };

          this.filterData.push(el);
        })
        this.loading2 = false;
        this.loadingTable = false;
      }).catch(({ response }) => {
        this.loading2 = false;
        this.loadingTable = false;
        this.$toast.error(response.data.error.message.join(" , "))
        console.log(response)
      })
    },

    removeRow(id) {
      this.overlay = true;
      this.$http.get(`bank-statement/delete/${id}`, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("access_token"),
        }
      }).then((response) => {
        this.$toast.success(response.data.data.message.join(", "))
        this.overlay = false;
        this.search()
      }).catch(({ response }) => {
        this.$toast.error(response.data.error.message.join(", "))
        this.overlay = false;
      })
    },

    goToTransaction(value) {
      let routeData = null
      if(value.model == 1) {
        routeData = this.$router.resolve({name: 'transactions', params: {trId: value.transaction_id}});
      } else if(value.model == 2) {
        routeData = this.$router.resolve({name: 'expense', params: {exId: value.transaction_id}});
      }
      if(routeData) {
        window.open(routeData.href, '_blank');
      }
    },

    // Approve Status
    approveStatus(id) {
      this.overlay = true;
      this.$http.get(`bank-statement/${id}`, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("access_token"),
        }
      }).then((response) => {
        this.$toast.success(response.data.data.message.join(", "))
        this.overlay = false;
        this.search()
      }).catch(({ response }) => {
        this.$toast.error(response.data.error.message.join(", "))
        this.overlay = false;
      })
    },

    resetFilter() {
      this.filterPartnerAccountId = 0;
      this.filterPartnerBankAccountId = 0;
      this.inputMonth = [];
      this.filterData = [];
    },

    // get Account full name
    getItemName(item) {
      if (item.account_type_id == 1 && item.acc_number != null)
        return (
          item.name +
          " " +
          (item.last_name || "") +
          " (" +
          item.acc_number.substring(item.acc_number.length - 4) +
          ")"
        );
      return item.name + " " + (item.last_name || "");
    },
  },
  computed: {
    disabled() {
      return (
        this.excelFile == null ||
        this.partnerAccountId == 0 ||
        this.partnerBankAccountId == 0
      );
    },

    disabledDrive() {
      return (
        this.excelFile == null
      );
    },

    filterInputMonth() {
      return this.inputMonth.join(" ~ ");
    },

    disabledFilter() {
      return (
        this.filterPartnerAccountId == 0
      );
    },

    getUploadData() {
      return {
        excel: this.excelFile
      }
    }
  },
};
</script>

<style>
.bg-red {
  background-color: #ffe9e8;
}
</style>