<template>
  <div class="mr-4 ml-4 mt-4">
    <v-row>
      <v-col cols="6">
        <v-data-table
          :headers="earningHeaders"
          :loading="tableLoading"
          :items="earningItems"
          hide-default-footer
          class="elevation-1 mb-4"
        >
          <template v-slot:top>
            <v-toolbar color="grey lighten-3" flat>
              <v-toolbar-title>Earning</v-toolbar-title>
              <v-divider class="mx-2" inset vertical></v-divider>
              <v-spacer></v-spacer>
            </v-toolbar>
          </template>
          <template v-slot:[`item.head`]="{ item }">
            <span class="font-weight-medium">{{ item.head }}</span>
          </template>
          <template v-slot:[`item.rate`]="{ item }">
            <v-chip dark color="green">
              {{ item.rate }}
            </v-chip>
          </template>
          <template v-slot:[`item.type`]="{ item }">
            {{ getType(item.type) }}
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-icon small class="mr-2" @click="editItemDialog(item)">
              mdi-pencil
            </v-icon>
          </template>
        </v-data-table>
      </v-col>
      <v-col cols="6">
        <v-data-table
          :headers="earningHeaders"
          :loading="tableLoading"
          :items="deductionItems"
          hide-default-footer
          class="elevation-1 mb-4"
        >
          <template v-slot:top>
            <v-toolbar color="grey lighten-3" flat>
              <v-toolbar-title>Deduction</v-toolbar-title>
              <v-divider class="mx-2" inset vertical></v-divider>
              <v-spacer></v-spacer>
            </v-toolbar>
          </template>
          <template v-slot:[`item.head`]="{ item }">
            <span class="font-weight-medium">{{ item.head }}</span>
          </template>
          <template v-slot:[`item.rate`]="{ item }">
            <v-chip dark color="red">
              {{ item.rate }}
            </v-chip>
          </template>
          <template v-slot:[`item.type`]="{ item }">
            {{ getType(item.type) }}
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-icon small class="mr-2" @click="editItemDialog(item)">
              mdi-pencil
            </v-icon>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-dialog v-model="editDialog" width="500">
      <v-card>
        <v-card-title class="grey lighten-2">
          {{ editedHeading }}
        </v-card-title>

        <v-card-text>
          <v-switch
            v-model="typeSwitch"
            :label="`Type : ${typeSwitch ? 'Fixed' : 'Percantage'}`"
          ></v-switch>
          <v-text-field
            label="Rate"
            filled
            dense
            v-model="editedRate"
          ></v-text-field>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="editDialog = false">
            Close
          </v-btn>
          <v-btn
            color="success"
            depressed
            tile
            :loading="saveSetting"
            @click="editItem"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      earningHeaders: [
        {
          text: "Head",
          align: "start",
          value: "head",
        },
        { text: "Rate", value: "rate", sortable: false },
        { text: "Type", value: "type", sortable: false },
        { text: "", value: "actions", sortable: false },
      ],
      earningItems: [],
      deductionItems: [],
      data: [],
      dataKey: [],
      tableLoading: false,
      editDialog: false,
      editedIndex: 0,
      typeSwitch: 1,
      editedRate: 0,
      editedHeading: "Edit",
      saveSetting: false,
    };
  },
  created() {
    this.fetchSettingDetails();
  },
  methods: {
    fetchSettingDetails() {
      this.tableLoading = true;
      this.$http
        .get("salary-slip/get-details", {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("access_token"),
          },
        })
        .then((response) => {
          const data = response.data.data;
          const dataObj = data.data;
          const dataKey = data.dataKey;
          this.earningItems = [
            {
              id: dataObj.find((item) => item.key == "dearness_allowance").id,
              head: dataKey.dearness_allowance,
              rate: dataObj.find((item) => item.key == "dearness_allowance")
                .value,
              type: dataObj.find((item) => item.key == "dearness_allowance")
                .meta,
            },
            {
              id: dataObj.find((item) => item.key == "hra").id,
              head: dataKey.hra,
              rate: dataObj.find((item) => item.key == "hra").value,
              type: dataObj.find((item) => item.key == "hra").meta,
            },
            {
              id: dataObj.find((item) => item.key == "edu_alw").id,
              head: dataKey.edu_alw,
              rate: dataObj.find((item) => item.key == "edu_alw").value,
              type: dataObj.find((item) => item.key == "edu_alw").meta,
            },
            {
              id: dataObj.find((item) => item.key == "washing_alw").id,
              head: dataKey.washing_alw,
              rate: dataObj.find((item) => item.key == "washing_alw").value,
              type: dataObj.find((item) => item.key == "washing_alw").meta,
            },
            {
              id: dataObj.find((item) => item.key == "medi_alw").id,
              head: dataKey.medi_alw,
              rate: dataObj.find((item) => item.key == "medi_alw").value,
              type: dataObj.find((item) => item.key == "medi_alw").meta,
            },
            {
              id: dataObj.find((item) => item.key == "mobile_alw").id,
              head: dataKey.mobile_alw,
              rate: dataObj.find((item) => item.key == "mobile_alw").value,
              type: dataObj.find((item) => item.key == "mobile_alw").meta,
            },
            {
              id: dataObj.find((item) => item.key == "conveyance_allowance").id,
              head: dataKey.conveyance_allowance,
              rate: dataObj.find((item) => item.key == "conveyance_allowance")
                .value,
              type: dataObj.find((item) => item.key == "conveyance_allowance")
                .meta,
            },
          ];

          this.deductionItems = [
            {
              id: dataObj.find((item) => item.key == "pf").id,
              head: dataKey.pf,
              rate: dataObj.find((item) => item.key == "pf").value,
              type: dataObj.find((item) => item.key == "pf").meta,
            },
            {
              id: dataObj.find((item) => item.key == "esic").id,
              head: dataKey.esic,
              rate: dataObj.find((item) => item.key == "esic").value,
              type: dataObj.find((item) => item.key == "esic").meta,
            },
            {
              id: dataObj.find((item) => item.key == "profession_tax").id,
              head: dataKey.profession_tax,
              rate: dataObj.find((item) => item.key == "profession_tax").value,
              type: dataObj.find((item) => item.key == "profession_tax").meta,
            },
            {
              id: dataObj.find((item) => item.key == "tds").id,
              head: dataKey.tds,
              rate: dataObj.find((item) => item.key == "tds").value,
              type: dataObj.find((item) => item.key == "tds").meta,
            },
            {
              id: dataObj.find((item) => item.key == "lwf").id,
              head: dataKey.lwf,
              rate: dataObj.find((item) => item.key == "lwf").value,
              type: dataObj.find((item) => item.key == "lwf").meta,
            },
            {
              id: dataObj.find((item) => item.key == "sal_adv").id,
              head: dataKey.sal_adv,
              rate: dataObj.find((item) => item.key == "sal_adv").value,
              type: dataObj.find((item) => item.key == "sal_adv").meta,
            },
          ];

          this.tableLoading = false;
        })
        .catch((error) => {
          console.error(error);
          this.$toast.error("Something went wrong");
          this.tableLoading = false;
        });
    },
    editItem() {
      if (this.editedIndex) {
        this.saveSetting = true;
        this.$http
          .post(
            `salary-setting/edit/${this.editedIndex}`,
            {
              type: this.typeSwitch,
              rate: this.editedRate,
            },
            {
              headers: {
                Authorization: "Bearer " + localStorage.getItem("access_token"),
              },
            }
          )
          .then((response) => {
            this.$toast.success(response.data.data.message.join(" , "));
            this.fetchSettingDetails();
            this.editDialog = false;
            this.resetEditedValue();
            this.saveSetting = false;
          })
          .catch((error) => {
            console.error(error);
            this.$toast.error("Something went wrong");
            this.resetEditedValue();
            this.saveSetting = false;
          });
      } else {
        this.$toast.error("Something went wrong");
        this.resetEditedValue();
      }
    },
    editItemDialog(item) {
      this.editedIndex = item.id;
      if (item.type == 1) this.typeSwitch = true;
      if (item.type == 2) this.typeSwitch = false;
      this.editedRate = item.rate;
      this.editedHeading = item.head;
      this.editDialog = true;
    },
    resetEditedValue() {
      this.editedIndex = 0;
      this.typeSwitch = 1;
      this.editedRate = 0;
      this.editedHeading = "Edit";
    },
    getType(type) {
      if (type == 1) return "Fixed";
      if (type == 2) return "Percantage";
      return "";
    },
  },
};
</script>

<style>
</style>