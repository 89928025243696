<template>
  <div class="mr-4 ml-4 mt-4">
    <v-data-table
      :headers="headers"
      :footer-props="{ 'items-per-page-options': [10, 20, 30, 40, 50, -1] }"
      :items-per-page="50"
      :items="invoices"
      class="elevation-1"
      :search="search"
      :loading="tableLoading"
    >
      <template v-slot:top>
        <v-toolbar color="grey lighten-3" flat>
          <v-toolbar-title>Invoices</v-toolbar-title>
          <v-divider class="mx-2" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <v-dialog persistent v-model="dialog" max-width="50%">
            <template v-slot:activator="{ on }">
              <v-btn color="green" dark tile class="mb-2" v-on="on"
                >+ Add Invoice</v-btn
              >
            </template>
            <v-card>
              <v-card-title>
                <span class="headline">Invoice</span>
              </v-card-title>

              <v-card-text>
                <v-form>
                  <v-container grid-list-md>
                    <v-row>
                      <v-col cols="12" md="4">
                        <p class="font-weight-bold">Party Account</p>
                        <v-autocomplete
                          dense
                          filled
                          v-model="partyAccountId"
                          :items="getPartyAccount"
                          label="Select Party Account"
                        ></v-autocomplete>
                      </v-col>
                      <v-col cols="12" md="4">
                        <p class="font-weight-bold">Associted Account</p>
                        <v-autocomplete
                          dense
                          filled
                          v-model="associtedAccountId"
                          :items="getAssocAccount"
                          @change="getAssocBankAccount"
                          label="Select Associted Account"
                        ></v-autocomplete>
                      </v-col>
                      <v-col cols="12" md="4">
                        <p class="font-weight-bold">Bank Account</p>
                        <v-autocomplete
                          dense
                          filled
                          v-model="bankAccountId"
                          :items="getBankAccount"
                          label="Select Account"
                        ></v-autocomplete>
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col cols="12" md="4">
                        <v-text-field
                          v-model.trim="invoice_no"
                          label="Invoice no."
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="4">
                        <v-menu
                          v-model="inDateMenu"
                          :close-on-content-click="false"
                          :nudge-right="40"
                          transition="scale-transition"
                          offset-y
                          min-width="auto"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="date"
                              label="Date"
                              v-mask="'####-##-##'"
                              hint="YYYY-MM-DD format"
                              persistent-hint
                              prepend-icon="mdi-calendar"
                              v-bind="attrs"
                              v-on="on"
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            v-model="date"
                            no-title
                            @input="inDateMenu = false"
                          ></v-date-picker>
                        </v-menu>
                      </v-col>
                      <v-col cols="12" md="4">
                        <v-text-field
                          type="number"
                          min="0"
                          v-model.number="term"
                          label="Term"
                        ></v-text-field>
                      </v-col>
                    </v-row>

                    <hr class="my-8" />

                    <v-row
                      v-for="(item, index) in description"
                      :key="index"
                      class="pa-0 mb-0"
                    >
                      <v-col cols="12" md="8">
                        <v-textarea
                        dense
                        class="pa-0"
                          rows="1"
                          label="Task"
                          v-model="item.text"
                        ></v-textarea>
                      </v-col>
                      <v-col cols="12" md="4">
                        <v-text-field
                        dnse
                          type="number"
                          class="pa-0 mb-0"
                          v-model.number="item.amount"
                          label="Amount"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col align="right" cols="12">
                        <v-btn
                          depressed
                          tile
                          @click="addDescription"
                          style="border: 1px solid #ccc"
                        >
                          + Add Task
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-form>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" tile depressed text @click="close"
                  >Cancel</v-btn
                >
                <v-btn
                  color="blue darken-1 white--text"
                  :loading="loadingSave"
                  :disabled="disabledSave"
                  tile
                  depressed
                  @click="save"
                  >Save</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
        <v-row align="center" class="pa-4">
          <v-col cols="12" md="2">
            <v-autocomplete
              :items="getPartyAccount"
              v-model="filterPartyAccountId"
              label="Party Account"
            >
            </v-autocomplete>
          </v-col>
          <v-col cols="12" md="2">
            <v-dialog
              ref="dialog"
              v-model="filterModal"
              :return-value.sync="filterDate"
              persistent
              width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="dateRangeText"
                  label="Select date range"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker v-model="filterDate" range scrollable>
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="filterModal = false">
                  Cancel
                </v-btn>
                <v-btn
                  text
                  color="primary"
                  @click="$refs.dialog.save(filterDate)"
                >
                  OK
                </v-btn>
              </v-date-picker>
            </v-dialog>
          </v-col>
          <v-col cols="12" md="2">
            <v-btn
              class="ma-2 white--text"
              color="blue-grey"
              small
              tile
              depressed
              @click="intialize"
              :loading="tableLoading"
            >
              Search
            </v-btn>
            <v-btn
              class="ma-2"
              color="blue-grey"
              dark
              small
              tile
              depressed
              outlined
              @click="resetFilterValue"
            >
              Reset
            </v-btn>
          </v-col>
        </v-row>
      </template>
      <template v-slot:[`item.invoice_no`]="{ item }">
        <b>{{ item.invoice_no }}</b>
      </template>
      <template v-slot:[`item.term`]="{ item }">
        <v-chip>{{ item.term }}</v-chip>
      </template>
      <template v-slot:[`item.status`]="{ item }">
        <v-chip color="red" small dark label v-if="item.status == 0"
          >UNPAID</v-chip
        >
        <v-chip color="green" small dark label v-if="item.status == 1"
          >PAID</v-chip
        >
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-icon small class="mr-2" @click="editItem(item)"> mdi-pencil </v-icon>
        <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon>

        <v-btn
          depressed
          tile
          small
          class="ml-4"
          @click="downloadPdf(item)"
        >
          Generate PDF
        </v-btn>

        <v-btn
          depressed
          tile
          small
          color="green"
          dark
          class="ml-4"
          @click="markAsPaid(item)"
          v-if="item.status == 0"
        >
          Mark as paid
        </v-btn>

        <v-btn
          depressed
          tile
          small
          class="ml-4"
          color="red"
          dark
          @click="markAsPaid(item)"
          v-if="item.status == 1"
        >
          Mark as unpaid
        </v-btn>
      </template>
    </v-data-table>
    <v-dialog v-model="dialogDelete" max-width="500px">
      <v-card>
        <v-card-title class="headline"
          >Are you sure you want to delete this item?</v-card-title
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
          <v-btn color="blue darken-1" text @click="deleteItemConfirm"
            >OK</v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: ["ivId"],
  data() {
    return {
      loadingSave: false,
      inDateMenu: false,
      dialog: false,
      headers: [
        { text: "Date", value: "date" },
        { text: "Invoice no.", value: "invoice_no" },
        { text: "Party account", value: "party_account_name" },
        { text: "Associted account", value: "partner_account_name" },
        { text: "Term", value: "term" },
        { text: "Status", value: "status" },
        { text: "Action", value: "actions" },
      ],
      invoices: [],
      search: "",
      tableLoading: false,
      partyAccounts: [],
      partyAccountId: 0,
      associtedAccounts: [],
      associtedAccountId: 0,
      bankAccounts: [],
      bankAccountId: 0,
      term: 0,
      date: null,
      invoice_no: "",
      description: [{ text: "", amount: 0 }],
      editedIndex: -1,
      dialogDelete: false,
      deleteIndex: -1,
      filterPartyAccountId: 0,
      filterModal: false,
      filterDate: [],
    };
  },
  created() {
    this.intialize();
  },
  methods: {
    intialize() {
      this.tableLoading = true;
      this.$http
        .get("invoices", {
          params: {
            party_account_id: this.filterPartyAccountId,
            dates: this.filterDate,
            id: this.ivId || 0
          },
          headers: {
            Authorization: "Bearer " + localStorage.getItem("access_token"),
          },
        })
        .then((response) => {
          const data = response.data.data;
          this.partyAccounts = data.accounts.filter(
            (acc) => acc.account_type_id == 3
          );
          this.associtedAccounts = data.accounts.filter(
            (acc) => acc.account_type_id == 1 || acc.account_type_id == 2 || acc.account_type_id == 4 || acc.account_type_id == 5
          );

          this.invoices = data.invoices.map((iv) => {
            return {
              id: iv.id,
              party_account_id: iv.party_account_id,
              party_account_name: this.getItemName(
                iv.party_account.master_account
              ),
              partner_account_id: iv.partner_account_id,
              partner_account_name: this.getItemName(
                iv.partner_account.master_account
              ),
              invoice_no: iv.invoice_no,
              term: iv.term,  
              date: iv.date,
              status: iv.status,
            };
          });
          this.tableLoading = false;
        })
        .catch(({ response }) => {
          this.$toast.error(response.data.error.message.join(", "));
          this.tableLoading = false;
        });
    },
    resetFilterValue() {
      this.filterPartyAccountId = 0;
      this.filterDate = [];
      this.ivId = 0;
      this.intialize();
    },
    getAssocBankAccount() {
      const account = this.associtedAccounts.find(
        (i) => i.id == this.associtedAccountId
      );
      this.$http
        .get(`bank-statement/get-bank-account/${account.master_account.id}`, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("access_token"),
          },
        })
        .then((response) => {
          this.bankAccounts = response.data.data.bankAccounts;
        })
        .catch(({ response }) => {
          this.$toast.error(response.data.error.message.join(", "));
        });
    },
    getItemName(item) {
      if (item.account_type_id == 1 && item.acc_number != null)
        return (
          item.name +
          " " +
          (item.last_name || "") +
          " (" +
          item.acc_number.substring(item.acc_number.length - 4) +
          ")"
        );
      return item.name + " " + (item.last_name || "");
    },
    editItem(item) {
      this.$http
        .get(`invoice/${item.id}`, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("access_token"),
          },
        })
        .then((response) => {
          const invoice = response.data.data.invoice;
          this.partyAccountId = invoice.party_account_id;
          this.associtedAccountId = invoice.partner_account_id;
          this.bankAccountId = invoice.bank_account_id;
          this.term = invoice.term;
          this.date = invoice.date;
          this.invoice_no = invoice.invoice_no;
          this.description = invoice.description;
          this.bankAccounts = response.data.data.bankAccounts;
          this.editedIndex = item.id;
          this.dialog = true;
        })
        .catch(({ response }) => {
          this.$toast.error(response.data.error.message.join(", "));
        });
    },
    save() {
      this.loadingSave = true;
      this.$http
        .post(
          "invoice",
          {
            id: this.editedIndex,
            party_account_id: this.partyAccountId,
            partner_account_id: this.associtedAccountId,
            bank_account_id: this.bankAccountId,
            term: this.term,
            date: this.date,
            invoice_no: this.invoice_no,
            description: this.description.filter(
              (des) => des.text != "" || des.amount != 0
            ),
          },
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("access_token"),
            },
          }
        )
        .then((response) => {
          this.$toast.success(response.data.data.message.join(", "));
          this.loadingSave = false;
          this.dialog = false;
          this.intialize();
          this.close();
        })
        .catch(({ response }) => {
          this.$toast.error(response.data.error.message.join(", "));
          this.loadingSave = false;
        });
    },
    addDescription() {
      this.description.push({
        text: "",
        amount: "",
      });
    },
    close() {
      this.dialog = false;
      this.loadingSave = false;
      this.partyAccountId = 0;
      this.associtedAccountId = 0;
      this.bankAccountId = 0;
      this.term = 0;
      this.date = null;
      this.invoice_no = "";
      this.description = [{ text: "", amount: "" }];
      this.editedIndex = -1;
    },
    deleteItem(item) {
      this.deleteIndex = item.id;
      this.dialogDelete = true;
    },
    deleteItemConfirm() {
      this.$http
        .post(
          `invoice/delete/${this.deleteIndex}`,
          {},
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("access_token"),
            },
          }
        )
        .then((response) => {
          this.$toast.success(response.data.data.message.join(", "));
          this.closeDelete();
          this.intialize();
        })
        .catch(({ response }) => {
          this.$toast.error(response.data.error.message.join(", "));
        });
    },
    closeDelete() {
      this.deleteIndex = -1;
      this.dialogDelete = false;
    },
    downloadPdf(item) {
      this.$http
        .get(`invoice/pdf/${item.id}`, {
          responseType: "blob",
          headers: {
            Authorization: "Bearer " + localStorage.getItem("access_token"),
          },
        })
        .then((response) => {
          const file = new Blob([response.data], { type: "application/pdf" });
          //Build a URL from the file
          const fileURL = URL.createObjectURL(file);
          //Open the URL on new Window
          window.open(fileURL);
        })
        .catch(({ response }) => {
          this.$toast.error(response.data.error.message.join(", "));
        });
    },
    markAsPaid(item) {
      this.$http
        .get(`invoice/paid/${item.id}`, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("access_token"),
          },
        })
        .then((response) => {
          this.$toast.success(response.data.data.message.join(", "));
          this.intialize();
        })
        .catch(({ response }) => {
          this.$toast.error(response.data.error.message.join(", "));
        });
    },
  },
  computed: {
    getAssocAccount() {
      return this.associtedAccounts.map((acc) => {
        return {
          text: this.getItemName(acc.master_account),
          value: acc.id,
        };
      });
    },
    getPartyAccount() {
      return this.partyAccounts.map((acc) => {
        return {
          text: this.getItemName(acc.master_account),
          value: acc.id,
        };
      });
    },
    getBankAccount() {
      return this.bankAccounts.map((acc) => {
        return {
          text: acc.bank_name,
          value: acc.id,
        };
      });
    },
    disabledSave() {
      return (
        this.partyAccountId == 0 ||
        this.associtedAccountId == 0 ||
        this.bankAccountId == 0 ||
        this.invoice_no == 0 ||
        this.date == null
      );
    },
    dateRangeText() {
      return this.filterDate.join(" ~ ");
    },
  },
};
</script>

<style>
</style>