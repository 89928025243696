export default {
    userPermissions(permissionList) {
        const userPermission = JSON.parse(window.atob(localStorage.getItem('permission')));
        let flag = true;
        let count = 0;
        permissionList.forEach(element => {
            if(userPermission.includes(element)) {  
                count++;
            }
        });
        return count === permissionList.length;
    },

    hasPermission(list, all = false){
        const userPermission = JSON.parse(window.atob(localStorage.getItem('permission')));
        if(all){
            let count = 0;
            list.forEach(function(permission){
                if(userPermission.includes(permission)){
                    count++;
                }
            })
            return count == list.length;
        }else{
            return list.filter(function(permission){
                return userPermission.filter(function(_permission){
                    return _permission == permission
                }).length > 0
            }).length > 0
        }
    }
    
}