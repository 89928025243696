<template>
  <div>
    <v-card
      elevation="1"
      outlined
      tile
      class="mr-4 ml-4 mt-4"
    >
    <v-simple-table>
      <template v-slot:top>
        <v-toolbar color="grey lighten-3" flat>
          <v-toolbar-title>Link Account</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <v-dialog v-model="dialog" max-width="600px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="green" dark tile class="mb-2" v-bind="attrs" v-on="on">
                <v-icon left>
                  mdi mdi-link
                </v-icon>
                Link Account
              </v-btn>
            </template>
            <v-card>
              <v-card-title>
                <span class="headline">{{ formTitle }}</span>
              </v-card-title>

              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="12" sm="6" md="6">
                      <v-select
                        v-model="partnerAccount"
                        :items="partnerAccounts"
                        item-value="value"
                        label="Select Account"
                        required
                      ></v-select>
                    </v-col>
                    <v-col cols="12" sm="6" md="6">
                      <v-select
                        v-model="assignAccount"
                        :items="assignAccounts"
                        item-value="value"
                        label="Select Account"
                        :disabled="isDisabled"
                        required
                        multiple
                      ></v-select>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="close"> Cancel </v-btn>
                <v-btn color="blue darken-1" text @click="save"> Save </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
              <v-card-title class="headline"
                >Are you sure you want to delete this item?</v-card-title
              >
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="closeDelete"
                  >Cancel</v-btn
                >
                <v-btn color="blue darken-1" text @click="deleteItemConfirm"
                  >OK</v-btn
                >
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
        <v-text-field
          v-model="search"
          label="Search"
          class="mx-4"
        ></v-text-field>
      </template>
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-center" width="500px">
              Partner Account
            </th>
            <th class="text-left">
              Link Account
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in partnerAccounts" :key="item.value">
            <td class="text-center">
              {{ item.text }}
            </td>
            <td class="text-left" v-if="accounts[item.text]">
              <v-chip
                label
                class="ma-2"
                close
                color="info"
                v-for="v in accounts[item.text]"
                :key="v.id"
                @click:close="deleteItem(v)"
              >
                {{ v.account.master_account.name }}
              </v-chip>
            </td>
            <td v-else>No account assign</td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
    </v-card>
  </div>
</template>

<script>
export default {
  beforeCreate() {
    if (
      !this.$permission.userPermissions([
        "view-link-page",
      ])
    )
      this.$router.push({ name: "dashboard" });
  },
  data: () => ({
    search: '',
    dialog: false,
    dialogDelete: false,
    isDisabled: false,
    headers: [
      {
        text: "Link Account",
        align: "center",
        value: "link_account",
      },
      {
        text: "Partner Account",
        align: "center",
        value: "partner_account",
      },
      { text: "Actions", value: "actions", sortable: false },
    ],
    accounts: [],
    partnerAccounts: [],
    partnerAccount: null,
    assignAccounts: [],
    assignAccount: null,
    editedIndex: -1,
    editedItem: {
      partnerAccount: null,
      assignAccount: null,
    },
    defaultItem: {
      partnerAccount: null,
      assignAccount: null,  
    },
  }),
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "New Link Account" : "Edit Link Account";
    },
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },
  created() {
    let keyPressed = false;
    document.addEventListener("keydown", (e) => {
      if(e.code == "AltLeft") {
        keyPressed = true;
      }
    })
    document.addEventListener("keyup", (e) => {
      if(keyPressed && e.code == "KeyT") {
        this.dialog = !this.dialog
      }
      keyPressed = false;
    })
    this.fetchPartnerAccounts();
    this.fetchAccounts();
  },
  methods: {
    fetchPartnerAccounts() {
      this.$http
        .get("partner-accounts", {
            headers: {
              Authorization: "Bearer " + localStorage.getItem('access_token')
            }
          }
        )
        .then((response) => {
          this.partnerAccounts = [];
          this.assignAccounts = [];
          let accounts = response.data.data.accounts
          accounts.forEach(account => {
            if(account.account_type_id == 5) {
              this.partnerAccounts.push({
                value: account.id,
                text: account.master_account.name
              })
            } else {
              this.assignAccounts.push({
                value: account.id,
                text: account.master_account.name
              })
            }
          })
        })
        .catch((error) => {
          console.log("error", error.response);
        });
    },
    fetchAccounts() {
      const user_id = JSON.parse(window.atob(localStorage.getItem('user'))).id
      this.$http.get("link-account", {
        params: {
          user_id
        },
        headers: {
          Authorization: "Bearer " + localStorage.getItem('access_token')
        }
      }).then((response) => {
        this.accounts = [];
        const accounts = response.data.data.linkAccounts;
        this.accounts = accounts;
      });
    },

     getItemName(item){
        if(item.account_type_id == 1 && item.acc_number != null) return item.name + ' ' + (item.middle_name || '') + ' ' + (item.last_name || '') + ' (' + item.acc_number.substring(item.acc_number.length - 4)  + ')';
        return item.name + ' ' + (item.middle_name || '') + ' ' + (item.last_name || '');
    },

    editItem(item) {
      this.isDisabled = true;
      this.assignAccount = [];
      this.partnerAccount = this.partnerAccounts.find(
        (type) => type.value === item.partner_account_id
      ).value;
      this.assignAccount.push(this.assignAccounts.find(
        (type) => type.value === item.account_id
      ).value);
      this.editedIndex = item.id;
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },
    deleteItem(item) {
      this.editedIndex = item.id;
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },
    deleteItemConfirm() {
      this.deleteAssignedAccount(this.editedIndex);
      this.closeDelete();
    },
    close() {
      this.dialog = false;
      this.assignAccount = null;
      this.isDisabled = false;
      this.partnerAccount = null;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    save() {
      if (
        this.partnerAccount == "" ||
        this.partnerAccount == null ||
        this.assignAccount.length == 0 ||
        this.assignAccount == null
      ) {
        return;
      }
      const data = {
        partner_account_id: this.partnerAccount,
        account_id: this.assignAccount,
      };
      if (this.editedIndex > -1) {
        this.updateAccount(data);
      } else {
        this.$http
          .post("link-account", data, {
            headers: {
              Authorization: "Bearer " + localStorage.getItem('access_token')
            }
          }
        )
          .then((response) => {
            if (response.data.status === 200) {
              this.fetchAccounts();
              this.close();
              this.$toast.success(response.data.data.message[0]);
            }
          })
          .catch((error) => {
            this.$toast.error("Something went wrong");
            console.log(error);
          });
      }
    },

    updateAccount(data) {
      this.$http
        .put("link-account/" + this.editedIndex, data, {
            headers: {
              Authorization: "Bearer " + localStorage.getItem('access_token')
            }
          }
        )
        .then((response) => {
          if (response.data.status === 200) {
            this.close();
            this.fetchAccounts();
            this.$toast.success(response.data.data.message[0]);
          }
        })
        .catch((error) => {
          this.$toast.error("Something went wrong");
          console.log(error);
        });
    },

    deleteAssignedAccount(id) {
      this.$http
        .delete("link-account/" + id, {
            headers: {
              Authorization: "Bearer " + localStorage.getItem('access_token')
            }
          }
        )
        .then((response) => {
          this.fetchAccounts();
          this.$toast.success(response.data.data.message[0]);
        })
        .catch((error) => {
          this.$toast.error("Something went wrong");
          console.log(error);
        });
    },
  },
};
</script>
