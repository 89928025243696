import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify';
import vueRouter from 'vue-router';
import axios from 'axios'
import route from './route.js';
import Toast from "vue-toastification";
import Permission from "./utils/Permission.js"
import "vue-toastification/dist/index.css";
import { VueMaskDirective } from 'v-mask';
import VueApexCharts from 'vue-apexcharts'
import { defaultRedirectionLink, redirectToDefaultRoute } from './utils/helpers.js';

Vue.use(Toast, {
  transition: "Vue-Toastification__bounce",
  maxToasts: 20,
  newestOnTop: true,
  position: "top-right",
  timeout: 5000,
  closeOnClick: true,
  pauseOnFocusLoss: true,
  pauseOnHover: true,
  draggable: true,
  draggablePercent: 0.6,
  showCloseButtonOnHover: true,
  closeButton: "button",
  icon: true,
  rtl: false
});
Vue.directive('mask', VueMaskDirective);

Vue.use(vueRouter);
Vue.use(VueApexCharts);
Vue.component('apexchart', VueApexCharts)

 axios.defaults.baseURL = "https://journal-api.codexivesolutions.com/api/"
// axios.defaults.baseURL = "http://127.0.0.1:8000/api/"

Vue.prototype.$http = axios;
Vue.prototype.$permission = Permission;
Vue.prototype.$apiURL = "https://journal-api.codexivesolutions.com/api/";
// Vue.prototype.$apiURL = "http://127.0.0.1:8000/api/";
Vue.config.productionTip = false;

Vue.prototype.defaultRedirectionLink = defaultRedirectionLink;
Vue.prototype.redirectToDefaultRoute = redirectToDefaultRoute;

new Vue({
  vuetify,
  router: route,
  render: h => h(App)
}).$mount('#app')
