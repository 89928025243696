<template>
  <div>
    <v-card
      elevation="1"
      outlined
      tile
      class="mr-4 ml-4 mt-4"
    >
    <v-toolbar color="grey lighten-3 mb-4" flat>
        <v-toolbar-title>Salary Report</v-toolbar-title>
        <v-divider class="mx-2" inset vertical></v-divider>
    </v-toolbar>
      <v-row class="pa-3">
        <v-col cols="12" md="3">
          <v-autocomplete
            dense
            filled
            v-model="accountTypeId"
            :items="accountTypes"
            label="Select Account Types"
            @input="fetchAccounts"
          ></v-autocomplete>
        </v-col>
        <v-col cols="12" md="3">
          <v-autocomplete
            dense
            filled
            v-model="accountId"
            :items="accounts"
            label="Select Accounts"
          ></v-autocomplete>
        </v-col>
        <v-col cols="12" md="3">
          <v-dialog
            ref="searchDialog"
            v-model="searchDateModal"
            :return-value.sync="searchMonth"
            persistent
            width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="filterSearchMonth"
                label="Select Month"
                prepend-icon="mdi-calendar"
                readonly
                dense
                filled
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker range v-model="searchMonth" type="month" scrollable>
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="searchDateModal = false">
                Cancel
              </v-btn>
              <v-btn
                text
                color="primary"
                @click="$refs.searchDialog.save(searchMonth)"
              >
                OK
              </v-btn>
            </v-date-picker>
          </v-dialog>
        </v-col>
        <v-col cols="12" md="3">
          <v-btn
            :loading="loading"
            :disabled="disabled"
            color="info"
            class="ma-2 white--text"
            @click="generateReport"
            depressed
            tile
          >
            Search
          </v-btn>
          <v-btn
            color="info"
            class="ma-2 white--text"
            @click="resetSearchValue"
            :disabled="disabledReset"
            depressed
            tile
            outlined
          >
            Reset
          </v-btn>
          <v-btn
            color="success darken-1"
            class="ma-2 white--text"
            @click="exportExcel"
            :disabled="disabled2"
            :loading="loading2"
            depressed
            tile
          >
            Export Excel
          </v-btn>
        </v-col>
      </v-row>

      <v-data-table
        :headers="headers"
        :footer-props="{ 'items-per-page-options': [10, 20, 30, 40, 50, -1] }"
        :items-per-page="50"
        :items="transactions"
        :loading="loadingtable"
        loading-text="Loading... Please wait"
      >
        <template v-slot:[`item.actions`]="{ item }">
          <v-btn
            small
            depressed
            till
            class="mr-2"
            @click="goToTransaction(item)"
          >
            Go to Transaction
          </v-btn>
        </template>

        <template v-slot:[`item.ctc`]="{ item }">
          <v-chip outlined color="primary" text-color="black">{{ item.ctc }}</v-chip>
        </template>

        <template v-slot:[`item.amount`]="{ item }">
          <v-chip outlined color="green" text-color="black">{{ item.amount }}</v-chip>
        </template>

        <template v-slot:body.append>
        <tr class="grey lighten-5">
          <td></td>
          <td></td>
          <td><v-chip color="primary" text-color="white">{{ totalPayableAmount }}</v-chip></td>
          <td><v-chip color="green" text-color="white">{{ totalTransferAmount }}</v-chip></td>
          <td></td>
        </tr>
      </template>
      </v-data-table>

    </v-card>
  </div>
</template>

<script>
export default {
  data() {
    return {
      accountTypes: [],
      accountTypeId: 0,
      accounts: [],
      accountId: 0,
      searchMonth: [],
      searchDateModal: false,
      loading: false,
      loading2: false,
      loadingtable: false,
      transactions: [],
      headers: [
        { text: "Name", value: "name"},
        { text: "Month", value: "salary_month"},
        { text: "CTC", value: "ctc"},
        { text: "Amount Transfer", value: "amount"},
        { text: "actions", value: "actions"},
      ],
    };
  },

  created() {
    this.fetchAccountTypes();
  },

  methods: {
    fetchAccountTypes() {
      this.$http
        .get(`salary-report/account-types`, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("access_token"),
          },
        })
        .then((response) => {
          const accountTypes = response.data.data.accountType;
          this.accountTypes = [];

          accountTypes.forEach((element) => {
            this.accountTypes.push({
              text: element.name,
              value: element.id,
            });
          });
        })
        .catch(() => {
          this.$toast.error("Something went wrong");
        });
    },
    fetchAccounts() {
      this.$http
        .get(`salary-report/accounts/${this.accountTypeId}`, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("access_token"),
          },
        })
        .then((response) => {
          const accounts = response.data.data.accounts;
          this.accounts = [];

          accounts.forEach((element) => {
            this.accounts.push({
              text: this.getItemName(element.master_account),
              value: element.id
            })
          })
        })
        .catch(() => {
          this.$toast.error("Something went wrong");
        });
    },
    generateReport() {
      this.loading = true;
      this.loadingtable = true;

      var formData = new FormData();
      formData.append("account_type_id", this.accountTypeId);
      formData.append("account_id", this.accountId);
      formData.append("search_month", this.searchMonth);

      this.$http
        .post(`salary-report/generate-report`, formData, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("access_token")
          }
        }).then((response) => {
          const transactions = response.data.data.transactions

          this.transactions = []
          transactions.forEach((transaction) =>{
            this.transactions.push({
              id: transaction.id,
              name: this.getItemName(transaction.to_account.master_account),
              salary_month: transaction.salary_month,
              amount: transaction.amount,
              ctc: transaction.ctc ? transaction.ctc.ctc : 0
            })
          })

          this.loading = false;
          this.loadingtable = false;

        }).catch((error) => {
          console.error(error)
          this.$toast.error("Something went wrong");
          this.loading = false;
          this.loadingtable = false; 
        })
    },
    exportExcel() {
      this.loading2 = true;
      var formData = new FormData();
      formData.append("account_type_id", this.accountTypeId);
      formData.append("account_id", this.accountId);
      formData.append("search_month", this.searchMonth);

      this.$http
        .post('salary-report/generate-excel', formData, {
          responseType: "blob",
          headers: {
            Authorization: "Bearer " + localStorage.getItem("access_token")
          }
        }).then((response) => {
          const url = window.URL.createObjectURL(response.data);
          const link = document.createElement("a");
          link.href = url;
          const fileName = this.searchMonth.join('_to_') + '_salary_report.xlsx';
          link.setAttribute("download", fileName.replace(/-/g,'_'));
          document.body.appendChild(link);
          link.click();
          this.loading2 = false;
        }).catch(() => {
          this.$toast.error("Something went wrong");
          this.loading2 = false;
        })
    },
    getItemName(item) {
      if (item.account_type_id == 1 && item.acc_number != null)
        return (
          item.name +
          " (" +
          item.acc_number.substring(item.acc_number.length - 4) +
          ")"
        );
      return item.name + " " + (item.last_name || "");
    },
    resetSearchValue() {
      this.accountTypeId = 0; 
      this.accountId = 0; 
      this.searchMonth = [];
      this.transactions = [];
      this.accounts = [];
    },
    goToTransaction(value) {
      let routeData = this.$router.resolve({
        name: "transactions",
        params: { trId: value.id },
      });
      if (routeData) {
        window.open(routeData.href, "_blank");
      }
    },
  },

  computed: {
    filterSearchMonth() {
      return this.searchMonth.join(" ~ ");
    },
    disabled() {
      return this.accountTypeId == 0 && this.searchMonth.length == 0;
    },
    disabled2() {
      return this.transactions.length == 0;
    },
    disabledReset() {
      return this.accountTypeId == 0 && this.accountId == 0 && this.searchMonth.length == 0;
    },
    totalPayableAmount() {
      let totalAmount = 0;
      this.transactions.map((element) => {
         totalAmount += element.ctc
      });

      return totalAmount;
    },  
    totalTransferAmount() {
      let totalAmount = 0;
      this.transactions.map((element) => {
         totalAmount += element.amount
      });

      return totalAmount;
    }
  }
};
</script>

<style>
</style>