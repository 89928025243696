var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{staticClass:"mr-4 ml-4 mt-4",attrs:{"elevation":"1","outlined":"","tile":""}},[_c('v-toolbar',{attrs:{"color":"grey lighten-3 mb-4","flat":""}},[_c('v-toolbar-title',[_vm._v("Salary Report")]),_c('v-divider',{staticClass:"mx-2",attrs:{"inset":"","vertical":""}})],1),_c('v-row',{staticClass:"pa-3"},[_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-autocomplete',{attrs:{"dense":"","filled":"","items":_vm.accountTypes,"label":"Select Account Types"},on:{"input":_vm.fetchAccounts},model:{value:(_vm.accountTypeId),callback:function ($$v) {_vm.accountTypeId=$$v},expression:"accountTypeId"}})],1),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-autocomplete',{attrs:{"dense":"","filled":"","items":_vm.accounts,"label":"Select Accounts"},model:{value:(_vm.accountId),callback:function ($$v) {_vm.accountId=$$v},expression:"accountId"}})],1),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-dialog',{ref:"searchDialog",attrs:{"return-value":_vm.searchMonth,"persistent":"","width":"290px"},on:{"update:returnValue":function($event){_vm.searchMonth=$event},"update:return-value":function($event){_vm.searchMonth=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Select Month","prepend-icon":"mdi-calendar","readonly":"","dense":"","filled":""},model:{value:(_vm.filterSearchMonth),callback:function ($$v) {_vm.filterSearchMonth=$$v},expression:"filterSearchMonth"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.searchDateModal),callback:function ($$v) {_vm.searchDateModal=$$v},expression:"searchDateModal"}},[_c('v-date-picker',{attrs:{"range":"","type":"month","scrollable":""},model:{value:(_vm.searchMonth),callback:function ($$v) {_vm.searchMonth=$$v},expression:"searchMonth"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.searchDateModal = false}}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.$refs.searchDialog.save(_vm.searchMonth)}}},[_vm._v(" OK ")])],1)],1)],1),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-btn',{staticClass:"ma-2 white--text",attrs:{"loading":_vm.loading,"disabled":_vm.disabled,"color":"info","depressed":"","tile":""},on:{"click":_vm.generateReport}},[_vm._v(" Search ")]),_c('v-btn',{staticClass:"ma-2 white--text",attrs:{"color":"info","disabled":_vm.disabledReset,"depressed":"","tile":"","outlined":""},on:{"click":_vm.resetSearchValue}},[_vm._v(" Reset ")]),_c('v-btn',{staticClass:"ma-2 white--text",attrs:{"color":"success darken-1","disabled":_vm.disabled2,"loading":_vm.loading2,"depressed":"","tile":""},on:{"click":_vm.exportExcel}},[_vm._v(" Export Excel ")])],1)],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"footer-props":{ 'items-per-page-options': [10, 20, 30, 40, 50, -1] },"items-per-page":50,"items":_vm.transactions,"loading":_vm.loadingtable,"loading-text":"Loading... Please wait"},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"mr-2",attrs:{"small":"","depressed":"","till":""},on:{"click":function($event){return _vm.goToTransaction(item)}}},[_vm._v(" Go to Transaction ")])]}},{key:"item.ctc",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"outlined":"","color":"primary","text-color":"black"}},[_vm._v(_vm._s(item.ctc))])]}},{key:"item.amount",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"outlined":"","color":"green","text-color":"black"}},[_vm._v(_vm._s(item.amount))])]}},{key:"body.append",fn:function(){return [_c('tr',{staticClass:"grey lighten-5"},[_c('td'),_c('td'),_c('td',[_c('v-chip',{attrs:{"color":"primary","text-color":"white"}},[_vm._v(_vm._s(_vm.totalPayableAmount))])],1),_c('td',[_c('v-chip',{attrs:{"color":"green","text-color":"white"}},[_vm._v(_vm._s(_vm.totalTransferAmount))])],1),_c('td')])]},proxy:true}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }