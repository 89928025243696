<template>
  <div class="mr-4 ml-4 mt-4">
    <v-data-table
      :headers="headers"
      :items="users"
      :loading="tableLoading"
      sort-by="calories"
      class="elevation-1"
      :search="search"
    >
      <template v-slot:top>
        <v-toolbar color="grey lighten-3 mb-4" flat>
          <v-toolbar-title>User</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <v-dialog v-model="dialog" max-width="500px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="green"
                dark
                tile
                class="mb-2"
                v-bind="attrs"
                v-on="on"
              >
                + New User
              </v-btn>
            </template>
            <v-card>
              <v-card-title>
                <span class="text-h5">{{ formTitle }}</span>
              </v-card-title>

              <v-card-text>
                <v-container>
                  <v-text-field
                    v-model="name"
                    :error-messages="nameErrors"
                    label="Name"
                    required
                    @input="$v.name.$touch()"
                    @blur="$v.name.$touch()"
                  ></v-text-field>
                  <v-text-field
                    v-model="email"
                    :error-messages="emailErrors"
                    label="E-mail"
                    required
                    @input="$v.email.$touch()"
                    @blur="$v.email.$touch()"
                  ></v-text-field>
                  <v-text-field
                    v-if="editedIndex == -1"
                    v-model="password"
                    :error-messages="passwordErrors"
                    :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="showPassword ? 'text' : 'password'"
                    label="Password"
                    class="input-group--focused"
                    @click:append="showPassword = !showPassword"
                    required
                    @input="$v.password.$touch()"
                    @blur="$v.password.$touch()"
                  ></v-text-field>
                  <v-select
                    v-model="partner_account_id"
                    :items="partner_accounts"
                    label="Partner Account"
                  ></v-select>

                  <v-select
                    v-model="role_id"
                    :items="roles"
                    :error-messages="roleErrors"
                    label="Role"
                  ></v-select>
                </v-container>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="close">
                  Cancel
                </v-btn>
                <v-btn color="blue darken-1" text @click="save"> Save </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
              <v-card-title class="text-h5"
                >Are you sure you want to delete this item?</v-card-title
              >
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="closeDelete"
                  >Cancel</v-btn
                >
                <v-btn color="blue darken-1" text @click="deleteItemConfirm"
                  >OK</v-btn
                >
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
        <v-text-field
          v-model="search"
          label="Search"
          class="mx-4"
        ></v-text-field>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-icon small class="mr-2" @click="editItem(item)"> mdi-pencil </v-icon>
        <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon>
        <v-btn @click="resetPassword(item)" depressed tile small class="ml-4">
          Reset Password
        </v-btn>
        <v-btn v-if="user.role == 1" @click="attachUser(item)" depressed tile small class="ml-4">
          Attach user
        </v-btn>
      </template>
      <template v-slot:no-data>
        <p>No data available.</p>
      </template>
    </v-data-table>
    <v-overlay style="z-index: 9999" :value="overlay">
      <v-progress-circular indeterminate size="32"></v-progress-circular>
    </v-overlay>
    <template>
      <v-row justify="center">
        <v-dialog v-model="attachUserModal" persistent max-width="600px">
          <v-card>
            <v-card-title>
              <span class="text-h5">Users list</span>
            </v-card-title>
            <v-card-text>
              <v-row>
                <v-col cols="12" sm="12">
                  <v-autocomplete
                    :items="getUserList"
                    v-model="atttachUserArr"
                    label="Select user"
                    multiple
                  ></v-autocomplete>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="blue darken-1"
                text
                @click="attachUserModal = false"
              >
                Close
              </v-btn>
              <v-btn color="blue darken-1" text @click="saveAttachUser">
                Save
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
    </template>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, maxLength, email } from "vuelidate/lib/validators";

export default {
  mixins: [validationMixin],

  beforeCreate() {
    if (!this.$permission.userPermissions(["view-user-page"]))
      this.$router.push({ name: "dashboard" });
  },

  validations: {
    name: { required, maxLength: maxLength(20) },
    email: { required, email },
    password: { required },
    role_id: { required },
  },

  data: () => ({
    user: null,
    attachUserModal: false,
    attachUserId: -1,
    atttachUserArr: [],
    search: "",
    overlay: false,
    dialog: false,
    dialogDelete: false,
    headers: [
      { text: "Name", align: "start", sortable: true, value: "name" },
      { text: "Email", value: "email", sortable: true },
      { text: "Role", value: "role_name", sortable: true },
      { text: "Partner Account", value: "partner_account", sortable: true },
      { text: "Actions", value: "actions", sortable: false },
    ],
    users: [],
    editedIndex: -1,
    editedItem: {
      name: "",
      email: "",
      partner_account_id: 0,
      role_id: "",
    },
    defaultItem: {
      name: "",
      email: "",
      password: "",
      partner_account_id: 0,
      role_id: "",
    },
    partner_accounts: [
      {
        text: "Select Partner Account",
        value: 0,
      },
    ],
    roles: [
      {
        text: "Company",
        value: 2,
      },
      {
        text: "User",
        value: 3,
      },
      {
        text: "CA",
        value: 4,
      },
    ],
    editId: null,
    name: "",
    email: "",
    password: "",
    role_id: "",
    partner_account_id: 0,
    showPassword: false,
  }),

  computed: {
    getUserList() {
      const users = this.users.filter((u) => {
        return u.id != this.attachUserId;
      });
      return users.map((u) => {
        return { text: u.name + " - " + u.email, value: u.id };
      });
    },
    formTitle() {
      return this.editedIndex === -1 ? "New User" : "Edit User";
    },
    nameErrors() {
      const errors = [];
      if (!this.$v.name.$dirty) return errors;
      !this.$v.name.maxLength &&
        errors.push("Name must be at most 10 characters long");
      !this.$v.name.required && errors.push("Name is required.");
      return errors;
    },
    passwordErrors() {
      const errors = [];
      if (!this.$v.password.$dirty) return errors;
      !this.$v.password.required && errors.push("Password is required.");
      return errors;
    },
    emailErrors() {
      const errors = [];
      if (!this.$v.email.$dirty) return errors;
      !this.$v.email.email && errors.push("Must be valid e-mail");
      !this.$v.email.required && errors.push("E-mail is required");
      return errors;
    },

    roleErrors() {
      const errors = [];
      if (!this.$v.role_id.$dirty) return errors;
      !this.$v.role_id.required && errors.push("Role is required");
      return errors;
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  created() {
    let keyPressed = false;
    document.addEventListener("keydown", (e) => {
      if (e.code == "AltLeft") {
        keyPressed = true;
      }
    });
    document.addEventListener("keyup", (e) => {
      if (keyPressed && e.code == "KeyT") {
        this.dialog = !this.dialog;
      }
      keyPressed = false;
    });
    const user = JSON.parse(window.atob(localStorage.getItem("user")));
    this.user = user;
    this.initialize();
    this.fetchPartnerAccount();
  },

  methods: {
    saveAttachUser() {
      this.overlay = true;
      this.$http
        .post(
          `save-attach-user/${this.attachUserId}`,
          {
            users: this.atttachUserArr,
          },
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("access_token"),
            },
          }
        )
        .then((response) => {
          this.$toast.success(response.data.data.message.join(", "));
          this.overlay = false;
          this.attachUserModal = false;
        })
        .catch(({ response }) => {
          this.$toast.error(response.data.error.message.join(", "));
          this.overlay = false;
        });
    },
    resetPassword(item) {
      this.overlay = true;
      this.$http
        .post("reset-password", item, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("access_token"),
          },
        })
        .then((response) => {
          if (response.status == 200) {
            this.$toast.success(response.data.data.message.join(", "));
          }
          this.overlay = false;
        })
        .catch(() => {
          this.$toast.error(
            "Reset password link failed to sent. please try again"
          );
          this.overlay = false;
        });
    },
    attachUser(item) {
      this.overlay = true;
      this.$http
        .get(`get-attach-users/${item.id}`, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("access_token"),
          },
        })
        .then((response) => {
          this.atttachUserArr = response.data.data.users;
          this.attachUserId = item.id;
          this.attachUserModal = true;
          this.overlay = false;
        }).catch(({response}) => {
          this.$toast.error(response.data.error.message.join(", "));
          this.overlay = false;
        });
    },
    fetchPartnerAccount() {
      this.$http
        .get("typed/partner-account", {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("access_token"),
          },
        })
        .then((response) => {
          this.partner_accounts = [];
          this.partner_accounts.push({
            text: "Select Partner Account",
            value: 0,
          });
          if (response.data.data.length > 0) {
            response.data.data.forEach((element) => {
              this.partner_accounts.push({
                text: element.master_account.name + ' - ' + element.branch.name,
                value: element.id,
              });
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    initialize() {
      this.tableLoading = true;
      this.$http
        .get("users-list", {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("access_token"),
          },
        })
        .then((response) => {
          if (response.data.status === 200) {
            const users = response.data.data.users;
            this.users = [];
            users.forEach((element) => {
              this.users.push({
                id: element.id,
                partner_account_id: element.partner_account_id,
                name: element.name,
                email: element.email,
                partner_account: element.partner_account
                  ? element.partner_account.master_account.name
                  : "",
                role_name: element.group.name,
                role_id: element.group.id,
              });
            });
          }
          this.tableLoading = false;
        })
        .catch((error) => {
          console.error(error);
          this.tableLoading = false;
        });
    },

    editItem(item) {
      this.editedIndex = this.users.indexOf(item);
      this.name = item.name;
      this.email = item.email;
      this.partner_account_id = item.partner_account_id;
      this.role_id = item.role_id;
      this.editId = item.id;
      this.dialog = true;
    },

    deleteItem(item) {
      this.editId = item.id;
      this.editedIndex = this.users.indexOf(item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      this.$http
        .delete("user/" + this.editId + "/delete", {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("access_token"),
          },
        })
        .then((response) => {
          this.$toast.success(response.data.data.message[0]);
          this.initialize();
        })
        .catch((error) => {
          this.$toast.error("Something went wrong");
          console.log(error);
        });
      this.users.splice(this.editedIndex, 1);
      this.closeDelete();
    },

    close() {
      this.dialog = false;
      this.$v.$reset();
      this.$nextTick(() => {
        this.name = this.defaultItem.name;
        this.email = this.defaultItem.email;
        this.password = this.defaultItem.password;
        this.partner_account_id = this.defaultItem.partner_account_id;
        this.role_id = this.defaultItem.role_id;
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    save() {
      this.$v.$touch();
      if (this.editedIndex > -1) {
        if (this.name === "" || this.email === "") return;
        this.$http
          .put(
            "user/" + this.editId + "/update",
            {
              name: this.name,
              email: this.email,
              partner_account_id: this.partner_account_id,
              role_id: this.role_id,
            },
            {
              headers: {
                Authorization: "Bearer " + localStorage.getItem("access_token"),
              },
            }
          )
          .then((response) => {
            this.$toast.success(response.data.data.message[0]);
            this.initialize();
          })
          .catch((error) => {
            this.$toast.error("Something went wrong");
            console.log(error);
          });
      } else {
        if (this.name === "" || this.email === "" || this.password === "")
          return;
        this.$http
          .post(
            "user/create",
            {
              name: this.name,
              email: this.email,
              password: this.password,
              partner_account_id: this.partner_account_id,
              role_id: this.role_id,
            },
            {
              headers: {
                Authorization: "Bearer " + localStorage.getItem("access_token"),
              },
            }
          )
          .then((response) => {
            this.$toast.success(response.data.data.message[0]);
            this.initialize();
          })
          .catch((error) => {
            this.$toast.error("Something went wrong");
            console.log(error);
          });
      }
      this.close();
    },
  },
};
</script>

<style>
</style>