<template>
  <!-- Google Drive Dialog Box Start -->
  <div class="text-center">
    <!-- Drive Dialog Start -->
    <v-dialog
      v-model="googleDriveDialog"
      persistent
      :fullscreen="isDriveDialogExpand"
      width="1220px"
    >
      <v-card>
        <v-card-title class="bg-google-green pa-4">
          <v-chip color="success" class="px-4">
            <div style="display: flex; align-items: center">
              <img style="width: 24px" src="google-drive.png" alt="" />
              <span class="ml-3">Google Drive</span>
            </div>
          </v-chip>

          <div class="ml-auto">
            <v-btn
              style="min-width: auto; padding: 4px"
              color="transparent"
              dark
              @click="isDriveDialogExpand = !isDriveDialogExpand"
              depressed
              tile
            >
              <v-icon>{{
                isDriveDialogExpand ? "mdi-fullscreen-exit" : "mdi-fullscreen"
              }}</v-icon>
            </v-btn>

            <v-btn
              style="min-width: auto; padding: 4px; z-index: 99"
              color="transparent"
              dark
              @click="minimizedDialog"
              depressed
              tile
            >
              <v-icon>mdi-arrow-bottom-right</v-icon>
            </v-btn>

            <v-btn
              style="min-width: auto; padding: 4px"
              color="transparent"
              dark
              @click="googleDriveDialog = false"
              depressed
              tile
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </div>
        </v-card-title>

        <v-card-text
          :class="`pa-4 ${isDriveDialogExpand ? 'fce-height' : 'fc-height'}`"
          style="overflow: auto"
        >
          <v-row style="border-bottom: 1px solid lightgray">
            <v-col
              cols="12"
              style="
                display: flex;
                align-items: center;
                justify-content: space-between;
              "
            >
              <div style="display: flex; align-items: center">
                /
                <v-breadcrumbs
                  class="py-0 px-2"
                  :items="recentFolderList"
                  divider="/"
                >
                </v-breadcrumbs>
              </div>

              <div>
                <v-btn
                  tile
                  small
                  dark
                  depressed
                  class="ml-4"
                  color="blue rounded-xl"
                  @click="newFolderDialog = true"
                >
                  + New Folder
                </v-btn>
              </div>
            </v-col>
          </v-row>

          <v-row class="pb-4" v-if="!loadingDrive">
            <v-col
              v-if="recentFolderList.length > 1"
              :cols="isDriveDialogExpand ? 2 : 3"
            >
              <button @click="getLastFolderId" class="folder-button other">
                ...
              </button>
            </v-col>
          </v-row>

          <div v-if="!loadingDrive" class="drive-list-name">
            <span>Folders</span>
          </div>

          <v-row class="pb-4" v-if="!loadingDrive && folderList.length > 0">
            <v-col
              :cols="isDriveDialogExpand ? 2 : 3"
              v-for="folder in folderList"
              :key="folder.id"
            >
              <div class="folder-row">
                <button @click="getFolderId(folder.id)" class="folder-button">
                  <img
                    style="width: 24px"
                    class="mr-4"
                    src="folder.png"
                    alt=""
                  />
                  <span
                    style="
                      white-space: nowrap;
                      overflow: hidden;
                      text-overflow: ellipsis;
                    "
                    >{{ folder.file_name }}</span
                  >
                </button>
                <button
                  class="folder-delete-icon"
                  @click="confirmDelete(folder.id)"
                >
                  <v-icon right dark class="mr-2">mdi-delete</v-icon>
                </button>
              </div>
            </v-col>

            <v-overlay
              :absolute="true"
              :value="driveOverlay"
              style="opacity: 0.15"
            >
              <v-progress-circular
                color="black"
                indeterminate
                size="32"
              ></v-progress-circular>
            </v-overlay>
          </v-row>

          <v-row v-else class="pb-4">
            <v-col
              :cols="isDriveDialogExpand ? 2 : 3"
              class="pa-4"
              align="center"
              v-if="!loadingDrive"
            >
              <div class="folder-button file other">No folders found.</div>
            </v-col>
          </v-row>

          <div v-if="!loadingDrive" class="drive-list-name">
            <span>Files</span>
          </div>

          <v-row
            v-if="!loadingDrive && otherFolderList.length > 0"
            class="pb-4"
          >
            <v-col
              :cols="isDriveDialogExpand ? 2 : 3"
              v-for="folder in otherFolderList"
              :key="folder.id"
            >
              <div class="folder-row file">
                <div class="folder-button file" :title="folder.file_name">
                  <img style="width: 24px" class="mr-4" src="file.png" alt="" />
                  <span
                    style="
                      white-space: nowrap;
                      overflow: hidden;
                      text-overflow: ellipsis;
                    "
                    >{{ folder.file_name }}</span
                  >
                </div>
                <button
                  class="folder-delete-icon"
                  @click="confirmDelete(folder.id)"
                >
                  <v-icon right dark class="mr-2">mdi-delete</v-icon>
                </button>
              </div>
            </v-col>
          </v-row>

          <v-row v-else class="pb-4">
            <v-col
              :cols="isDriveDialogExpand ? 2 : 3"
              class="pa-4"
              align="center"
              v-if="!loadingDrive"
            >
              <div class="folder-button file other">No files found.</div>
            </v-col>
          </v-row>

          <v-overlay
            :absolute="true"
            :value="driveOverlay"
            style="opacity: 0.15"
          >
            <v-progress-circular
              color="black"
              indeterminate
              size="32"
            ></v-progress-circular>
          </v-overlay>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions v-if="upload" class="py-4">
          <v-spacer></v-spacer>
          <v-btn
            color="green"
            class="rounded-xl px-5"
            text
            tile
            @click="googleDriveDialog = false"
          >
            Close
          </v-btn>

          <v-btn
            color="green"
            class="rounded-xl pl-3 pr-5"
            dark
            :loading="loading8"
            depressed
            tile
            @click="setGoogleDriveCallFunction"
          >
            <v-icon right dark class="mr-2"> mdi-cloud-upload </v-icon>
            Upload
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Drive Dialog End -->

    <!-- New Folder Dialog Start -->
    <v-dialog v-model="newFolderDialog" width="300px">
      <v-card>
        <v-card-title class="bg-google-green pa-4">
          <v-chip color="success" class="px-4">
            <div style="display: flex; align-items: center">
              <span>Folder</span>
            </div>
          </v-chip>

          <v-btn
            class="ml-auto"
            color="transparent"
            dark
            @click="newFolderDialog = false"
            depressed
            tile
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text class="pa-4">
          <v-text-field
            class="mt-6"
            label="New Folder"
            v-model="folderName"
            outlined
          ></v-text-field>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions class="py-4">
          <v-spacer></v-spacer>
          <v-btn
            color="green"
            class="rounded-xl px-5"
            text
            tile
            @click="newFolderDialog = false"
          >
            Close
          </v-btn>

          <v-btn
            color="green"
            class="rounded-xl px-5"
            dark
            :loading="loading9"
            depressed
            tile
            @click="createGoogleDriveFolder"
          >
            Create
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- New Folder Dialog End -->

    <!-- Remove Folder Dialog Start -->
    <v-dialog v-model="removeFolderFileDialog" persistent max-width="290">
      <v-card>
        <v-card-title class="pb-4 red red--text lighten-5">
          Remove Item
        </v-card-title>

        <v-card-text class="pt-4">
          Are you sure you want to delete this item?
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="red darken-1" text @click="closeDeleteDialog">
            Close
          </v-btn>

          <v-btn
            color="red darken-1"
            :loading="loading10"
            text
            @click="confirmDeleteDialog"
          >
            Delete
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Remove Folder Dialog End -->

    <!-- Google Drive Minimize Dialog Start -->
    <div
      :class="`minimized-popup ${type ? 'upper-dialog' : ''}`"
      v-if="minimizeDriveDialog"
    >
      <div style="display: flex; align-items: center">
        <img style="width: 24px" src="google-drive.png" alt="" />
        <span class="ml-3">{{ minimizedPopupTitle }}</span>
      </div>

      <div style="display: flex; align-items: center">
        <div v-if="loadingDrive">
          <v-progress-circular
            width="3"
            :size="20"
            indeterminate
            color="blue"
          ></v-progress-circular>
        </div>
        <v-btn
          style="min-width: auto; padding: 4px; margin-left: 8px"
          color="green"
          dark
          text
          @click="minimizedDialog"
          depressed
          tile
        >
          <v-icon>mdi-arrow-top-left</v-icon>
        </v-btn>
        <v-btn
          style="min-width: auto; padding: 4px"
          color="success"
          dark
          text
          @click="closeMinimizeDialog"
          depressed
          tile
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>
    </div>
    <!-- Google Drive Minimize Dialog End -->
  </div>
  <!-- Google Drive Dialog Box End -->
</template>

<script>
export default {
  props: {
    data: Object,
    upload: {
      type: String,
      default: null,
    },
    minimizedPopupTitle: {
      type: String,
      default: "Google Drive",
    },
    type: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      isDriveDialogExpand: false,
      driveOverlay: false,
      folderList: [],
      otherFolderList: [],
      googleDriveDialog: false,
      loadingDrive: false,
      folderId: "root",
      recentFolderList: [],
      folderName: "New Folder",
      newFolderDialog: false,
      minimizeDriveDialog: false,
      removeFolderFileDialog: false,
      deleteFolderId: null,
      loading8: false,
      loading9: false,
      loading10: false,
    };
  },
  methods: {
    minimizedDialog() {
      this.googleDriveDialog = !this.googleDriveDialog;
      this.minimizeDriveDialog = !this.minimizeDriveDialog;
    },
    closeDeleteDialog() {
      this.deleteFolderId = null;
      this.removeFolderFileDialog = false;
    },
    closeMinimizeDialog() {
      this.minimizeDriveDialog = false;
      this.googleDriveDialog = false;
    },
    getRootFolder() {
      this.folderId = "root";
      this.recentFolderList = [
        {
          id: this.folderId,
          text: "My Drive",
        },
      ];
      this.folderList = [];
      this.otherFolderList = [];
      this.minimizeDriveDialog = false;
      this.googleDriveDialog = true;
      this.getGoogleDriveFolder();
    },
    getFolderId(id, flag = false) {
      this.recentFolderList.push({
        id: this.folderId,
        text: this.folderList.find((i) => i.id == id)?.file_name,
      });

      this.folderId = id;
      this.getGoogleDriveFolder();
    },
    getLastFolderId() {
      this.folderId = this.recentFolderList.pop().id;
      this.getGoogleDriveFolder();
    },
    getGoogleDriveFolder() {
      this.driveOverlay = true;
      this.folderList = [];
      this.otherFolderList = [];
      this.loadingDrive = true;
      this.$http
        .get(`income-export/google-drive/${this.folderId}`, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("access_token"),
          },
        })
        .then((response) => {
          this.folderList = response.data.data.folders;
          this.otherFolderList = response.data.data.other_files;
          this.loadingDrive = false;
          this.driveOverlay = false;
        })
        .catch((error) => {
          console.log(error);
          this.loadingDrive = false;
          this.driveOverlay = false;
        });
    },
    // income-export/google-drive/create-folder
    createGoogleDriveFolder() {
      this.loading9 = true;
      this.$http
        .get(`income-export/google-drive-create-folder`, {
          params: {
            folderId: this.folderId,
            folderName: this.folderName,
          },
          headers: {
            Authorization: "Bearer " + localStorage.getItem("access_token"),
          },
        })
        .then((response) => {
          this.$toast.success(response.data.data.message[0]);
          this.loading9 = false;
          this.newFolderDialog = false;
          this.getGoogleDriveFolder();
        })
        .catch(({ response }) => {
          this.$toast.error(response.data.error.message[0]);
          console.log(response);
          this.loading9 = false;
        });
    },
    setGoogleDriveCallFunction() {
      this[this.upload]();
    },
    uploadOnGoogleDrive() {
      this.loadingDrive = true;
      this.driveOverlay = true;
      this.loading8 = true;
      this.$http
        .get(
          `income-export/upload-google-drive/${this.data.account}/${this.data.downloadType}?0=${this.data.dates}`,
          {
            params: {
              folder: this.folderId,
            },
            headers: {
              Authorization: "Bearer " + localStorage.getItem("access_token"),
            },
          }
        )
        .then((response) => {
          this.$toast.success(response.data.data.message[0]);
          this.getGoogleDriveFolder();
          this.loading8 = false;
        })
        .catch(({ response }) => {
          // console.log(error);
          this.$toast.error(response.data.error.message[0]);
          this.loadingDrive = false;
          this.driveOverlay = false;
          this.loading8 = false;
        });
    },
    saveRojmelToDrive() {
      this.loadingDrive = true;
      this.driveOverlay = true;
      this.loading8 = true;
      this.$http
        .get(`rojmel/upload-google-drive`, {
          params: {
            folder: this.folderId,
            0: this.data.dates,
            type: this.data.driveExportType,
            layout: this.data.driveExportLayoutId,
          },
          headers: {
            Authorization: "Bearer " + localStorage.getItem("access_token"),
          },
        })
        .then((response) => {
          this.$toast.success(response.data.data.message[0]);
          this.getGoogleDriveFolder();
          this.loading8 = false;
        })
        .catch(({ response }) => {
          // console.log(error);
          this.$toast.error(response.data.error.message[0]);
          this.loadingDrive = false;
          this.driveOverlay = false;
          this.loading8 = false;
        });
    },
    uploadStatementOnGoogleDrive() {
      this.loadingDrive = true;
      this.driveOverlay = true;
      this.loading8 = true;
      let data = new FormData();
      data.append("excel", this.data.excel);
      this.$http
        .post(`bank-statement/upload-google-drive`, data, {
          params: {
            folder: this.folderId,
          },
          headers: {
            Authorization: "Bearer " + localStorage.getItem("access_token"),
          },
        })
        .then((response) => {
          this.$toast.success(response.data.data.message[0]);
          this.getGoogleDriveFolder();
          this.loading8 = false;
        })
        .catch(({ response }) => {
          // console.log(error);
          this.$toast.error(response.data.error.message[0]);
          this.loadingDrive = false;
          this.driveOverlay = false;
          this.loading8 = false;
        });
    },
    confirmDelete(folderId) {
      this.deleteFolderId = folderId;
      this.removeFolderFileDialog = true;
    },
    confirmDeleteDialog() {
      this.loading10 = true;
      this.$http
        .get(`income-export/google-drive-delete-folder`, {
          params: {
            folderId: this.deleteFolderId,
          },
          headers: {
            Authorization: "Bearer " + localStorage.getItem("access_token"),
          },
        })
        .then((response) => {
          this.$toast.success(response.data.data.message[0]);
          this.deleteFolderId = null;
          this.removeFolderFileDialog = false;
          this.loading10 = false;
          this.getGoogleDriveFolder();
        })
        .catch(({ response }) => {
          this.$toast.error(response.data.error.message[0]);
          this.deleteFolderId = null;
          this.removeFolderFileDialog = false;
          this.loading10 = false;
        });
    },
  },
};
</script>

<style>
.drive-list-name {
  padding: 16px 4px;
  color: #969696;
  font-size: 14px;
}

.folder-row {
  display: flex;
  border-radius: 8px;
  overflow: hidden;
}

.folder-row.file {
  border: 1px solid black;
}

.folder-button {
  width: calc(100% - 42px);
  display: flex;
  align-items: center;
  padding: 16px;
  color: black;
  background-color: #ebebeb;
  border: 1px solid #ebebeb;
  font-weight: bold;
  letter-spacing: 0.5px;
  opacity: 0.7;
}

.folder-button.other {
  width: 100%;
  border-radius: 8px;
}

.folder-delete-icon {
  background-color: #ebebeb;
  border: 1px solid #ebebeb;
  opacity: 0.7;
}

.folder-delete-icon:hover {
  background-color: #ffe8e6;
}

.folder-button.file {
  background-color: transparent;
}

.folder-button:hover {
  background-color: #c7f2dd;
  border-color: #c7f2dd;
}

.bg-google-green {
  background-color: #0f9d58;
  color: white;
}

.minimized-popup {
  width: 320px;
  padding: 10px 16px 8px;
  background-color: #f5fffa;
  border: 2px solid #0f9d58;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  border-bottom: none;
  position: fixed;
  bottom: 0;
  right: 16px;
  z-index: 999;
  font-weight: bold;
  color: #0f9d58;
  display: flex;
  align-items: center;
  justify-content: space-between;
  user-select: none;
}

.fc-height {
  height: 640px !important;
}

.fce-height {
  height: calc(100vh - 140px) !important;
}

.upper-dialog {
  z-index: 1000;
}
</style>