<template>
  <div class="form-center">
    <v-card class="cart-width">
      <v-row align="center" justify="center">
        <img :src="logoUrl" />
      </v-row>
      <v-card-title primary-title>
        <h4>Login</h4>
      </v-card-title>

      <v-alert
        v-if="message"
        border="right"
        color="red"
        class="mx-4"
        elevation="4"
        text
        type="success"
        >{{ message }}</v-alert
      >

      <v-form class="pr-4 pl-4">
        <v-text-field
          v-model.trim="email"
          :error-messages="emailErrors"
          label="E-mail"
          required
          @input="$v.email.$touch()"
          @blur="$v.email.$touch()"
        ></v-text-field>
        <v-text-field
          type="password"
          v-model="password"
          :error-messages="passwordErrors"
          :counter="10"
          label="Password"
          required
          @input="$v.password.$touch()"
          @blur="$v.password.$touch()"
        ></v-text-field>
        <v-row align="center" class="mt-4">
          <v-col cols="6">
            <v-btn
              block
              color="success"
              depressed
              tile
              @click="submit"
              :loading="loginLoading"
            >
              Login to Account
            </v-btn>
          </v-col>
          <v-col cols="6">
            <v-btn @click="clear" block depressed tile outlined>
              Reset Credentials
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-card>
    <!-- </v-container> -->
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, maxLength, email } from "vuelidate/lib/validators";
import axios from "axios";

export default {
  mixins: [validationMixin],

  validations: {
    password: { required, maxLength: maxLength(10) },
    email: { required, email },
    select: { required },
    checkbox: {
      checked(val) {
        return val;
      },
    },
  },

  created() {
    this.getCompanyLogo();
  },

  data: () => ({
    snackbar: false,
    password: "",
    email: "",
    message: "",
    loginLoading: false,
    logoUrl: null,
  }),
  computed: {
    passwordErrors() {
      const errors = [];
      if (!this.$v.password.$dirty) return errors;
      !this.$v.password.maxLength &&
        errors.push("password must be at most 10 characters long");
      !this.$v.password.required && errors.push("password is required.");
      return errors;
    },
    emailErrors() {
      const errors = [];
      if (!this.$v.email.$dirty) return errors;
      !this.$v.email.email && errors.push("Must be valid e-mail");
      !this.$v.email.required && errors.push("E-mail is required");
      return errors;
    },
  },

  methods: {
    getCompanyLogo() {
      this.$http
        .get("get-company-logo")
        .then((response) => {
          this.logoUrl = response.data.data.company_logo.company_logo_url
        })
        .catch(({ response }) => {
          this.$toast.error(response.data.error.message.join(", "));
        });
    },
    submit() {
      this.loginLoading = true;
      if (this.email != "" && this.password != "") {
        this.$v.$touch();
        axios
          .post("auth/login", { email: this.email, password: this.password })
          .then((response) => {
            if (response.status == 200) {
              localStorage.setItem("access_token", response.data.access_token);
              localStorage.setItem(
                "permission",
                window.btoa(
                  JSON.stringify(
                    response.data.permissions.map((item) => item.slug)
                  )
                )
              );
              localStorage.setItem(
                "user",
                window.btoa(JSON.stringify(response.data.user))
              );


              this.$router.replace(this.defaultRedirectionLink[response.data.user.role]);
            }
            this.loginLoading = false;
          })
          .catch((error) => {
            console.log("error", error);
            if (error.response.status == 422) {
              this.message = error.response.data.message;
              this.snackbar = true;
            }
            if (error.response.status == 401) {
              this.message = error.response.data.message;
              this.snackbar = true;
            }
            this.loginLoading = false;
          });
      } else {
        this.message = "please enter email and password";
        this.loginLoading = false;
      }
    },
    clear() {
      this.$v.$reset();
      this.password = "";
      this.email = "";
    },
  },
};
</script>
<style>
.form-center {
  height: 100vh;
  display: flex;
  place-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.02);
}
.cart-width {
  width: 540px;
  padding: 32px;
  box-shadow: none !important;
}
</style>
