<template>
  <div>
    <v-card elevation="1" outlined tile class="mr-4 ml-4 mt-4">
      <v-toolbar color="grey lighten-3 mb-4" flat>
        <v-toolbar-title>Earning Report</v-toolbar-title>
        <v-divider class="mx-2" inset vertical></v-divider>
      </v-toolbar>
      <v-row class="pa-3">
        <v-col cols="12" md="2">
          <v-autocomplete
            dense
            filled
            v-model="clientTypeId"
            :items="clientType"
            label="Select Client Type"
          ></v-autocomplete>
        </v-col>
        <v-col cols="12" md="2">
          <v-autocomplete
            dense
            filled
            v-model="partyAccountId"
            :items="partyAccounts"
            label="Select Party"
          ></v-autocomplete>
        </v-col>
        <v-col cols="12" md="2">
          <v-file-input
            label="Upload Excel"
            dense
            filled
            v-model="excelFile"
            prepend-icon="mdi-file-plus"
          ></v-file-input>
        </v-col>
        <v-col cols="12" md="3" v-if="isSelectMonth">
          <v-dialog
            ref="dialog"
            v-model="dateModal"
            :return-value.sync="inputMonth"
            persistent
            width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="inputMonth"
                label="Select Month"
                prepend-icon="mdi-calendar"
                readonly
                dense
                filled
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker v-model="inputMonth" type="month" scrollable>
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="dateModal = false">
                Cancel
              </v-btn>
              <v-btn
                text
                color="primary"
                @click="$refs.dialog.save(inputMonth)"
              >
                OK
              </v-btn>
            </v-date-picker>
          </v-dialog>
        </v-col>
        <v-col cols="12" md="3">
          <v-btn
            :loading="loading"
            :disabled="disabled"
            color="success"
            class="ma-2 white--text"
            @click="uploadExcel"
            depressed
            tile
          >
            Upload
            <v-icon right dark> mdi-cloud-upload </v-icon>
          </v-btn>
          <v-btn
            color="info"
            class="ma-2 white--text"
            @click="showExcelList"
            :loading="loading3"
            depressed
            tile
          >
            Show Uploaded Report
          </v-btn>
        </v-col>
      </v-row>
    </v-card>
    <v-card elevation="1" outlined tile class="mr-4 ml-4 mt-4">
      <v-toolbar color="grey lighten-3 mb-4" flat>
        <v-toolbar-title>Search Earning Report</v-toolbar-title>
        <v-divider class="mx-2" inset vertical></v-divider>
      </v-toolbar>
      <v-row class="pa-3">
        <v-col cols="12" md="2">
          <v-autocomplete
            dense
            filled
            v-model="searchClientTypeId"
            :items="clientType"
            label="Select Client Type"
          ></v-autocomplete>
        </v-col>
        <v-col cols="12" md="2">
          <v-autocomplete
            dense
            filled
            v-model="searchPartyAccountId"
            :items="partyAccountsList"
            label="Select Party"
          ></v-autocomplete>
        </v-col>
        <v-col cols="12" md="2">
          <v-autocomplete
            dense
            filled
            v-model="clientId"
            :items="clientsList"
            label="Select Client"
          ></v-autocomplete>
        </v-col>
        <v-col cols="12" md="2">
          <v-autocomplete
            dense
            filled
            v-model="clientProjectId"
            :items="clientProjectsList"
            label="Select Project"
          ></v-autocomplete>
        </v-col>
        <v-col cols="12" md="2">
          <v-dialog
            ref="searchDialog"
            v-model="searchDateModal"
            :return-value.sync="searchMonth"
            persistent
            width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="filterSearchMonth"
                label="Select Month"
                prepend-icon="mdi-calendar"
                readonly
                dense
                filled
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker range v-model="searchMonth" type="month" scrollable>
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="searchDateModal = false">
                Cancel
              </v-btn>
              <v-btn
                text
                color="primary"
                @click="$refs.searchDialog.save(searchMonth)"
              >
                OK
              </v-btn>
            </v-date-picker>
          </v-dialog>
        </v-col>
        <v-col cols="12" md="2">
          <v-btn
            :loading="loading2"
            :disabled="disabled2"
            color="primary"
            class="ma-2 white--text"
            @click="search"
            depressed
            tile
          >
            Search
          </v-btn>
          <v-btn
            :disabled="disabled2"
            outlined
            color="primary"
            class="ma-2 white--text"
            @click="resetSearchValue"
            depressed
            tile
          >
            Reset
          </v-btn>
        </v-col>
      </v-row>

      <v-simple-table>
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left">Party</th>
              <th class="text-left">Client</th>
              <th class="text-left">Project</th>
              <th class="text-left">Date</th>
              <th class="text-left">Total Billed</th>
              <th class="text-left">Fess Paid</th>
              <th class="text-left">Taxes Paid</th>
              <th class="text-left">Earning (Rate)</th>
              <th class="text-left">Earning (INR)</th>
              <th class="text-left"></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in projectList" :key="item.id">
              <td>{{ item.project.client.account.master_account.name }}</td>
              <td>{{ item.project.client.client_name }}</td>
              <td>{{ item.project.project_name }}</td>
              <td>{{ item.format_month }}</td>
              <td class="text-green">
                {{ item.currency_sign }} {{ item.total_billed.toFixed(2) }}
              </td>
              <td class="text-red">
                {{ item.currency_sign }} {{ item.fees_paid.toFixed(2) }}
              </td>
              <td class="text-red">
                {{ item.currency_sign }} {{ item.taxes_paid.toFixed(2) }}
              </td>
              <td class="text-green">
                {{ item.currency_sign }} {{ item.earning.toFixed(2) }}
                <span class="text-dark">({{ item.currency_rate }})</span>
              </td>
              <td class="text-green">
                <v-chip outlined color="green" dark>
                  &#8377; {{ item.inr_earning }}
                </v-chip>
              </td>
              <td>
                <v-btn
                  icon
                  class="mr-4"
                  color="info"
                  small
                  @click="editProjectEarning(item)"
                >
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
                <v-btn
                  icon
                  small
                  color="red"
                  @click="deleteProjectEarning(item.id)"
                >
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </td>
            </tr>
            <tr class="grey lighten-5">
              <th></th>
              <th></th>
              <th></th>
              <th>Total:</th>
              <th class="text-green">
                <v-chip
                  outlined
                  color="green"
                  small
                  class="my-1"
                  v-for="(item, index) in totalBilled"
                  :key="index"
                  >{{ item.sign }} {{ item.total }}</v-chip
                >
              </th>
              <th class="text-red">
                <v-chip
                  outlined
                  color="red"
                  small
                  class="my-1"
                  v-for="(item, index) in feesPaid"
                  :key="index"
                  >{{ item.sign }} {{ item.total }}</v-chip
                >
              </th>
              <th class="text-red">
                <v-chip
                  outlined
                  color="red"
                  small
                  class="my-1"
                  v-for="(item, index) in taxesPaid"
                  :key="index"
                  >{{ item.sign }} {{ item.total }}</v-chip
                >
              </th>
              <th class="text-green">
                <v-chip
                  outlined
                  color="green"
                  small
                  class="my-1"
                  v-for="(item, index) in earning"
                  :key="index"
                  >{{ item.sign }} {{ item.total }}</v-chip
                >
              </th>
              <th class="text-green">
                <v-chip color="green" dark> &#8377; {{ inrEarning }} </v-chip>
              </th>
              <th></th>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card>
    <v-dialog v-model="dialogDelete" max-width="500px">
      <v-card>
        <v-card-title class="text-h5"
          >Are you sure you want to delete this item?</v-card-title
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeDeleteProjectEarning"
            >Cancel</v-btn
          >
          <v-btn color="blue darken-1" text @click="confirmDeleteProjectEarning"
            >OK</v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-row justify="center">
      <v-dialog v-model="editReportDialog" persistent max-width="600px">
        <v-card>
          <v-toolbar color="grey lighten-3" flat>
            <v-toolbar-title style="font-size: 16px" class="font-weight-bold pa-6 gray--text">{{ editDialogTitle }}</v-toolbar-title>
          </v-toolbar>
          <v-card-text class="pt-8">
            <v-container>
              <v-row>
                <v-col cols="12" sm="4">
                  <v-text-field
                    dense
                    filled
                    label="Earning"
                    v-model="editedEarning"
                    :disabled="true"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="4">
                  <v-text-field
                    dense
                    filled
                    label="Currency Rate"
                    v-model="editedCurrencyRate"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="4">
                  <v-text-field
                    dense
                    filled
                    label="INR Earning"
                    v-model="editedInrEarning"
                    :disabled="true"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="closeEditReportDialog">
              Close
            </v-btn>
            <v-btn
              :loading="editLoading"
              :disabled="disabledEditSave"
              color="primary"
              class="white--text"
              @click="saveEditReport"
              depressed
              tile
            >
              Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
    <v-bottom-sheet v-model="sheet">
      <v-sheet class="text-center">
        <v-btn class="mt-6" text color="error" @click="sheet = !sheet">
          close
        </v-btn>
        <div class="my-3">
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-center">Report Month</th>
                  <th class="text-center">Party Account</th>
                  <th class="text-center">File Name</th>
                  <th class="text-center">Added On</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in excelList" :key="item.id">
                  <td>{{ item.month }}</td>
                  <td v-html="item.name"></td>
                  <td>
                    <a :href="item.web_path">{{ item.original_name }}</a>
                  </td>
                  <td>{{ item.created_at }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </div>
      </v-sheet>
    </v-bottom-sheet>
  </div>
</template>

<script>
import _ from "lodash";

export default {
  data() {
    return {
      inputMonth: null,
      excelFile: null,
      dateModal: false,
      loading: false,
      loading2: false,
      loading3: false,
      projectList: [],
      clients: [],
      clientId: 0,
      partyAccounts: [],
      partyAccountId: 0,
      clientProjects: [],
      clientProjectId: 0,
      searchPartyAccountId: 0,
      searchClientTypeId: 0,
      clientTypeId: 1,
      searchMonth: [],
      searchDateModal: false,
      excelList: [],
      sheet: false,
      dialogDelete: false,
      deleteItemId: null,
      editDialogTitle: "Edit",
      editReportDialog: false,
      editedIndex: -1,
      editedCurrencyRate: 0.0,
      editedEarning: 0.0,
      editLoading: false,
    };
  },

  beforeCreate() {
    if (!this.$permission.userPermissions(["view-project-earning-page"]))
      this.$router.push({ name: "dashboard" });
  },

  async created() {
    await this.fetchClientName();
  },

  methods: {
    editProjectEarning(item) {
      this.editDialogTitle = item.project.project_name + ' (' + item.format_month + ')';
      this.editedIndex = item.id;
      this.editedCurrencyRate = item.currency_rate;
      this.editedEarning = item.earning.toFixed(2);
      this.editReportDialog = true;
    },
    closeEditReportDialog() {
      this.editReportDialog = false;
      this.editDialogTitle = "Edit";
      this.editedIndex = -1;
      this.editedCurrencyRate = 0.0;
      this.editedEarning = 0.0;
    },
    saveEditReport() {
      this.editLoading = true;
      const data = {
        currency_rate: this.editedCurrencyRate,
        inrEarning: this.editedInrEarning,
      };

      this.$http
        .post(`edit-earning-report/${this.editedIndex}`, data, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("access_token"),
          },
        })
        .then((response) => {
          this.$toast.success(response.data.data.message.join(", "));
          this.editLoading = false;
          this.closeEditReportDialog();
          this.search();
        })
        .catch(({ response }) => {
          this.$toast.error(response.data.error.message.join(", "));
          this.editLoading = false;
        });
    },
    deleteProjectEarning(id) {
      this.deleteItemId = id;
      this.dialogDelete = true;
    },
    confirmDeleteProjectEarning() {
      this.dialogDelete = false;
      this.$http
        .post(
          `project-earning/${this.deleteItemId}`,
          {},
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("access_token"),
            },
          }
        )
        .then((response) => {
          this.$toast.success(response.data.data.message.join(" , "));
          this.search();
        })
        .catch((error) => {
          console.error(error);
          this.$toast.error("Something went wrong");
        });
    },
    closeDeleteProjectEarning() {
      (this.deleteItemId = null), (this.dialogDelete = false);
    },
    fetchPartyClient() {
      this.$http
        .get("earning-report/get-party-client/" + this.searchPartyAccountId, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("access_token"),
          },
        })
        .then((response) => {
          const clients = response.data.data.clients;

          this.clients = [];
          clients.forEach((element) => {
            this.clients.push({
              text: element.client_name,
              value: element.id,
            });
          });
        })
        .catch(() => {
          this.$toast.error("Something went wrong");
        });
    },
    showExcelList() {
      this.loading3 = true;
      this.$http
        .get("show-uploaded-report-list", {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("access_token"),
          },
        })
        .then((response) => {
          if (response.data.status === 200) {
            const excelList = response.data.data.list;
            this.excelList = [];
            excelList.forEach((element) => {
              this.excelList.push({
                month: element.month,
                original_name: element.original_name,
                web_path: element.web_path,
                created_at: element.created_at,
                name: element.party_account
                  ? this.getItemName(element.party_account.master_account)
                  : '<span class="grey--text">No account Found</span>',
              });
            });
            this.sheet = true;
            this.loading3 = false;
          }
        })
        .catch((error) => {
          this.$toast.error("Something went wrong");
          console.log(error);
          this.loading3 = false;
        });
    },
    async fetchClientName() {
      await this.$http
        .get("clients", {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("access_token"),
          },
        })
        .then((response) => {
          if (response.data.status === 200) {
            const clients = response.data.data.clients;
            const projects = response.data.data.projects;
            const partyAccounts = response.data.data.partyAccounts;

            this.partyAccounts = [];
            partyAccounts.forEach((element) => {
              this.partyAccounts.push({
                text: this.getItemName(element.master_account),
                value: element.id,
              });
            });

            this.clients = [];
            clients.forEach((element) => {
              this.clients.push({
                text: element.client_name,
                value: element.id,
                party_account_id: element.account_id,
                type: element.type,
              });

              this.partyAccounts.forEach((item) => {
                if (item.value == element.account_id) {
                  item.type = element.type;
                }
              });
            });

            this.clientProjects = [];
            projects.forEach((element) => {
              this.clients.forEach((client) => {
                if (client.value == element.client_id) {
                  this.clientProjects.push({
                    text: element.project_name,
                    value: element.id,
                    client: element.client_id,
                    type: client.type,
                    party_account_id: client.party_account_id,
                  });
                }
              });
            });
          }
        })
        .catch((error) => {
          this.$toast.error("Something went wrong");
          console.log(error);
        });
    },
    fetchClientProject() {
      this.$http
        .get("client/project/" + this.clientId, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("access_token"),
          },
        })
        .then((response) => {
          if (response.data.status === 200) {
            const projects = response.data.data.projects;
            this.clientProjects = [];
            projects.forEach((element) => {
              this.clientProjects.push({
                text: element.project_name,
                value: element.id,
              });
            });
          }
        })
        .catch((error) => {
          this.$toast.error("Something went wrong");
          console.log(error);
        });
    },
    uploadExcel() {
      this.loading = true;
      var data = new FormData();
      data.append("client_type_id", this.clientTypeId);
      data.append("report_month", this.inputMonth);
      data.append("excel_file", this.excelFile);
      data.append("account_id", this.partyAccountId);

      this.$http
        .post("earning-report", data, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("access_token"),
          },
        })
        .then(async (response) => {
          if (response.data.status === 200) {
            await this.fetchClientName();
            this.$toast.success(response.data.data.message[0]);
          }
          this.loading = false;
        })
        .catch((error) => {
          this.$toast.error("Something went wrong");
          console.log(error);
          this.loading = false;
        });
    },

    search() {
      this.loading2 = true;
      let data = new FormData();
      data.append("client_id", this.clientId);
      data.append("party_account_id", this.searchPartyAccountId);
      data.append("client_type_id", this.searchClientTypeId);
      data.append("client_project_id", this.clientProjectId);
      data.append("search_month", this.searchMonth ?? 0);

      this.$http
        .post("earning-report/search", data, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("access_token"),
          },
        })
        .then((response) => {
          if (response.data.status === 200) {
            const data = response.data.data.report;
            this.projectList = data;
          }
          this.loading2 = false;
        })
        .catch((error) => {
          this.$toast.error("Something went wrong");
          console.log(error);
          this.loading2 = false;
        });
    },
    resetSearchValue() {
      this.clientProjectId = 0;
      this.clientId = 0;
      this.searchPartyAccountId = 0;
      this.searchClientTypeId = 0;
      this.searchMonth = [];
    },
    getItemName(item) {
      if (item.account_type_id == 1 && item.acc_number != null)
        return (
          item.name +
          " " +
          (item.last_name || "") +
          " (" +
          item.acc_number.substring(item.acc_number.length - 4) +
          ")"
        );
      return item.name + " " + (item.last_name || "");
    },
  },

  watch: {
    searchClientTypeId() {
      this.clientId = 0;
      this.clientProjectId = 0;
      this.searchPartyAccountId = 0;
    },
    searchPartyAccountId() {
      this.clientId = 0;
      this.clientProjectId = 0;
    },
    clientId() {
      this.clientProjectId = 0;
    },
  },

  computed: {
    editedInrEarning() {
      return (this.editedEarning * this.editedCurrencyRate).toFixed(2);
    },
    disabledEditSave() {
      return this.editedCurrencyRate == '';
    },
    partyAccountsList() {
      let data = this.partyAccounts;
      if (this.searchClientTypeId) {
        data = this.partyAccounts.filter((item) => {
          return item.type == this.searchClientTypeId;
        });
      }
      return data;
    },
    clientsList() {
      let data = this.clients;

      if (this.searchPartyAccountId) {
        data = this.clients.filter((item) => {
          return item.party_account_id == this.searchPartyAccountId;
        });
      } else if (this.searchClientTypeId) {
        data = this.clients.filter((item) => {
          return item.type == this.searchClientTypeId;
        });
      }
      return data;
    },
    clientProjectsList() {
      let data = this.clientProjects;
      if (this.clientId) {
        data = this.clientProjects.filter((item) => {
          return item.client == this.clientId;
        });
      } else if (this.searchPartyAccountId) {
        data = this.clientProjects.filter((item) => {
          return item.party_account_id == this.searchPartyAccountId;
        });
      } else if (this.searchClientTypeId) {
        data = this.clientProjects.filter((item) => {
          return item.type == this.searchClientTypeId;
        });
      }
      return data;
    },
    clientType() {
      return [
        {
          text: "Upwork Client",
          value: 1,
        },
        {
          text: "Direct Client",
          value: 2,
        },
      ];
    },
    isSelectMonth() {
      return this.clientTypeId == 1;
    },
    disabled() {
      if (this.clientTypeId == 1) {
        return (
          this.inputMonth == null ||
          this.excelFile == null ||
          this.partyAccountId == 0
        );
      } else {
        return this.excelFile == null || this.partyAccountId == 0;
      }
    },
    disabled2() {
      return (
        this.searchClientTypeId == 0 &&
        this.searchPartyAccountId == 0 &&
        this.clientId == 0 &&
        this.searchMonth.length == 0 &&
        this.clientProjectId == 0
      );
    },
    totalBilled() {
      let totalAmountArr = [];
      if (this.projectList.length > 0) {
        let groupBy = _.groupBy(this.projectList, "currency_sign");
        _.forEach(groupBy, (value, key) => {
          let totalAmount = 0;
          value.forEach((item) => {
            totalAmount += item.total_billed;
          });

          totalAmountArr.push({
            sign: key,
            total: totalAmount.toFixed(2),
          });
        });
      }
      return totalAmountArr;
    },
    feesPaid() {
      let totalAmountArr = [];
      if (this.projectList.length > 0) {
        let groupBy = _.groupBy(this.projectList, "currency_sign");
        _.forEach(groupBy, (value, key) => {
          let totalAmount = 0;
          value.forEach((item) => {
            totalAmount += item.fees_paid;
          });

          totalAmountArr.push({
            sign: key,
            total: totalAmount.toFixed(2),
          });
        });
      }
      return totalAmountArr;
    },
    taxesPaid() {
      let totalAmountArr = [];
      if (this.projectList.length > 0) {
        let groupBy = _.groupBy(this.projectList, "currency_sign");
        _.forEach(groupBy, (value, key) => {
          let totalAmount = 0;
          value.forEach((item) => {
            totalAmount += item.taxes_paid;
          });

          totalAmountArr.push({
            sign: key,
            total: totalAmount.toFixed(2),
          });
        });
      }
      return totalAmountArr;
    },
    earning() {
      let totalAmountArr = [];
      if (this.projectList.length > 0) {
        let groupBy = _.groupBy(this.projectList, "currency_sign");
        _.forEach(groupBy, (value, key) => {
          let totalAmount = 0;
          value.forEach((item) => {
            totalAmount += item.earning;
          });

          totalAmountArr.push({
            sign: key,
            total: totalAmount.toFixed(2),
          });
        });
      }
      return totalAmountArr;
    },
    inrEarning() {
      let inrEarning = 0;
      this.projectList.forEach((element) => {
        inrEarning += element.inr_earning;
      });
      return inrEarning.toFixed(2);
    },
    filterSearchMonth() {
      return this.searchMonth.join(" ~ ");
    },
  },
};
</script>

<style>
.text-green {
  color: green;
  font-weight: bold;
}

.text-dark {
  color: #8b8b8b;
}

.text-red {
  color: red;
}

.text-center {
  text-align: center;
}

.project-list-heading {
  font-size: 16px;
  color: #010110;
}

.block {
  display: block;
}

.text-underline {
  text-decoration: underline;
}

.total-section {
  display: block;
}
</style>