
<template>
  <div class="mr-4 ml-4 mt-4">
    <v-data-table
      :headers="headers"
      :footer-props="{ 'items-per-page-options': [10, 20, 30, 40, 50, -1] }"
      :items-per-page="50"
      :items="transactions"
      class="elevation-1"
      :search="search"
      :loading="tableLoading"
    >
      <template v-slot:top>
        <v-toolbar color="grey lighten-3" flat>
          <v-toolbar-title>Transactions</v-toolbar-title>
          <v-divider class="mx-2" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            dark
            tile
            class="mb-2 mr-2"
            href="/transactions"
            v-if="trId"
            >All Transaction</v-btn
          >
          <v-dialog v-model="dialog" max-width="50%">
            <template v-slot:activator="{ on }" v-if="![1, 4].includes(user.role)">
              <v-btn
                color="green"
                dark
                tile
                class="mb-2"
                v-on="on"
                @click="addTransactionModal"
                >+ Add Transaction</v-btn
              >
            </template>
            
            <v-card>
              <v-card-title>
                <span class="headline">{{ formTitle }}</span>
              </v-card-title>

              <v-card-text>
                <v-form>
                  <v-container grid-list-md>
                    <v-row>
                      <v-col cols="12" md="6">
                        <p class="font-weight-bold">Debit from</p>
                        <v-autocomplete
                          dense
                          filled
                          v-model="fromAccountType"
                          :items="accountTypes"
                          label="Select Account Type"
                          @input="fetchTypedAccounts(fromAccountType, 'from')"
                          required
                        ></v-autocomplete>
                        <v-autocomplete
                          dense
                          filled
                          v-model="fromAccount"
                          :items="fromAccounts"
                          label="Select Account"
                          required
                        ></v-autocomplete>
                      </v-col>
                      <v-col cols="12" md="6">
                        <p class="font-weight-bold">Credit to</p>
                        <v-autocomplete
                          dense
                          filled
                          v-model="toAccountType"
                          :items="accountTypes"
                          label="Select Account Type"
                          :error-messages="toAccountTypeErrors"
                          @input="fetchTypedAccounts(toAccountType, 'to')"
                          @change="$v.toAccountType.$touch()"
                          @blur="$v.toAccountType.$touch()"
                          required
                        ></v-autocomplete>
                        <v-autocomplete
                          dense
                          filled
                          v-model="toAccount"
                          :items="toAccounts"
                          @change="prepareBankAccount"
                          label="Select Account"
                          required
                        ></v-autocomplete>
                      </v-col>
                    </v-row>

                    <!-- RADIO PURPOSE TYPE-->
                    <v-row>
                      <v-col cols="12" md="12" class="mt-n4 mb-n4">
                        <v-radio-group v-model="purposeType" :mandatory="false">
                          <template v-slot:label>
                            <div>
                              <strong>Transaction Purpose</strong>
                            </div>
                          </template>
                          <v-layout>
                            <v-flex>
                              <v-radio
                                label="Normal"
                                :value="0"
                                color="blue"
                              ></v-radio>
                            </v-flex>
                            <v-flex>
                              <v-radio
                                label="Income"
                                :value="3"
                                color="blue"
                              ></v-radio>
                            </v-flex>
                            <v-flex>
                              <v-radio
                                label="Salary"
                                :value="1"
                                color="blue"
                              ></v-radio>
                            </v-flex>
                            <v-flex>
                              <v-radio
                                label="Upad"
                                :value="2"
                                color="blue"
                              ></v-radio>
                            </v-flex>
                            <v-flex>
                              <v-radio
                                label="Self Credit"
                                :value="4"
                                color="blue"
                              ></v-radio>
                            </v-flex>
                          </v-layout>
                        </v-radio-group>
                      </v-col>
                    </v-row>

                    <!-- RADIO TRANSACTION TYPE-->
                    <v-row>
                      <v-col cols="12" md="12" class="mt-n4 mb-n4">
                        <v-radio-group
                          v-model="transactionType"
                          :mandatory="false"
                          @change="clearRadioInputs"
                        >
                          <template v-slot:label>
                            <div>
                              <strong>Transaction Type</strong>
                            </div>
                          </template>
                          <v-layout>
                            <v-flex>
                              <v-radio
                                label="Cash"
                                :value="0"
                                color="blue"
                              ></v-radio>
                            </v-flex>
                            <v-flex>
                              <v-radio
                                label="cheque"
                                :value="1"
                                color="blue"
                              ></v-radio>
                            </v-flex>
                            <v-flex>
                              <v-radio
                                label="Transfer"
                                :value="2"
                                color="blue"
                              ></v-radio>
                            </v-flex>
                            <v-flex>
                              <v-radio
                                label="Forex"
                                :value="3"
                                color="blue"
                              ></v-radio>
                            </v-flex>
                          </v-layout>
                        </v-radio-group>
                      </v-col>
                    </v-row>

                    <!-- chequeShow -->
                    <v-row v-if="transactionType == 1">
                      <v-col cols="12" md="4">
                        <v-text-field
                          v-model="chequeNo"
                          label="Cheque No"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="4">
                        <v-select
                          v-model="bankName"
                          :items="bankNameList"
                          label="Select Bank Account"
                        ></v-select>
                      </v-col>
                      <v-col cols="12" md="4">
                        <v-menu
                          v-model="chequeMenu"
                          :close-on-content-click="false"
                          :nudge-right="40"
                          transition="scale-transition"
                          offset-y
                          min-width="auto"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="depositedDate"
                              label="Deposited Date"
                              v-mask="'####-##-##'"
                              hint="YYYY-MM-DD format"
                              prepend-icon="mdi-calendar"
                              v-bind="attrs"
                              v-on="on"
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            v-model="depositedDate"
                            @input="chequeMenu = false"
                          ></v-date-picker>
                        </v-menu>
                      </v-col>
                    </v-row>

                    <!-- tfShow -->
                    <v-row v-else-if="transactionType == 2">
                      <v-col cols="12" md="6">
                        <v-text-field
                          v-model="transferNo"
                          label="Transfer No"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="6">
                        <v-select
                          v-model="bankName"
                          :items="bankNameList"
                          label="Select Bank Account"
                        ></v-select>
                      </v-col>
                    </v-row>

                    <!-- forexShow -->
                    <v-row v-else-if="transactionType == 3">
                      <v-col cols="12" md="6">
                        <v-text-field
                          v-model="transferNo"
                          label="Forex Transfer No"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="6">
                        <v-select
                          v-model="bankName"
                          :items="bankNameList"
                          label="Select Bank Account"
                        ></v-select>
                      </v-col>
                    </v-row>

                    <div class="d-flex align-items-center">
                      <v-switch
                        v-model="is_tds_applicable"
                        color="success"
                        @change="calculateTDSAmount"
                      ></v-switch>
                      <span class="ml-2 font-weight-bold mt-5"
                        >TDS Applicable</span
                      >
                    </div>

                    <v-row>
                      <v-col cols="12" md="4">
                        <v-text-field
                          label="Amount"
                          v-model.number="amount"
                          prefix="₹"
                          type="number"
                          min="0"
                          :error-messages="amountErrors"
                          @change="handleChangeAmount"
                          @blur="handleChangeAmount"
                        ></v-text-field>
                      </v-col>

                      <template v-if="is_tds_applicable">
                        <v-col cols="12" md="4" class="position-relative">
                          <v-tooltip v-model="show" top v-if="tds_percentage == 0 && editedIndex !== -1">
                            <template v-slot:activator="{ on }">
                              <div class="tds_applicabe_check" @click="addDefaultTDS" v-on="on">
                                <i class="v-icon notranslate mdi mdi mdi-information"></i>
                              </div>
                            </template>
                            <span>click here to apply new TDS</span>
                          </v-tooltip>
                          
                          <v-text-field
                            :label="`TDS (${tds_percentage}%)`"
                            v-model.number="tds_amount"
                            prefix="₹"
                            type="number"
                            min="0"
                            readonly
                          ></v-text-field>
                        </v-col>

                        <v-col cols="12" md="4">
                          <v-text-field
                            label="Payable amount"
                            v-model.number="payable_amount"
                            prefix="₹"
                            type="number"
                            min="0"
                            @change="handleChangePayableAmount"
                            @blur="handleChangePayableAmount"
                          ></v-text-field>
                        </v-col>
                      </template>
                    </v-row>

                    <v-row>
                      <v-col cols="12" md="4" v-if="purposeType == 1">
                        <v-text-field
                          label="Working Days"
                          v-model.number="workingDays"
                          prefix="₹"
                          type="number"
                          min="1"
                          max="31"
                        ></v-text-field>
                      </v-col>

                      <v-col cols="12" md="4" v-if="purposeType != 1">
                        <v-radio-group v-model="invoiceType" row>
                          <v-radio label="Attach" :value="1"></v-radio>
                          <v-radio label="Invoice-list" :value="2"></v-radio>
                        </v-radio-group>
                      </v-col>

                      <v-col cols="12" md="4">
                        <v-file-input
                          v-if="invoiceType == 1"
                          accept="image/png, image/jpeg, image/bmp, application/pdf"
                          v-model="invoiceImg"
                          label="Invoice Image"
                          counter
                          truncate-length="25"
                        ></v-file-input>

                        <v-autocomplete
                          v-if="invoiceType == 2"
                          dense
                          filled
                          v-model="invoiceId"
                          :items="invoiceList"
                          label="Select Invoice"
                          required
                        ></v-autocomplete>
                      </v-col>

                      <v-col cols="12" md="4">
                        <v-menu
                          v-model="menu"
                          :close-on-content-click="false"
                          :nudge-right="40"
                          transition="scale-transition"
                          offset-y
                          min-width="auto"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="date"
                              label="Date"
                              v-mask="'####-##-##'"
                              hint="YYYY-MM-DD format"
                              persistent-hint
                              prepend-icon="mdi-calendar"
                              v-bind="attrs"
                              @blur="date"
                              v-on="on"
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            v-model="date"
                            no-title
                            @input="menu = false"
                          ></v-date-picker>
                        </v-menu>
                      </v-col>
                    </v-row>

                    <!-- salaryDateShow -->
                    <v-row justify="center" v-if="purposeType == 1">
                      <v-col cols="12" md="4">
                        <v-menu
                          v-model="menuMonth"
                          :close-on-content-click="false"
                          :nudge-right="40"
                          transition="scale-transition"
                          offset-y
                          min-width="auto"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="salaryMonth"
                              type="month"
                              label="Salary Month"
                              persistent-hint
                              prepend-icon="mdi-calendar"
                              v-bind="attrs"
                              @blur="salaryMonth"
                              v-on="on"
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            v-model="salaryMonth"
                            type="month"
                            no-title
                            @input="menuMonth = false"
                          ></v-date-picker>
                        </v-menu>
                      </v-col>
                      <v-col cols="12" md="4">
                        <v-text-field
                          label="Bonus"
                          v-model.number="bonus"
                          prefix="₹"
                          type="number"
                          min="0"
                        ></v-text-field>
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col cols="12" md="12">
                        <v-textarea
                          v-model="note"
                          rows="3"
                          outlined
                          name="note"
                          label="note"
                          clearable
                        ></v-textarea>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-form>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="close">Cancel</v-btn>
                <v-btn color="blue darken-1" text @click="save">Save</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
              <v-card-title class="headline"
                >Are you sure you want to delete this item?</v-card-title
              >
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="closeDelete"
                  >Cancel</v-btn
                >
                <v-btn color="blue darken-1" text @click="deleteItemConfirm"
                  >OK</v-btn
                >
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
        <v-row v-if="!trId" align="center" class="pa-4 mt-0 pt-0 ">
          <v-col cols="12" md="2">
            <v-autocomplete
              :items="masterAccounts"
              v-model="fromFilterType"
              label="From Account"
            >
            </v-autocomplete>
          </v-col>
          <v-col cols="12" md="2">
            <v-autocomplete
              :items="masterAccounts"
              v-model="toFilterType"
              label="To Account"
            >
            </v-autocomplete>
          </v-col>
          <v-col cols="12" md="2">
            <v-autocomplete
              :items="getPurposeTypeFilter"
              v-model="purposeFilterType"
              label="Purpose Type"
            >
            </v-autocomplete>
          </v-col>
          <v-col cols="12" md="2">
            <v-dialog
              ref="dialog"
              v-model="filterModal"
              :return-value.sync="dates"
              persistent
              width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="dateRangeText"
                  label="Select date range"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker v-model="dates" range scrollable>
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="filterModal = false">
                  Cancel
                </v-btn>
                <v-btn text color="primary" @click="$refs.dialog.save(dates)">
                  OK
                </v-btn>
              </v-date-picker>
            </v-dialog>
          </v-col>
          <v-col cols="12" md="2">
          <!-- add Switch btn -->
          <div class="d-flex align-items-center">
            <v-switch
              v-model="is_tds_applicable_filter"
              color="success"
            ></v-switch>
            <span class="ml-2 font-weight-bold mt-5">TDS Applicable</span>
          </div>
          </v-col>
          <v-col cols="12" md="2">
            <v-btn
              class="ma-2 white--text"
              color="blue-grey"
              small
              tile
              depressed
              @click="initialize"
              :loading="tableLoading"
            >
              Search
            </v-btn>
            <v-btn
              class="ma-2"
              color="blue-grey"
              dark
              small
              tile
              depressed
              outlined
              @click="resetFilterValue"
            >
              Reset
            </v-btn>
          </v-col>
        </v-row>
        <v-row v-if="!trId" class="mt-0 pt-0 mb-3">
          <v-col cols="12" md="3" class="mt-0 pt-0">
            <v-text-field
              v-model="from_amount"
              label="From Amount"
              prefix="₹"
              class="mx-4 mt-0 pt-0"
            ></v-text-field>
          </v-col>

          <v-col cols="12" md="3" class="mt-0 pt-0">
            <v-text-field
              v-model="to_amount"
              prefix="₹"
              label="To Amount"
             class="mx-4 mt-0 pt-0"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-text-field
          v-if="!trId"
          v-model="search"
          label="Search"
          class="mx-4 mt-n8"
        ></v-text-field>
      </template>
      <template v-slot:[`item.invoiceImg`]="{ item }">
        <span v-if="item.invoiceType == 1">
          <a
            :href="item.invoiceImg"
            v-if="item.invoiceImg"
            target="_blank"
            class="invoice-link"
          >
            <i class="v-icon notranslate mdi mdi-link-variant"></i>
            {{ item.invoiceOriginalName || "Invoice" }}
          </a>
          <i
            v-if="item.invoiceImg"
            class="mdi mdi-delete ml-4 cursor-pointer"
            @click="removeInvoice(item)"
          ></i>
        </span>

        <v-chip
          label
          v-if="item.invoiceType == 2"
          @click="goToInvoice(item.invoiceId)"
          class="blue blue--text"
          outlined
          small
        >
          Invoice No. <b class="ml-1">{{ item.invoiceNo }}</b>
          <v-icon class="ml-2" small blue> mdi-arrow-right </v-icon>
        </v-chip>
      </template>
      <template v-slot:[`item.type`]="{ item }">
        {{ getTransactionType(item.type) }}
      </template>
      <template v-slot:[`item.purposeType`]="{ item }">
        {{ getPurposeType(item.purposeType) }}
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <template v-if="![1, 4].includes(user.role)">
          <v-icon small class="mr-2" @click="editItem(item)"> mdi-pencil </v-icon>
          <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon>
        </template>
      </template>
      <template v-slot:[`item.date`]="{ item }">
        <span class="font-weight-medium"> {{ item.date }} </span>
      </template>
      <template v-slot:[`item.amount`]="{ item }">
        <v-chip>{{ item.amount }}</v-chip>
      </template>
      <template v-slot:no-data>
        <p>No data available.</p>
      </template>
      <template v-slot:body.append>
        <tr class="grey lighten-5">
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td>
            <v-chip color="green" text-color="white">{{
              totalTransactions
            }}</v-chip>
          </td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
      </template>
    </v-data-table>
    <div class="text-center pt-2">
      <v-row justify="end" align="center" class="pa-4">
        <v-btn
          class="auto ml-4"
          depressed
          tile
          color="info"
          v-if="transactions.length > 0"
          :loading="loadingExport"
          @click="exportTransactions"
          >Export</v-btn
        >
      </v-row>
    </div>
    <v-dialog v-model="confirmDialog" max-width="290">
      <v-card>
        <v-card-title class="text-h5"> Are you sure ? </v-card-title>

        <v-card-text> Duplicate transactions </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="green darken-1" text @click="confirmDialog = false">
            Cancel
          </v-btn>

          <v-btn
            color="green darken-1"
            :loading="loadingSaveTransactions"
            text
            @click="confirmTransaction"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-overlay style="z-index: 9999" :value="overlay">
      <v-progress-circular indeterminate size="32"></v-progress-circular>
    </v-overlay>
  </div>
</template>
<script>
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
export default {
  props: ["trId"],
  mixins: [validationMixin],
  validations: {
    account: { required },
    toAccountType: { required },
    transactionType: { required },
    amount: { required },
  },
  beforeCreate() {
    if (!this.$permission.userPermissions(["view-transaction-page"]))
      this.$router.push({ name: "dashboard" });
  },
  async beforeMount() {
    await this.fetchAccountTypes();

    await this.fetchCompanyDetails();

    if (this.trId) {
      this.transactions = this.transactions.filter((item) => {
        if (item.id === parseInt(this.trId)) {
          return item;
        }
      });
    }
  },
  data: () => ({
    user: null,
    overlay: false,
    loadingSaveTransactions: false,
    loadingExport: false,
    checkDate: 1,
    salaryMonth: "",
    menuMonth: false,
    bankNameList: [],
    search: "",
    dialog: false,
    confirmDialog: false,
    headers: [
      { text: "Date", value: "date" },
      { text: "User", value: "user_name" },
      {
        text: "From Account",
        align: "start",
        value: "fromAccountName",
      },
      {
        text: "To Account",
        value: "toAccountName",
      },
      { text: "Type", value: "type" },
      { text: "Purpose", value: "purposeType" },
      { text: "Amount", value: "amount" },
      { text: "Notes", value: "notes" },
      { text: "Invoice", value: "invoiceImg" },
      { text: "Actions", value: "actions", sortable: false },
    ],
    desserts: [],
    editedIndex: -1,
    editedItem: {
      name: "",
      calories: 0,
      fat: 0,
      carbs: 0,
      protein: 0,
    },
    defaultItem: {
      name: "",
      calories: 0,
      fat: 0,
      carbs: 0,
      protein: 0,
    },
    bankDetails: [],
    accountTypes: [],
    toAccountType: null,
    fromAccountType: null,
    toAccounts: [],
    toAccount: null,
    fromAccounts: [],
    fromAccount: null,
    from_amount: null,
    to_amount: null,
    account: null,
    chequeNo: "",
    bankName: null,
    chequeMenu: false,
    depositedDate: "",
    transferNo: "",
    amount: null,
    bonus: null,
    invoiceType: 1,
    invoiceId: 0,
    invoiceList: [],
    invoiceImg: null,
    note: "",
    transactions: [],
    workingDays: 0,
    chequeShow: false,
    tfShow: false,
    forexShow: false,
    purposeType: 0,
    transactionType: 0,
    is_tds_applicable: false,
    is_tds_applicable_filter: false,
    tds_amount: 0,
    payable_amount: 0,
    tds_percentage: 0,
    tds_original_percentage: 0,
    date: new Date().toISOString().substr(0, 10),
    menu: false,
    modal: false,
    menu2: false,
    dialogDelete: false,
    deleteAccount: null,
    dates: [],
    filterModal: false,
    confirmFlag: 1,
    tableLoading: false,
    masterAccounts: [],
    fromFilterType: 0,
    toFilterType: 0,
    purposeFilterType: "",
  }),

  computed: {
    getPurposeTypeFilter() {
      let data = [
        {
          text: "Normal",
          value: 0,
        },
        {
          text: "Salary",
          value: 1,
        },
        {
          text: "Upad",
          value: 2,
        },
        {
          text: "Income",
          value: 3,
        },
        {
          text: "Self Credit",
          value: 4,
        },
      ];

      return data;
    },
    getMasterAccounts() {
      let data = this.masterAccounts;
      data.unshift({ value: 0, text: "All" });
      return data;
    },
    toAccountTypeErrors() {
      const errors = [];
      if (!this.$v.toAccountType.$dirty) return errors;
      !this.$v.toAccountType.required && errors.push("Account is required");
      return errors;
    },
    transactionErrors() {
      const errors = [];
      if (!this.$v.transactionType.$dirty) return errors;
      !this.$v.transactionType.required &&
        errors.push("Transaction Type is required");
      return errors;
    },
    amountErrors() {
      const errors = [];
      if (!this.$v.amount.$dirty) return errors;
      !this.$v.amount.required && errors.push("Amount must be greater than 0");
      return errors;
    },
    formTitle() {
      return this.editedIndex === -1 ? "Add Transaction" : "Edit Transaction";
    },
    dateRangeText() {
      return this.dates.join(" ~ ");
    },
    disableSearch() {
      return this.dates.length == 0;
    },
    totalTransactions() {
      let amount = 0;
      this.transactions.forEach((value) => {
        amount += value.amount;
      });

      return amount;
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
    invoiceImg(val) {
      console.log(val);
    },
  },

  async created() {
    let keyPressed = false;
    document.addEventListener("keydown", (e) => {
      if (e.code == "AltLeft") {
        keyPressed = true;
      }
    });
    document.addEventListener("keyup", (e) => {
      if (keyPressed && e.code == "KeyT") {
        this.dialog = !this.dialog;
      }
      keyPressed = false;
    });

    if (this.trId) {
      this.checkDate = 0;
    } else {
      this.checkDate = 1;
    }

    const user = JSON.parse(window.atob(localStorage.getItem("user")));
    this.user = user;
    await this.initialize();
    this.fetchMasterAccounts();
  },

  methods: {
    goToInvoice(id) {
      let routeData = this.$router.resolve({
        name: "Invoice",
        params: { ivId: id },
      });
      if (routeData) {
        window.open(routeData.href, "_blank");
      }
    },
    resetFilterValue() {
      this.fromFilterType = 0;
      this.toFilterType = 0;
      this.dates = [];
      this.purposeFilterType = "";
      this.from_amount = null;
      this.to_amount = null;
      this.is_tds_applicable_filter = false;
      this.initialize();
    },
    fetchMasterAccounts() {
      this.$http
        .get("masteraccounts", {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("access_token"),
          },
        })
        .then((response) => {
          const masterAccounts = response.data.data.masterAccounts;
          this.masterAccounts = [];
          masterAccounts.forEach((element) => {
            if (element.account) {
              this.masterAccounts.push({
                value: element.account.id,
                text: `${element.name} ${element.middle_name ||
                  ""} ${element.last_name || ""}`,
              });
            }
          });
        })
        .catch((error) => {
          console.log("error", error);
          this.$toast.error("Something went wrong");
        });
    },
    exportTransactions() {
      this.loadingExport = true;
      this.$http
        .get("transactions-export", {
          params: {
            0: this.dates,
            checkDate: this.checkDate,
            tr_id: this.trId || 0,
            fromId: this.fromFilterType,
            toId: this.toFilterType,
            purpose: this.purposeFilterType,
          },
          headers: {
            Authorization: "Bearer " + localStorage.getItem("access_token"),
          },
          responseType: "blob",
        })
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          const fileName = this.dates.join("_to_") + "_transactions.xlsx";
          link.setAttribute("download", fileName.replace(/-/g, "_"));
          document.body.appendChild(link);
          link.click();
          this.loadingExport = false;
        })
        .catch((error) => {
          console.log("error", error.response);
          this.$toast.error("Something went wrong");
          this.loadingExport = false;
        });
    },
    prepareBankAccount() {
      this.bankName = null;
      this.bankNameList = [];
      if (this.bankDetails.length > 0) {
        let bankNameArr = this.bankDetails.find(
          (item) => item.id === this.toAccount
        );

        if (bankNameArr) {
          this.bankNameList = bankNameArr.bankName.map((el) => {
            if (!this.bankName) {
              this.bankName = el.is_primary === 1 ? el.id : null;
            }
            return {
              text: `${el.bank_name} (${el.bank_account_number.substring(
                el.bank_account_number.length - 4
              )})`,
              value: el.id,
            };
          });
        }
      }
    },
    async initialize() {
      this.tableLoading = true;
      this.trId = this.trId || 0;

      if (!this.trId) {
        this.checkDate = 1;
      } else {
        this.checkDate = 0;
      }

      await this.$http
        .get("transactions", {
          params: {
            0: this.dates,
            checkDate: this.checkDate,
            tr_id: this.trId || 0,
            fromId: this.fromFilterType,
            toId: this.toFilterType,
            purpose: this.purposeFilterType,
            is_tds_applicable_filter: this.is_tds_applicable_filter,
            from_amount: this.from_amount,
            to_amount: this.to_amount,
          },
          headers: {
            Authorization: "Bearer " + localStorage.getItem("access_token"),
          },
        })
        .then((response) => {
          const transactions = response.data.data.transactions;
          const invoices = response.data.data.invoices;
          this.transactions = [];
          this.invoiceList = [];
          transactions.forEach((element) => {
            this.transactions.push({
              id: element.id,
              user_name: element.user ? element.user.name : "(User Deleted)",
              toAccountName: this.getItemName(
                element.to_account.master_account
              ),
              toAccount: element.to_account_id,
              toAccountTypeId: element.to_account.account_type_id,
              fromAccountName: this.getItemName(
                element.from_account.master_account
              ),
              fromAccount: element.from_account_id,
              fromAccountTypeId: element.from_account.account_type_id,
              type: element.transaction_type,
              amount: element.amount,
              notes: element.notes,
              bankName: element.bank_name || null,
              chequeNo: element.cheque_no || "",
              transferNo: element.transfer_no || "",
              purposeType: element.purpose_type,
              depositedDate: element.cheque_deposited_date || "",
              salaryMonth: element.salary_month || "",
              bonus: element.bonus,
              date: element.date,
              invoiceImg: element.full_invoice_path,
              invoiceOriginalName: element.invoice_original_name,
              invoiceType: element.invoice_type,
              invoiceId: element.invoice_id,
              invoiceNo: element.invoice ? element.invoice.invoice_no : null,
              workingDays: element.working_days,
              is_tds_applicable: element.is_tds_applicable,
              tds_amount: element.tds_amount,
              tds_percentage: element.tds_percentage,
              payable_amount: element.payable_amount,
            });
          });
          console.log(this.transactions);

          invoices.forEach((element) => {
            this.invoiceList.push({
              value: element.id,
              text:
                element.invoice_no +
                " - " +
                this.getItemName(element.party_account.master_account),
            });
          });
          console.log(this.invoiceList);

          this.tableLoading = false;
        })
        .catch((error) => {
          console.log("error", error.response);
          this.tableLoading = false;
        });

    },

    async fetchAccountTypes() {
      await this.$http
        .get("account/types", {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("access_token"),
          },
        })
        .then((response) => {
          this.accountTypes = [];
          const accountType = response.data.data.accountType;
          accountType.forEach((element) => {
            this.accountTypes.push({ value: element.id, text: element.name });
          });
        })
        .catch((error) => {
          console.log("error", error.response);
        });
    },

    async fetchTypedAccounts(type, flag) {
      const data = {
        accountTypeId: type,
      };
      await this.$http
        .post("typed/accounts", data, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("access_token"),
          },
        })
        .then((response) => {
          const accountType = response.data.data.accounts;
          if (flag == "to") {
            this.toAccounts = [];
            this.bankDetails = [];
            accountType.forEach((element) => {
              if (element.master_account.bank_account.length > 0) {
                this.bankDetails.push({
                  id: element.id,
                  bankName: element.master_account.bank_account,
                });
              }
              this.toAccounts.push({
                value: element.id,
                text: this.getItemName(element.master_account),
              });
            });
          } else {
            this.fromAccounts = [];
            accountType.forEach((element) => {
              this.fromAccounts.push({
                value: element.id,
                text: this.getItemName(element.master_account),
              });
            });
          }
        })
        .catch((error) => {
          console.log("error", error.response);
        });
    },

    getItemName(item) {
      if (item.account_type_id == 1 && item.acc_number != null)
        return (
          item.name +
          " " +
          (item.last_name || "") +
          " (" +
          item.acc_number.substring(item.acc_number.length - 4) +
          ")"
        );
      return item.name + " " + (item.last_name || "");
    },

    async editItem(item) {
      this.overlay = true;
      this.editedIndex = item.id;
      this.fromAccountType = this.accountTypes.find(
        (type) => type.value === item.fromAccountTypeId
      );
      await this.fetchTypedAccounts(this.fromAccountType.value, "from");

      this.toAccountType = this.accountTypes.find(
        (type) => type.value === item.toAccountTypeId
      );

      await this.fetchTypedAccounts(this.toAccountType.value, "to");
      this.amount = item.amount;
      this.bonus = item.bonus;
      this.note = item.notes;
      this.purposeType = item.purposeType;
      this.transactionType = item.type;
      this.date = item.date;
      this.toAccount = item.toAccount;
      this.fromAccount = item.fromAccount;
      this.invoiceType = item.invoiceType;
      this.invoiceId = item.invoiceId;
      if (item.chequeNo != "") this.chequeNo = item.chequeNo;
      if (item.depositedDate != "") this.depositedDate = item.depositedDate;
      if (item.salaryMonth != "") this.salaryMonth = item.salaryMonth;
      if (item.transferNo != "") this.transferNo = item.transferNo;
      this.prepareBankAccount();
      if (item.bankName != null) this.bankName = item.bankName;
      this.editedItem = Object.assign({}, item);
      this.overlay = false;
      this.dialog = true;
      this.workingDays = item.workingDays;

      this.is_tds_applicable = item.is_tds_applicable;
      this.tds_amount = item.tds_amount;
      this.payable_amount = item.amount;
      this.amount = this.payable_amount + this.tds_amount;
      this.tds_percentage = item.tds_percentage;
    },
    deleteItem(item) {
      this.editedIndex = item.id;
      this.deleteAccount = item;
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },
    close() {
      this.dialog = false;
      this.clear();
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    closeDelete() {
      this.dialogDelete = false;
      this.deleteAccount = null;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    deleteItemConfirm() {
      this.deleteTransaction(this.deleteAccount);
      //   this.masterAccounts.splice(this.editedIndex, 1);
      this.closeDelete();
    },
    confirmTransaction() {
      this.loadingSaveTransactions = true;
      this.confirmFlag = 0;
      this.save();
    },
    save() {
      var data = new FormData();
      data.append("from_account_id", this.fromAccount);
      data.append("to_account_id", this.toAccount);
      data.append("purpose_type", this.purposeType);
      data.append("transaction_type", this.transactionType);
      data.append("amount", this.amount);
      data.append("bonus", this.bonus);
      data.append("flag", this.confirmFlag);
      data.append("invoice_type", this.invoiceType);
      data.append("invoice_id", this.invoiceId);
      data.append("working_days", this.workingDays);
      data.append("is_tds_applicable", this.is_tds_applicable);
      data.append("tds_amount", this.tds_amount);
      data.append("payable_amount", this.payable_amount);
      data.append("tds_percentage", this.tds_percentage);

      if (this.invoiceImg != null) data.append("invoice_img", this.invoiceImg);
      data.append("date", this.date);
      data.append("notes", this.note);
      if (this.chequeNo != "") data.append("cheque_no", this.chequeNo);
      if (this.salaryMonth != "") data.append("salary_month", this.salaryMonth);
      if (this.transactionType != 0 && this.bankName != null)
        data.append("bank_name", this.bankName);
      if (this.depositedDate != "")
        data.append("cheque_deposited_date", this.depositedDate);
      if (this.transferNo != "") data.append("transfer_no", this.transferNo);
      if (this.editedIndex > -1) {
        this.updateTransaction(data);
      } else {
        this.saveTransaction(data);
      }
    },
    saveTransaction(data) {
      if (! this.is_tds_applicable) {
        data.append('payable_amount', this.amount);
      }

      this.$http
        .post("transactions", data, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("access_token"),
          },
        })
        .then((response) => {
          if (response.data.data.duplicate) {
            this.confirmDialog = true;
          }
          if (response.data.status === 200 && !response.data.data.duplicate) {
            this.close();
            this.initialize();
            this.confirmFlag = 1;
            this.confirmDialog = false;
            this.loadingSaveTransactions = false;
            this.$toast.success(response.data.data.message[0]);
          }
        })
        .catch((error) => {
          this.$toast.error("Something went wrong");
          console.log("error", error.response);
          this.loadingSaveTransactions = false;
        });
    },
    updateTransaction(data) {
      if (! this.is_tds_applicable) {
        data.append('payable_amount', this.amount);
      }

      data.append("_method", "PUT");
      this.$http
        .post("transactions/" + this.editedIndex, data, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("access_token"),
          },
        })
        .then((response) => {
          if (response.data.status === 200) {
            this.close();
            this.clear();
            this.initialize();
            this.$toast.success(response.data.data.message[0]);
          }
          this.loadingSaveTransactions = false;
        })
        .catch((error) => {
          this.$toast.error("Something went wrong");
          console.log(error);
          this.loadingSaveTransactions = false;
        });
    },
    clearRadioInputs() {
      this.chequeNo = "";
      this.depositedDate = "";
      this.transferNo = "";
    },
    deleteTransaction(item) {
      this.$http
        .delete("transactions/" + item.id, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("access_token"),
          },
        })
        .then((response) => {
          this.initialize();
          this.$toast.success(response.data.data.message[0]);
        })
        .catch((error) => {
          this.$toast.error("Something went wrong");
          console.log(error);
        });
    },
    getTransactionType(id) {
      if (id === 0) return "Cash";
      if (id === 1) return "Cheque";
      if (id === 2) return "Transfer";
      if (id === 3) return "Forex";
    },
    getPurposeType(id) {
      if (id === 0) return "Normal";
      if (id === 1) return "Salary";
      if (id === 2) return "Upad";
      if (id === 3) return "Income";
      if (id === 4) return "Self Credit";
    },
    clear() {
      this.toAccountType = null;
      this.fromAccountType = null;
      this.toAccounts = [];
      this.toAccount = null;
      this.fromAccounts = [];
      this.fromAccount = null;
      this.account = null;
      this.chequeNo = "";
      this.bankName = null;
      this.chequeMenu = false;
      this.depositedDate = "";
      this.salaryMonth = "";
      this.transferNo = "";
      this.amount = null;
      this.bonus = null;
      this.invoiceImg = null;
      this.is_tds_applicable = false;
      this.workingDays = 0;
      this.note = "";
      this.purposeType = 0;
      this.transactionType = 0;
      this.bankNameList = [];
      (this.tds_amount = 0), (this.payable_amount = 0), this.$v.$reset();
    },
    removeInvoice(item) {
      this.$http
        .post("transaction/remove-invoice", item, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("access_token"),
          },
        })
        .then((response) => {
          if (response.data.status === 200) {
            this.initialize();
            this.$toast.success(response.data.data.message[0]);
          }
        })
        .catch((error) => {
          console.log(error);
          this.$toast.error("Something went wrong");
        });
    },

    fetchCompanyDetails() {
      this.$http
        .get("company-details", {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("access_token"),
          },
        })
        .then((response) => {
          const companyDetails = response.data.data.companies;
          this.tds_percentage = companyDetails.tds_percentage
            ? companyDetails.tds_percentage.value
            : 0;

          this.tds_original_percentage = this.tds_percentage;
        })
        .catch((error) => {
          this.$toast.error("Something went wrong");
        });
    },

    handleChangeAmount() {
      this.$v.amount.$touch();
      this.calculateTDSAmount();
    },

    handleChangePayableAmount() {
      this.amount = this.payable_amount / (1 - (this.tds_percentage / 100));
      this.tds_amount = (Number(this.amount) * this.tds_percentage) / 100;
    },

    calculateTDSAmount() {
      if (this.is_tds_applicable) {
        this.tds_amount = (Number(this.amount) * this.tds_percentage) / 100;
        this.payable_amount = this.amount - this.tds_amount;
      } else {
        this.tds_amount = 0;
      }
    },

    addTransactionModal() {
      this.tds_percentage = this.tds_original_percentage;
    },

    addDefaultTDS() {
      this.tds_percentage = this.tds_original_percentage;
      this.calculateTDSAmount();
    },
  },
};
</script>

<style>
.invoice-link {
  text-decoration: none;
  white-space: nowrap;
  overflow: hidden;
}

 .tds_applicabe_check {
    position: absolute;
    left: 66px;
  }

  .position-relative {
    position: relative;
  }
</style>
