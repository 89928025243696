<template>
  <v-navigation-drawer permanent>
    <v-list-item>
      <v-list-item-content>
        <v-list-item-title class="title">
          {{ company_name }}
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>

    <v-divider></v-divider>

    <v-list dense nav flat>
      <div v-for="item in items" :key="item.title">
        <v-list-item
          v-if="item.group == null"
          link
          :to="item.link"
          active-class="blue--text"
        >
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-group v-else :prepend-icon="item.icon">
          <template v-slot:activator>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </template>

          <div v-for="(i, t) in item.group" :key="t">
            <v-list-item
              :to="i.link"
              v-if="i.forAll"
              link
              active-class="blue--text"
              class="pl-16"
            >
              <v-list-item-content>
                <v-list-item-title>{{ i.title }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </div>
        </v-list-group>
      </div>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
export default {
  created() {
    this.getCompanyLogo();
  },

  data() {
    let myMenu = [];
    let menuList = [
      {
        title: "Dashboard",
        icon: "mdi-view-dashboard-outline",
        link: "/home",
        forAll: this.$permission.userPermissions(["view-dashboard-page"]),
        group: null,
      },
      {
        title: "Branch",
        icon: "mdi-source-branch",
        link: "/branches",
        forAll: this.$permission.userPermissions(["view-branch-page"]),
        group: null,
      },
      {
        title: "User",
        icon: "mdi-account-supervisor",
        link: "/user",
        forAll: this.$permission.userPermissions(["view-user-page"]),
        group: null,
      },
      {
        title: "Master Accounts",
        icon: "mdi-view-dashboard",
        link: "/accounts",
        forAll: this.$permission.userPermissions([
          "add-account",
          "view-account",
          "edit-account",
          "delete-account",
          "view-master-account-page",
        ]),
        group: null,
      },
      {
        title: "Asssign Account",
        icon: "mdi-pencil",
        link: "/assign-account",
        forAll: this.$permission.userPermissions(["view-assign-account-page"]),
        group: null,
      },
      {
        title: "Link Account",
        icon: "mdi-link-variant",
        link: "/link-account",
        forAll: this.$permission.userPermissions(["view-link-page"]),
        group: null,
      },
      {
        title: "Transactions",
        icon: "mdi-bank-transfer",
        link: "/transactions",
        forAll: this.$permission.userPermissions(["view-transaction-page"]),
        group: null,
      },
      {
        title: "Expenses",
        icon: "mdi-currency-usd",
        link: "/expense",
        forAll: this.$permission.userPermissions(["view-expenses-page"]),
        group: null,
      },
      {
        title: "Rojmel",
        icon: "mdi-cash-multiple",
        link: "/rojmel",
        forAll: this.$permission.userPermissions(["view-rojmel-page"]),
        group: null,
      },
      {
        title: "Profit",
        icon: "mdi-cash-multiple",
        link: "/profit",
        forAll: this.$permission.userPermissions(["view-rojmel-page"]),
        group: null,
      },
      {
        title: "Reports",
        icon: "mdi-chart-bar",
        link: null,
        forAll: this.$permission.userPermissions(["view-dashboard-page", "view-project-earning-page"]),
        group: [
          // {
          //   title: "Analytics Report",
          //   icon: "mdi-chart-bar",
          //   link: "/analytics",
          //   forAll: this.$permission.userPermissions(["view-dashboard-page"]),
          // },
          {
            title: "Income Export Report",
            icon: "mdi-chart-bar",
            link: "/income-export",
            forAll: this.$permission.userPermissions(["view-dashboard-page"]),
          },
          {
            title: "Earning Report",
            icon: "mdi-chart-bar",
            link: "/earning-report",
            forAll: this.$permission.userPermissions([
              "view-project-earning-page",
            ]),
          },
          {
            title: "Salary Report",
            icon: "mdi-chart-bar",
            link: "/salary-report",
            forAll: this.$permission.userPermissions([
              "view-project-earning-page",
            ]),
          },
        ],
      },
      {
        title: "Salary Slip",
        icon: "mdi-cash-multiple",
        link: "/salary-slip",
        forAll: this.$permission.userPermissions(["view-salary-slip-page"]),
        group: null,
      },
      {
        title: "Bank Statement",
        icon: "mdi-file-account",
        link: "/bank-statement",
        forAll: this.$permission.userPermissions(["view-salary-slip-page"]),
        group: null,
      },
      {
        title: "Invoice",
        icon: "mdi-receipt",
        link: "/invoices",
        forAll: this.$permission.userPermissions(["view-invoice"]),
        group: null,
      },
    ];

    menuList.forEach((menu) => {
      if (menu.forAll) {
        myMenu.push({
          title: menu.title,
          icon: menu.icon,
          link: menu.link,
          group: menu.group,
        });
      }
    });

    return {
      items: myMenu,
      right: null,
      logoUrl: null,
      company_name: null
    };
  },

  methods: {
    getCompanyLogo() {
      this.$http
        .get("company-details", {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("access_token"),
          }
        })
        .then((response) => {
         const companies = response.data.data.companies
          this.company_name = companies.name.value
        })
        .catch(({ response }) => {
          this.$toast.error(response.data.error.message.join(", "));
        });
    },
  }
};
</script>
