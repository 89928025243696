var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mr-4 ml-4 mt-4"},[_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('v-data-table',{staticClass:"elevation-1 mb-4",attrs:{"headers":_vm.earningHeaders,"loading":_vm.tableLoading,"items":_vm.earningItems,"hide-default-footer":""},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"color":"grey lighten-3","flat":""}},[_c('v-toolbar-title',[_vm._v("Earning")]),_c('v-divider',{staticClass:"mx-2",attrs:{"inset":"","vertical":""}}),_c('v-spacer')],1)]},proxy:true},{key:"item.head",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"font-weight-medium"},[_vm._v(_vm._s(item.head))])]}},{key:"item.rate",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"dark":"","color":"green"}},[_vm._v(" "+_vm._s(item.rate)+" ")])]}},{key:"item.type",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getType(item.type))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.editItemDialog(item)}}},[_vm._v(" mdi-pencil ")])]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-data-table',{staticClass:"elevation-1 mb-4",attrs:{"headers":_vm.earningHeaders,"loading":_vm.tableLoading,"items":_vm.deductionItems,"hide-default-footer":""},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"color":"grey lighten-3","flat":""}},[_c('v-toolbar-title',[_vm._v("Deduction")]),_c('v-divider',{staticClass:"mx-2",attrs:{"inset":"","vertical":""}}),_c('v-spacer')],1)]},proxy:true},{key:"item.head",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"font-weight-medium"},[_vm._v(_vm._s(item.head))])]}},{key:"item.rate",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"dark":"","color":"red"}},[_vm._v(" "+_vm._s(item.rate)+" ")])]}},{key:"item.type",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getType(item.type))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.editItemDialog(item)}}},[_vm._v(" mdi-pencil ")])]}}],null,true)})],1)],1),_c('v-dialog',{attrs:{"width":"500"},model:{value:(_vm.editDialog),callback:function ($$v) {_vm.editDialog=$$v},expression:"editDialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"grey lighten-2"},[_vm._v(" "+_vm._s(_vm.editedHeading)+" ")]),_c('v-card-text',[_c('v-switch',{attrs:{"label":("Type : " + (_vm.typeSwitch ? 'Fixed' : 'Percantage'))},model:{value:(_vm.typeSwitch),callback:function ($$v) {_vm.typeSwitch=$$v},expression:"typeSwitch"}}),_c('v-text-field',{attrs:{"label":"Rate","filled":"","dense":""},model:{value:(_vm.editedRate),callback:function ($$v) {_vm.editedRate=$$v},expression:"editedRate"}})],1),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function($event){_vm.editDialog = false}}},[_vm._v(" Close ")]),_c('v-btn',{attrs:{"color":"success","depressed":"","tile":"","loading":_vm.saveSetting},on:{"click":_vm.editItem}},[_vm._v(" Save ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }