<template>
  <div class="mr-4 ml-4 mt-4">
    <v-row>
      <v-col cols="6">
        <v-row>
          <v-col> Date ({{ requestedStartDate }}) </v-col>
          <v-col align="end">
            <v-btn
              @click="serchbyDateDialog = true"
              small
              depressed
              tile
              color="primary"
              >Setting</v-btn
            >
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="12" sm="4">
            <v-card class="pa-2" outlined tile>
              <p>Credits</p>
              <v-chip label color="green" dark text-color="white">{{
                totalToTransaction
              }}</v-chip>
            </v-card>
          </v-col>
          <v-col cols="12" sm="4">
            <v-card class="pa-2" outlined tile>
              <p>Debits</p>
              <v-chip label color="red" dark text-color="white">{{
                totalFromTransaction
              }}</v-chip>
            </v-card>
          </v-col>
          <v-col cols="12" sm="4">
            <v-card class="pa-2" outlined tile>
              <p>Expenses</p>
              <v-chip label color="red" dark text-color="white">{{
                totalExpense
              }}</v-chip>
            </v-card>
          </v-col>
        </v-row>
        <v-row>
          <v-col> Transaction Between ({{ prevTransactionDate }}) </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="12" sm="4">
            <v-card class="pa-2" outlined tile>
              <p>Month Opening Balance</p>
              <v-chip label color="gray" dark text-color="white">{{
                monthOpeningBalance
              }}</v-chip>
            </v-card>
          </v-col>
          <v-col cols="12" sm="4">
            <v-card class="pa-2" outlined tile>
              <p>Total Credits</p>
              <v-chip label color="green" dark text-color="white">{{
                prevToTransactionAmount
              }}</v-chip>
            </v-card>
          </v-col>
          <v-col cols="12" sm="4">
            <v-card class="pa-2" outlined tile>
              <p>Total Debits</p>
              <v-chip label color="red" dark text-color="white">{{
                prevFromTransactionAmount
              }}</v-chip>
            </v-card>
          </v-col>
          <v-col cols="12" sm="4">
            <v-card class="pa-2" outlined tile>
              <p>Total Expenses</p>
              <v-chip label color="red" dark text-color="white">{{
                prevExpensesAmount
              }}</v-chip>
            </v-card>
          </v-col>
          <v-col cols="12" sm="4">
            <v-card class="pa-2" outlined tile>
              <p>Closing Balance</p>
              <v-chip label color="orange" dark text-color="white">{{
                carryForward
              }}</v-chip>
            </v-card>
          </v-col>
        </v-row>
      </v-col>

      <v-col cols="6">
        <v-data-table
          style="height: 100%"
          :headers="bankHeaders"
          :items="bankStatement"
          :loading="bankLoading"
          :footer-props="{ 'items-per-page-options': [5] }"
          :items-per-page="5"
        >
          <template v-slot:top>
            <v-toolbar color="grey lighten-3" flat>
              <v-toolbar-title style="font-size: 16px"
                >Un-Approved Statment Transactions</v-toolbar-title
              >
            </v-toolbar>
          </template>
          <template v-slot:[`item.account`]="{ item }">
            <b>{{ item.account }}</b>
          </template>
          <template v-slot:[`item.bank`]="{ item }">
            <span class="primary--text font-weight-bold">{{ item.bank }}</span>
          </template>
          <template v-slot:[`item.amount`]="{ item }">
            <v-chip outlined dark color="success">{{ item.amount }}</v-chip>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-btn
              width="100"
              color="success"
              @click="approveStatus(item.id)"
              depressed
              tile
              small
              >Approve</v-btn
            >
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <v-data-table
          style="height: 100%"
          :headers="invoiceHeaders"
          :items="invoices"
          :loading="invoiceLoading"
          :footer-props="{ 'items-per-page-options': [5] }"
          :items-per-page="5"
          :item-class="invoiceRowBackground"
        >
          <template v-slot:top>
            <v-toolbar color="grey lighten-3" flat>
              <v-toolbar-title style="font-size: 16px"
                >Pending Invoice</v-toolbar-title
              >
            </v-toolbar>
          </template>
          <template v-slot:[`item.invoice_no`]="{ item }">
            <b>{{ item.invoice_no }}</b>
          </template>

          <template v-slot:[`item.term`]="{ item }">
            <v-chip v-if="item.term_expired" label color="red" dark>{{ item.term_remain_day }} out {{ item.term }} days passed</v-chip>
            <v-chip v-else label>{{ item.term_remain_day }} out {{ item.term }} days passed</v-chip>
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <hr class="grey my-4" style="opacity: 0.05" />

    <v-row justify="end" align="center">
      <v-col cols="1">
        <v-select
          v-model="getChartType"
          :items="chartItem"
          label="Select Chart Type"
        ></v-select>
      </v-col>
      <v-col cols="1" align="center">
        <v-btn
          @click="serchbyMonthDialog = true"
          small
          depressed
          tile
          color="secondary"
          >Setting</v-btn
        >
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <apexchart
          height="450"
          :type="getChartType"
          :options="options"
          :series="series"
        ></apexchart>
      </v-col>
    </v-row>

    <hr class="grey my-4" style="opacity: 0.05" />

    <v-row justify="end" align="center">
      <v-col cols="1">
        <v-select
          v-model="getSalaryChartType"
          :items="chartItem"
          label="Select Chart Type"
        ></v-select>
      </v-col>
      <v-col cols="1" align="center">
        <v-btn
          @click="serchbyMonthSalaryDialog = true"
          small
          depressed
          tile
          color="info"
          >Setting</v-btn
        >
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <apexchart
          height="450"
          :type="getSalaryChartType"
          :options="salaryOptions"
          :series="salarySeries"
        ></apexchart>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-dialog v-model="serchbyDateDialog" persistent max-width="290">
        <v-card>
          <v-card-text>
            <v-card-title></v-card-title>
            <v-menu
              ref="menu"
              v-model="menu"
              :close-on-content-click="false"
              :return-value.sync="date"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-mask="'####-##-##'"
                  hint="YYYY-MM-DD format"
                  v-model="date"
                  label="Date"
                  prepend-icon="mdi-calendar"
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker v-model="date" scrollable>
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="menu = false">
                  Cancel
                </v-btn>
                <v-btn text color="primary" @click="$refs.menu.save(date)">
                  OK
                </v-btn>
              </v-date-picker>
            </v-menu>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="green darken-1"
              text
              @click="serchbyDateDialog = false"
            >
              Close
            </v-btn>
            <v-btn color="green darken-1" text @click="fetchDetails">
              Search
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
    <v-row justify="center">
      <v-dialog v-model="serchbyMonthDialog" persistent max-width="290">
        <v-card>
          <v-card-text>
            <v-card-title></v-card-title>
            <v-dialog
              ref="searchDialog"
              v-model="searchDateModal"
              :return-value.sync="searchMonth"
              persistent
              width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="filterSearchMonth"
                  label="Select Month"
                  prepend-icon="mdi-calendar"
                  readonly
                  dense
                  filled
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                range
                v-model="searchMonth"
                type="month"
                scrollable
              >
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="searchDateModal = false">
                  Cancel
                </v-btn>
                <v-btn
                  text
                  color="primary"
                  @click="$refs.searchDialog.save(searchMonth)"
                >
                  OK
                </v-btn>
              </v-date-picker>
            </v-dialog>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="green darken-1"
              text
              @click="serchbyMonthDialog = false"
            >
              Close
            </v-btn>
            <v-btn color="green darken-1" text @click="fetchChartData">
              Search
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
    <v-row justify="center">
      <v-dialog v-model="serchbyMonthSalaryDialog" persistent max-width="290">
        <v-card>
          <v-card-text>
            <v-card-title></v-card-title>
            <v-dialog
              ref="searchSalaryDialog"
              v-model="searchSalaryDateModal"
              :return-value.sync="searchMonthSalary"
              persistent
              width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="filterSalarySearchMonth"
                  label="Select Month"
                  prepend-icon="mdi-calendar"
                  readonly
                  dense
                  filled
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                range
                v-model="searchMonthSalary"
                type="month"
                scrollable
              >
                <v-spacer></v-spacer>
                <v-btn
                  text
                  color="primary"
                  @click="searchSalaryDateModal = false"
                >
                  Cancel
                </v-btn>
                <v-btn
                  text
                  color="primary"
                  @click="$refs.searchSalaryDialog.save(searchMonthSalary)"
                >
                  OK
                </v-btn>
              </v-date-picker>
            </v-dialog>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="green darken-1"
              text
              @click="serchbyMonthSalaryDialog = false"
            >
              Close
            </v-btn>
            <v-btn color="green darken-1" text @click="fetchSalaryChartData">
              Search
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
    <v-overlay style="z-index: 9999" :value="overlay">
      <v-progress-circular indeterminate size="32"></v-progress-circular>
    </v-overlay>
  </div>
</template>

<script>
export default {
  beforeCreate() {
    
    if (
      !this.$permission.userPermissions([
        "view-dashboard-page",
      ])
    ) {
      this.$router.push({ name: this.redirectToDefaultRoute() });
    }
  },
  data() {
    return {
      bankLoading: false,
      bankHeaders: [
        { text: "Account", value: "account" },
        { text: "Bank", value: "bank" },
        { text: "Date", value: "date" },
        { text: "CR | DR", value: "type" },
        { text: "Amount", value: "amount" },
        { text: "", value: "actions" },
      ],
      invoiceHeaders: [
        { text: "Date", value: "date" },
        { text: "Invoice no.", value: "invoice_no" },
        { text: "Party account", value: "party_account_name" },
        { text: "Associted account", value: "partner_account_name" },
        { text: "Term", value: "term" },
      ],
      invoices: [],
      bankStatement: [],
      date: new Date().toISOString().substr(0, 10),
      searchMonth: [],
      searchMonthSalary: [],
      menu: false,
      salaryMenu: false,
      searchDateModal: false,
      searchSalaryDateModal: false,
      serchbyDateDialog: false,
      serchbyMonthDialog: false,
      serchbyMonthSalaryDialog: false,
      monthOpeningBalance: 0,
      totalOpeningBalance: 0,
      totalToTransaction: 0,
      totalFromTransaction: 0,
      totalExpense: 0,
      prevToTransactionAmount: 0,
      prevFromTransactionAmount: 0,
      prevExpensesAmount: 0,
      prevTransactionDate: "",
      carryForward: 0,
      requestedStartDate: "",
      requestedEndDate: "",
      options: {},
      series: [],
      salaryOptions: {},
      salarySeries: [],
      chartItem: ["bar", "line", "area"],
      getChartType: "bar",
      getSalaryChartType: "bar",
      overlay: false,
      invoiceLoading: false,
    };
  },
  created() {
    this.fetchDetails();
    this.fetchChartData();
    this.fetchSalaryChartData();
    this.fetchBankStatement();
    this.fetchUnpaidInvoice();
  },
  methods: {
    approveStatus(id) {
      this.overlay = true;
      this.$http
        .get(`bank-statement/${id}`, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("access_token"),
          },
        })
        .then((response) => {
          this.$toast.success(response.data.data.message.join(", "));
          this.overlay = false;
          this.fetchBankStatement();
        })
        .catch(({ response }) => {
          this.$toast.error(response.data.error.message.join(", "));
          this.overlay = false;
        });
    },
    fetchBankStatement() {
      this.bankLoading = true;
      this.$http
        .get("get-not-approve-bank-statment", {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("access_token"),
          },
        })
        .then((response) => {
          const data = response.data.data;
          this.bankStatement = [];
          data.bank_statement.map((item) => {
            const el = {
              id: item.id,
              account: this.getItemName(item.partner_account.master_account),
              bank: item.bank_account.bank_name,
              date: item.formated_date,
              type: item.type_text,
              amount: item.amount,
            };

            this.bankStatement.push(el);
          });
          this.bankLoading = false;
        })
        .catch(({ response }) => {
          console.error(response);
          this.bankLoading = false;
          this.$toast.error(response.data.error.join(", "));
        });
    },
    fetchUnpaidInvoice() {
      this.invoiceLoading = true;
      this.$http
        .get("unpaid-invoice", {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("access_token"),
          },
        })
        .then((response) => {
          const invoices = response.data.data.invoices;
          this.invoices = invoices.map((iv) => {
            return {
              id: iv.id,
              party_account_id: iv.party_account_id,
              party_account_name: this.getItemName(
                iv.party_account.master_account
              ),
              partner_account_id: iv.partner_account_id,
              partner_account_name: this.getItemName(
                iv.partner_account.master_account
              ),
              invoice_no: iv.invoice_no,
              date: iv.date,
              status: iv.status,
              term: iv.term,
              term_expired: iv.term_expired,
              term_remain_day: iv.term_remain_day,
            };
          });
          this.invoiceLoading = false;
        })
        .catch(({ response }) => {
          this.$toast.error(response.data.error.join(", "));
          this.invoiceLoading = false;
        });
    },
    fetchChartData() {
      this.$http
        .get("get-opening-balance", {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("access_token"),
          },
          params: {
            months: this.searchMonth,
          },
        })
        .then((response) => {
          const data = response.data.data;

          this.options = {
            xaxis: {
              categories: data.dates,
            },
            colors: ["#34c939", "#de3333", "#5983ba"],
            dataLabels: {
              enabled: false,
            },
          };

          this.series = [
            {
              name: "Earning",
              data: data.earning,
            },
            {
              name: "Debits",
              data: data.debits,
            },
            {
              name: "Net Profit",
              data: data.profit,
            }
          ];

          this.serchbyMonthDialog = false;
        })
        .catch(({ response }) => {
          console.error(response);
          this.$toast.error(response.data.error.join(", "));
          this.serchbyMonthDialog = false;
        });
    },
    fetchSalaryChartData() {
      this.$http
        .get("get-salary-chart-data", {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("access_token"),
          },
          params: {
            months: this.searchMonthSalary,
          },
        })
        .then((response) => {
          const data = response.data.data;

          this.salaryOptions = {
            xaxis: {
              categories: data.dates,
            },
            colors: ["#1976D2"],
            dataLabels: {
              enabled: false,
            },
          };

          this.salarySeries = [
            {
              name: "Salary",
              data: data.salary,
            },
          ];

          this.serchbyMonthSalaryDialog = false;
        })
        .catch(({ response }) => {
          console.error(response);
          this.$toast.error(response.data.error.join(", "));
          this.serchbyMonthSalaryDialog = false;
        });
    },
    fetchDetails() {
      this.$http
        .get("dashboard?0[]=" + this.date, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("access_token"),
          },
        })
        .then((response) => {
          const data = response.data.data;
          this.monthOpeningBalance = data.monthOpeningBalance;
          this.totalOpeningBalance = data.totalOpeningBalance;
          this.totalToTransaction = data.totalToTransaction;
          this.totalFromTransaction = data.totalFromTransaction;
          this.totalExpense = data.totalExpense;
          this.prevToTransactionAmount = data.prevToTransactionAmount;
          this.prevFromTransactionAmount = data.prevFromTransactionAmount;
          this.prevExpensesAmount = data.prevExpensesAmount;
          this.prevTransactionDate =
            data.openingBalanceCurrentMonth + " to " + data.requestedStartDate;
          this.requestedStartDate = data.requestedStartDate;
          this.requestedEndDate = data.requestedEndDate;
          this.carryForward = data.carryForward;
          this.serchbyDateDialog = false;
        })
        .catch(({ response }) => {
          console.log(response);
          this.$toast.error(response.data.error.message[0]);
          this.serchbyDateDialog = false;
        });
    },

    getItemName(item) {
      if (item.account_type_id == 1 && item.acc_number != null)
        return (
          item.name +
          " " +
          (item.last_name || "") +
          " (" +
          item.acc_number.substring(item.acc_number.length - 4) +
          ")"
        );
      return item.name + " " + (item.last_name || "");
    },
    invoiceRowBackground(item) {
      return item.term_expired ? "bg-blink" : "";
    },
  },
  computed: {
    filterSearchMonth() {
      return this.searchMonth.join(" ~ ");
    },
    filterSalarySearchMonth() {
      return this.searchMonthSalary.join(" ~ ");
    },
  },
};
</script>

<style>
.bg-blink {
  animation: 1s blink-red infinite;
  color: black;
}

@keyframes blink-red {
  0%,
  100% {
    background-color: #ffffff;
  }
  50% {
    background-color: #cfcfcf;
  }
}
</style>
