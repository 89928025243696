import Vue from 'vue';
import Router from 'vue-router';

Vue.use(Router);

import Master from "./components/layout/Master";
import Dashboard from "./components/dashboard/Dashboard";
import Login from "./components/auth/Login";
import Account from './components/account/Account'
import Transaction from './components/transaction/Transaction'
import Branch from './components/branch/Branch'
import AssignAccount from './components/account/AssignAccount'
import LinkAccount from './components/link-account/LinkAccount'
import Expense from './components/expense/Expense'
import NotFound from './components/not-found/NotFound'
// import Analytic from './components/analytics/Analytic'
import Rojmel from './components/rojmel/Rojmel'
import Profit from './components/profit/Profit'
import IncomeExport from './components/income-export/IncomeExport'
import EarningReport from './components/earning-report/EarningReport'
import SalaryReport from './components/salary-report/SalaryReport'
import SalarySlip from './components/salary-slip/SalarySlip'
import User from './components/user/User'
import Setting from './components/setting/Setting'
import SalarySetting from './components/salary-setting/SalarySetting'
import BankStatement from './components/bank-statement/BankStatement'
import Invoice from './components/invoices/Invoice'

let routes = [
    {
        path: '/',
        name: 'login',
        component: Login
    },
    {
        path: '/dashboard',
        name: 'master',
        component: Master,
        children: [
            {
                path: '/home',
                name: 'dashboard',
                component: Dashboard,
            },
            // {
            //     path: '/analytics',
            //     name: 'analytics',
            //     component: Analytic,
            // },
            {
                path: '/branches',
                name: 'branches',
                component: Branch
            },
            {
                path: '/accounts',
                name: 'accounts',
                component: Account
            },
            {
                path: '/transactions/:trId?',
                name: 'transactions',
                props: true,
                component: Transaction
            },
            {
                path: '/assign-account',
                name: 'assign-account',
                component: AssignAccount
            },
            {
                path: '/link-account',
                name: 'link-account',
                component: LinkAccount
            },
            {
                path: '/user',
                name: 'user',
                component: User
            },
            {
                path: '/expense/:exId?',
                props: true,
                name: 'expense',
                component: Expense
            },
            {
                path: '/rojmel',
                name: 'rojmel',
                component: Rojmel
            },
            {
                path: '/profit',
                name: 'profit',
                component: Profit
            },
            {
                path: '/income-export',
                name: 'IncomeExport',
                component: IncomeExport
            },
            {
                path: '/earning-report',
                name: 'EarningReport',
                component: EarningReport
            },
            {
                path: '/salary-report',
                name: 'SalaryReport',
                component: SalaryReport
            },
            {
                path: '/salary-slip',
                name: 'SalarySlip',
                component: SalarySlip
            },
            {
                path: '/setting',
                name: 'Setting',
                component: Setting
            },
            {
                path: '/salary-setting',
                name: 'SalarySetting',
                component: SalarySetting
            },
            {
                path: '/bank-statement',
                name: 'BankStatement',
                component: BankStatement
            },
            {
                path: '/invoices/:ivId?',
                props: true,
                name: 'Invoice',
                component: Invoice
            }
        ]
    },
    {   
      path: '/:notFound(.*)',
      component: NotFound
    }

];
const router = new Router({
    routes: routes,
    mode: 'history'

});

router.beforeEach((to, _, next) => {
    if (!to.meta.isFronted) {
      const auth = localStorage.getItem('access_token');
      if ((auth && to.name !== "login") || (!auth && to.name === "login")) {
        next();
      } else if (auth && to.name == "login") {
        next({
          name: "dashboard",
        });
      } else {
        next({
          name: "login",
        });
      }
    } else {
      next();
    }
  });
export default router;
