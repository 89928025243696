<template>
  <div class="mr-4 ml-4 mt-4">
  <v-data-table
    :headers="headers"
    :items="settings"
    :loading="tableLoading"
    sort-by="calories"
    class="elevation-1"
  >
    <template v-slot:top>
      <v-toolbar color="grey lighten-3" flat>
        <v-toolbar-title>Working Days</v-toolbar-title>
        <v-divider class="mx-4" inset vertical></v-divider>
        <v-spacer></v-spacer>
        <v-dialog v-model="dialog" max-width="500px">
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="success" dark tile class="mb-2" v-bind="attrs" v-on="on">
              + Add Working Days
            </v-btn>
          </template>
          <v-card>
            <v-card-title>
              <span class="text-h5">{{ formTitle }}</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12">
                    <v-menu
                      v-model="menuMonth"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="salaryMonth"
                          type="month"
                          label="Salary Month"
                          persistent-hint
                          prepend-icon="mdi-calendar"
                          v-bind="attrs"
                          @blur="salaryMonth"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="salaryMonth"
                        type="month"
                        no-title
                        @input="menuMonth = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      v-model="workingDays"
                      label="Working Days"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="close"> Cancel </v-btn>
              <v-btn color="info" depressed tile :loading="saveLoading" @click="save"> Save </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="dialogDelete" max-width="500px">
          <v-card>
            <v-card-title class="text-h5"
              >Are you sure you want to delete this item?</v-card-title
            >
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeDelete"
                >Cancel</v-btn
              >
              <v-btn color="blue darken-1" text @click="deleteItemConfirm"
                >OK</v-btn
              >
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>
    <template v-slot:item.actions="{ item }">
      <v-icon small class="mr-2" @click="editItem(item)"> mdi-pencil </v-icon>
      <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon>
    </template>
  </v-data-table>
  </div>
</template>

<script>
export default {
  data: () => ({
    salaryMonth: null,
    workingDays: 0,
    menuMonth: false,
    dialog: false,
    dialogDelete: false,
    tableLoading: false,
    saveLoading: false,
    headers: [
     { text: 'Salary Month' , value: 'salaryMonth'},
     { text: 'Total Days' , value: 'totalDays'},
     { text: 'Working Days' , value: 'workingDays'},
    ],
    settings: [],
    editedIndex: -1,
    editedItem: {
      name: "",
      calories: 0,
      fat: 0,
      carbs: 0,
      protein: 0,
    },
    defaultItem: {
      name: "",
      calories: 0,
      fat: 0,
      carbs: 0,
      protein: 0,
    },
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "Add Days" : "Edit Days";
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  created() {
    this.initialize();
  },

  methods: {
    initialize() {
      this.tableLoading = true;
      this.$http
        .get("settings", {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("access_token"),
          },
        })
        .then((response) => {
          const settings = response.data.data.settings;
          this.settings = [];
          settings.forEach(element => {
              this.settings.push({
                salaryMonth : element.salary_month,
                totalDays : element.total_day,
                workingDays : element.working_day
              });
          });
          this.tableLoading = false;
        })
        .catch((error) => {
          console.log("error", error.response);
          this.tableLoading = false;
        });
    },
    
    editItem(item) {
      this.editedIndex = this.desserts.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    deleteItem(item) {
      this.editedIndex = this.desserts.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      this.desserts.splice(this.editedIndex, 1);
      this.closeDelete();
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    save() {
      var data = new FormData();
      data.append("salary_month", this.salaryMonth);
      data.append("working_days", this.workingDays);
      if (this.editedIndex > -1) {
        this.updateSetting(data)
      } else {
        this.saveSetting(data)
      }
    },
    saveSetting(data) {
      this.saveLoading = true;
      this.$http
        .post("settings", data, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("access_token"),
          }
        }).then((response) => {
          if (response.data.status === 200) {
            this.close();
            this.initialize();
            this.$toast.success(response.data.data.message[0]);
            this.saveLoading = false;
          }
        })
        .catch((error) => {
          this.$toast.error("Something went wrong");
          console.log("error", error.response);
          this.saveLoading = false;
        });
    },
    updateSetting(data) {
      this.saveLoading = true;
      data.append("_method", "PUT");
      this.$http
        .post("settings/" + this.editedIndex, data, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("access_token"),
          },
        })
        .then((response) => {
          if (response.data.status === 200) {
            this.close();
            this.clear();
            this.initialize();
            this.$toast.success(response.data.data.message[0]);
          }
          this.saveLoading = false;
        })
        .catch((error) => {
          this.$toast.error("Something went wrong");
          console.log(error);
          this.saveLoading = false;
        });
    },
    clear() {
      this.salaryMonth = null;
      this.workingDays = 0;
    },
  },
};
</script>