<template v-slot:top>
  <v-card elevation="3" outlined tile class="mr-4 ml-4 mt-4">
    <div class="text-center">
      <v-dialog v-model="dialog" width="500">
        <v-card>
          <v-data-table :headers="opHeaders" :items="openingBalanceList">
            <template slot="body.append">
              <tr>
                <th>Total</th>
                <th>{{ monthOpeningBalance }}</th>
              </tr>
            </template>
          </v-data-table>

          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="dialog = false"> Close </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
    <div class="text-center">
      <v-dialog v-model="exportPdfDialog" width="500">
        <v-card>
          <v-col
        class="d-flex"
        cols="12"
      >
        <v-select
          :items="exportLayout"
          v-model="exportLayoutId"
          filled
          label="Layout"
        ></v-select>
      </v-col>
          <v-divider></v-divider>
          <v-card-actions class="pa-4">
            <v-spacer></v-spacer>
            <v-btn color="danger" text @click="exportPdfDialog = false"> Close </v-btn>
            <v-btn color="primary" depressed tile :loading="loading5" @click="exportRojmelPdf()"> Export </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
    <v-toolbar color="grey lighten-3 mb-4" flat>
      <v-toolbar-title>Rojmel</v-toolbar-title>
    </v-toolbar>
    <v-row class="pa-4">
      <v-col cols="3">
        <v-dialog
          ref="dialog"
          v-model="modal"
          :return-value.sync="dates"
          persistent
          width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="dateRangeText"
              label="Select date range"
              prepend-icon="mdi-calendar"
              readonly
              filled
              dense
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker v-model="dates" range scrollable>
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="modal = false"> Cancel </v-btn>
            <v-btn text color="primary" @click="$refs.dialog.save(dates)">
              OK
            </v-btn>
          </v-date-picker>
        </v-dialog>
      </v-col>
      <v-col cols="12" md="6">
        <v-btn
          class="ma-2"
          :loading="loading4"
          :disabled="disableSearch"
          color="info"
          depressed
          tile
          @click="fetchAccountData(account)"
        >
          Search
          <template v-slot:loader>
            <span class="custom-loader">
              <v-icon light>mdi-cached</v-icon>
            </span>
          </template>
        </v-btn>
        <v-btn
          class="ma-2"
          :disabled="disableSearch"
          color="success"
          @click="exportPdfDialog = true"
          depressed
          tile
        >
          Export PDF
          <template v-slot:loader>
            <span class="custom-loader">
              <v-icon light>mdi-cached</v-icon>
            </span>
          </template>
        </v-btn>
        <v-btn
          class="ma-2"
          :loading="loading6"
          :disabled="disableSearch"
          color="success darken-2"
          @click="exportRojmelExcel()"
          depressed
          tile
        >
          Export Excel
          <template v-slot:loader>
            <span class="custom-loader">
              <v-icon light>mdi-cached</v-icon>
            </span>
          </template>
        </v-btn>
        <v-btn
          class="ma-2"
          :disabled="disableSearch"
          color="success darken-2"
          @click="googleDriveTypeSelectDialog = true"
          depressed
          tile
        >
          Save to Google Drive
          <template v-slot:loader>
            <span class="custom-loader">
              <v-icon light>mdi-cached</v-icon>
            </span>
          </template>
        </v-btn>
      </v-col>
    </v-row>
    <hr class="grey mb-4" style="opacity: 0.05">
    <h3 align="center" >Balance Sheet</h3>
    <v-row class="pa-3">
      <v-col cols="12" md="6">
        <p class="text-center text-decoration-underline h4">Credits</p>
        <v-simple-table>
          <template v-slot:default>
            <tbody>
              <tr
                style="cursor: pointer"
                v-if="openingBalanceCurrentMonth"
                @click="dialog = true"
              >
                <th>
                  Opening Balance (Current Month) <br />
                  <span style="color: gray !important">{{
                    openingBalanceCurrentMonth
                  }}</span>
                </th>
                <td>{{ monthOpeningBalance }}</td>
              </tr>
              <tr v-if="prevTransactionDate">
                <th>
                  Amount Credited between <br />
                  <span style="color: gray !important">{{
                    prevTransactionDate
                  }}</span>
                </th>
                <td>{{ prevToTransactionAmount }}</td>
              </tr>
              <tr v-if="prevTransactionDate">
                <th>
                  Total Opening Balance<br />
                  <span style="color: gray !important">{{
                    prevTransactionDate
                  }}</span>
                </th>
                <td>{{ currentOpeningBalance }}</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
        <v-data-table :headers="toHeaders" :items="toTransaction" 
          :footer-props="{
            'items-per-page-options': [10, 20, 30, 40, -1]
          }" 
          :items-per-page="40"
          @click:row="handleClick">

          <template v-slot:[`item.purpose_type`]="{ item }">
            {{ getPurposeType(item.purpose_type) || '-' }}
          </template>

          <template v-slot:[`item.amount`]="{ item }">
            <v-chip>{{ item.amount}}</v-chip>
          </template>

          <template slot="body.append">
            <tr class="grey lighten-5">
              <th></th>
              <th>Total Credits</th>
              <td>
                <v-chip color="info">
                  {{ sumToField("amount") }}
                </v-chip>
              </td>
            </tr>
          </template>

          <template slot="body.append">
            <tr class="grey lighten-5">
              <th></th>
              <th>Balance Carry Forward</th>
              <td>
                <v-chip color="orange" dark>
                  {{ totalToFiels("amount") - sumFromField("amount") }}
                </v-chip>
              </td>
            </tr>
            <tr class="grey lighten-5">
              <th></th>
              <th>Total</th>
              <td>  
                <v-chip color="green" dark>
                  {{ totalToFiels("amount") }}
                </v-chip>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-col>
      <v-col cols="12" md="6">
        <p class="text-center text-decoration-underline h4 mt-4">Debits</p>
        <v-simple-table>
          <template v-slot:default>
            <tbody>
              <tr v-if="prevTransactionDate">
                <th>
                  Amount Debited between <br />
                  <span style="color: gray !important">{{
                    prevTransactionDate
                  }}</span>
                </th>
                <td>{{ prevFromTransactionAmount }}</td>
              </tr>
              <tr v-if="prevTransactionDate">
                <th>
                  Amount Expensed between <br />
                  <span style="color: gray !important">{{
                    prevTransactionDate
                  }}</span>
                </th>
                <td>{{ prevExpensesAmount }}</td>
              </tr>
              <tr v-if="prevTransactionDate">
                <th>
                  Total Amount Debited between <br />
                  <span style="color: gray !important">{{
                    prevTransactionDate
                  }}</span>
                </th>
                <td>{{ totalAmountDebited }}</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
        <v-data-table :headers="fromheaders" :items="fromTransaction" 
        :footer-props="{
            'items-per-page-options': [10, 20, 30, 40, -1]
          }" 
        :items-per-page="40"
        @click:row="handleClick">
          <template v-slot:[`item.purpose_type`]="{ item }">
            {{ getPurposeType(item.purpose_type) || '-' }}
          </template>

          <template v-slot:[`item.amount`]="{ item }">
            <v-chip>{{ item.amount}}</v-chip>
          </template>

          <template slot="body.append">
            <tr class="grey lighten-5">
              <th></th>
              <th>Total Debits</th>
              <td>
                <v-chip color="info">
                  {{ sumFromField("amount") }}
                </v-chip>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <v-row justify="center">
    <v-dialog
      v-model="googleDriveTypeSelectDialog"
      persistent
      max-width="600px"
    >
      <v-card>
        <v-card>
          <v-col
            class="d-flex"
            cols="12"
          >
            <v-select
              :items="[ { text: 'PDF', value: 1}, { text: 'Excel', value: 2}]"
              v-model="driveExportType"
              filled
              label="Type"
            ></v-select>
          </v-col>
          <v-col
            class="d-flex"
            cols="12"
          >
            <v-select
              v-if="driveExportType == 1"
              :items="exportLayout"
              v-model="driveExportLayoutId"
              filled
              label="Layout"
            ></v-select>
          </v-col>
          <v-divider></v-divider>
          <v-card-actions class="pa-4">
            <v-spacer></v-spacer>
            <v-btn color="danger" text @click="googleDriveTypeSelectDialog = false"> Close </v-btn>
            <v-btn color="primary" depressed tile @click="$refs.googleDrive.getRootFolder()"> Open Drive </v-btn>
          </v-card-actions>
        </v-card>
      </v-card>
    </v-dialog>
  </v-row>

    <GoogleDrive type="rojmel" minimized-popup-title="Google Drive (J.)" :data="getUploadData" upload="saveRojmelToDrive" ref="googleDrive"></GoogleDrive>
  </v-card>
</template>

<script>
import GoogleDrive from './../google-drive/GoogleDrive.vue'

export default {
  components: {
    GoogleDrive
  },
  beforeCreate() {
    if (
      !this.$permission.userPermissions([
        "view-rojmel-page",
      ])
    )
      this.$router.push({ name: "dashboard" });
  },
  data: () => ({
    googleDriveTypeSelectDialog: false,
    exportPdfDialog: false,
    exportLayoutId: 1,
    driveExportType: 1,
    driveExportLayoutId: 1,
    openingBalanceList: [],
    dialog: false,
    openingBalance: 0,
    prevToTransactionAmount: 0,
    prevFromTransactionAmount: 0,
    prevExpensesAmount: 0,
    prevTransactionDate: null,
    openingBalanceCurrentMonth: null,
    monthOpeningBalance: null,
    requestedStartDate: null,
    requestedEndDate: null,
    accountType: null,
    accountTypes: [],
    accounts: [],
    account: null,
    loading: true,
    opHeaders: [
      {
        text: "Account",
        align: "start",
        filterable: false,
        value: "account",
      },
      { text: "Amount", value: "amount" },
    ],
    toHeaders: [
      {
        text: "tr_id",
        filterable: false,
        align: "left d-none",
        value: "id",
      },
      {
        text: "Type",
        filterable: false,
        align: "left d-none",
        value: "type",
      },
      {
        text: "Credited To (Debited From)",
        align: "start",
        filterable: false,
        value: "account",
      },
      {
        text: "Type",
        filterable: false,
        value: "purpose_type"
      },
      { text: "Amount", value: "amount" },
    ],
    fromheaders: [
      {
        text: "tr_id",
        filterable: false,
        align: "left d-none",
        value: "id",
      },
      {
        text: "Type",
        filterable: false,
        align: "left d-none",
        value: "type",
      },
      {
        text: "Debited from (Credited To)",
        align: "start",
        filterable: false,
        value: "account",
      },
      {
        text: "Type",
        filterable: false,
        value: "purpose_type"
      },
      { text: "Amount", value: "amount" },
    ],
    toTransaction: [],
    fromTransaction: [],
    expense: [],
    carryFwd: 0,
    dates: [],
    modal: false,
    menu2: false,
    loader: null,
    loading4: false,
    loading5: false,
    loading6: false,
  }),
  computed: {
    getUploadData() {
      return {
        dates: this.dates,
        driveExportType: this.driveExportType,
        driveExportLayoutId: this.driveExportLayoutId
      }
    },
    dateRangeText() {
      return this.dates.join(" ~ ");
    },
    disableSearch() {
      return this.dates.length == 0;
    },
    currentOpeningBalance() {
      return (this.monthOpeningBalance + this.prevToTransactionAmount) - (this.prevFromTransactionAmount + this.prevExpensesAmount);
    },
    totalAmountDebited() {
      return this.prevFromTransactionAmount + this.prevExpensesAmount;
    },
    exportLayout() {
      return [
        {
          text: 'Layout - 1 (Columns)',
          value: 1
        },
        {
          text: 'Layout - 2 (Rows)',
          value: 2
        }
      ]
    },
  },
  watch: {
    loader() {
      const l = this.loader;
      this[l] = !this[l];

      setTimeout(() => (this[l] = false), 300);

      this.loader = null;
    },
  },
  methods: {
    exportRojmelPdf() {
      this.loading5 = true;
      this.$http
        .get(
          `analytics/export-rojmeldata/1`,
          {
            responseType: "blob",
            headers: {
              Authorization: "Bearer " + localStorage.getItem("access_token"),
            },
            params: {
              0: this.dates,
              layout: this.exportLayoutId
            }
          }
        )
        .then((response) => {
          const file = new Blob([response.data], { type: "application/pdf" });
          //Build a URL from the file
          const fileURL = URL.createObjectURL(file);
          //Open the URL on new Window
          window.open(fileURL);
          this.loading5 = false;
          this.exportPdfDialog = false;
        })
        .catch((error) => {
          console.log(error);
          this.loading5 = false;
        });
      // analytics/export-rojmeldata/{type}
    },
    exportRojmelExcel() {
      this.loading6 = true;
      this.$http
        .get(
          `analytics/export-rojmeldata/2`,
          {
            responseType: "blob",
            headers: {
              Authorization: "Bearer " + localStorage.getItem("access_token"),
            },
            params: {
              0: this.dates,
            }
          }
        )
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          const fileName = this.dates.join('_to_') + '_rojmel.xlsx';
          link.setAttribute("download", fileName.replace(/-/g,'_')); //or any other extension
          document.body.appendChild(link);
          link.click();
          this.loading6 = false;
        })
        .catch((error) => {
          console.log(error);
          this.loading6 = false;
        });
      // analytics/export-rojmeldata/{type}
    },
    handleClick(value) {
      let routeData = null
      if(value.type == 1) {
        routeData = this.$router.resolve({name: 'transactions', params: {trId: value.tr_id}});
      } else if(value.type == 2) {
        routeData = this.$router.resolve({name: 'expense', params: {exId: value.tr_id}});
      }
      if(routeData) {
        window.open(routeData.href, '_blank');
      }
    },
    getItemName(item) {
      if (item.account_type_id == 1 && item.acc_number != null)
        return (
          item.name +
          " (" +
          item.acc_number.substring(item.acc_number.length - 4) +
          ")"
        );
      return item.name;
    },
    fetchAccountData() {
      this.loading4 = true;
      this.$http
        .get("analytics/getRojmelData", {
          params: {
            0: this.dates,
          },
          headers: {
            Authorization: "Bearer " + localStorage.getItem('access_token')
          }
        })
        .then((response) => {
          this.prevToTransactionAmount =
            response.data.data.prevToTransactionAmount;
          this.prevFromTransactionAmount =
            response.data.data.prevFromTransactionAmount;
          this.prevExpensesAmount = response.data.data.prevExpensesAmount;

          this.fromTransaction = response.data.data.from_transaction.map(
            (item) => {
              return {
                tr_id: item.id,
                purpose_type: item.purpose_type,
                type: 1,
                amount: item.amount,
                account: `${this.dates.length > 1 ? item.date + " - " : ""}${
                  this.getItemName(item.from_account.master_account)
                } - (${this.getItemName(item.to_account.master_account)})`,
              };
            }
          );

          this.toTransaction = response.data.data.to_transaction.map((item) => {
            return {
              tr_id: item.id,
              purpose_type: item.purpose_type,
              type: 1,
              amount: item.amount,
              account: `${this.dates.length > 1 ? item.date + " - " : ""}${
                  this.getItemName(item.to_account.master_account)
              } - (${this.getItemName(item.from_account.master_account)})`,
            };
          });
          
          this.prevTransactionDate = response.data.data.prevTransactionDate;
          this.openingBalanceCurrentMonth =
            response.data.data.openingBalanceCurrentMonth;
          this.monthOpeningBalance = response.data.data.monthOpeningBalance;

          if (response.data.data.expense) {
            response.data.data.expense.forEach((element) => {
              this.fromTransaction.push({
                tr_id: element.id,
                type: 2,
                purpose_type: -1,
                amount: element.amount,
                account: `${this.dates.length > 1 ? element.date + " - " : ""}${
                  element.account.master_account.name
                } - (${element.notes || ''})`,
                to_account: {
                  master_account: { name: element.notes + " (expense)" },
                },
              });
            });
          }

          this.carryFwd = response.data.data.carryFwd;
          this.expense = response.data.data.expense;
          this.requestedStartDate = response.data.data.requestedStartDate;
          this.requestedEndDate = response.data.data.requestedEndDate;

          this.openingBalanceList = response.data.data.openingBalanceList.map(
            (item) => {
              return {
                amount: item.amount,
                account: `${item.account.master_account.name} ${item.account.master_account.middle_name || ''} ${item.account.master_account.last_name || ''}`,
              };
            }
          );

          this.loading4 = false;
        })
        .catch((error) => {
          if (error.response.status) {
            this.$toast.error(error.response.data.error.message.join(", "));
          } else {
            this.$toast.error("Something went wrong");
          }
          console.log("error", error.response);
          this.loading4 = false;
        });
    },
    getPurposeType(id) {
      if (id === 0) return "Normal";
      if (id === 1) return "Salary";
      if (id === 2) return "Upad";
      if (id === 3) return "Income";
      if (id === 4) return "Self Credit";
      if (id === -1) return "Expense";
    },
    getTransactionPerpose(id) {
      if (id === 0) return "Normal";
      if (id === 1) return "Salary";
      if (id === 2) return "Withdrawal";
    },
    sumToField(key) {
      // sum data in give key (property)
      return this.toTransaction.reduce((a, b) => a + (b[key] || 0), 0);
      // return this.toTransaction.reduce((a, b) => a + (b[key] || 0), 0) + this.monthOpeningBalance + this.prevToTransactionAmount;
    },
    sumFromField(key) {
      // sum data in give key (property)
      return this.fromTransaction.reduce((a, b) => a + (b[key] || 0), 0);
      // return this.fromTransaction.reduce((a, b) => a + (b[key] || 0), 0) + this.prevFromTransactionAmount + this.prevExpensesAmount;
    },
    totalToFiels(key) {
      return (
        this.toTransaction.reduce((a, b) => a + (b[key] || 0), 0) +
        this.currentOpeningBalance
      );
    },
    totalFromFiels(key) {
      return (
        this.fromTransaction.reduce((a, b) => a + (b[key] || 0), 0) +
        this.prevFromTransactionAmount +
        this.prevExpensesAmount
      );
    },
  },
  created() {
    this.loading = false;
    let keyPressed = false;
    document.addEventListener("keydown", (e) => {
      if(e.code == "ShiftLeft") {
        keyPressed = true;
      }
    })
    document.addEventListener("keyup", (e) => {
      if(keyPressed && e.code == "KeyO") {
        this.dialog = !this.dialog
      }else if(keyPressed && e.code == "KeyD") {
        this.modal = !this.modal
      }
      keyPressed = false;
    })
  },
};
</script>

<style>
.custom-loader {
  animation: loader 1s infinite;
  display: flex;
}
@-moz-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-o-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>
