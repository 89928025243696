<template>
  <div class="mr-4 ml-4 pb-4">
    <v-radio-group v-model="isSalaryAccount">
      <v-data-table
        :headers="headers"
        :items="getMasterAccounts"
        class="elevation-1"
        :loading="isLoading"
        :search="search"
        :footer-props="{ 'items-per-page-options': [10, 20, 30, 40, 50, -1] }"
        :items-per-page="-1"
      >
        <template v-slot:top>
          <v-toolbar color="grey lighten-3" flat>
            <v-toolbar-title>Master Accounts</v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-spacer></v-spacer>
            <v-dialog v-model="dialog" max-width="900px">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="green"
                  dark
                  tile
                  class="mb-2"
                  v-bind="attrs"
                  v-on="on"
                >
                  + Add Account
                </v-btn>
              </template>
              <v-card>
                <v-card-title>
                  <span class="headline">{{ formTitle }}</span>
                </v-card-title>

                <v-card-text>
                  <v-container>
                    <v-select
                      v-model.number="accountType"
                      :items="accountTypes"
                      item-value="value"
                      :error-messages="selectErrors"
                      label="Select Type"
                      required
                      @change="
                        $v.accountType.$touch();
                        clear();
                      "
                      @blur="$v.accountType.$touch()"
                    ></v-select>
                  </v-container>
                  <v-container v-if="accountType === 1">
                    <v-text-field
                      v-model.trim="bank.acc_number"
                      label="Bank Account Number"
                    >
                      <v-icon slot="append" color="black"> mdi-pencil </v-icon>
                    </v-text-field>
                    <v-text-field v-model.trim="bank.name" label="Bank Name">
                      <v-icon slot="append" color="black"> mdi-pencil </v-icon>
                    </v-text-field>
                    <v-text-field
                      v-model.trim="bank.branch"
                      label="Branch Name"
                    >
                      <v-icon slot="append" color="black"> mdi-pencil </v-icon>
                    </v-text-field>
                    <v-text-field v-model.trim="bank.ifsc" label="IFSC">
                      <v-icon slot="append" color="black"> mdi-pencil </v-icon>
                    </v-text-field>
                  </v-container>
                  <v-container v-if="accountType === 2">
                    <v-text-field v-model.trim="cash.name" label="Account Name">
                      <v-icon slot="append" color="black"> mdi-pencil </v-icon>
                    </v-text-field>
                  </v-container>
                  <v-container
                    v-if="
                      accountType === 3 ||
                      accountType === 4 ||
                      accountType === 5
                    "
                  >
                    <v-row>
                      <v-col cols="12" md="3">
                        <v-text-field
                          v-model.trim="party.name"
                          label="First name"
                        >
                          <v-icon slot="append" color="black">
                            mdi-pencil
                          </v-icon>
                        </v-text-field>
                      </v-col>

                      <v-col cols="12" md="4">
                        <v-text-field
                          v-model.trim="party.middle_name"
                          label="Middle name"
                        ></v-text-field>
                      </v-col>

                      <v-col cols="12" md="4">
                        <v-text-field
                          v-model.trim="party.last_name"
                          label="Last Name"
                        ></v-text-field>
                      </v-col>
                    </v-row>

                    <v-text-field
                      v-if="accountType === 3"
                      v-model.trim="party.party_company_name"
                      label="Company Name"
                    >
                    </v-text-field>

                    <v-text-field
                      v-if="accountType === 3"
                      v-model.trim="party.party_company_address"
                      label="Company Address"
                    >
                    </v-text-field>

                    <v-text-field v-model.trim="party.email" label="E-mail">
                      <v-icon slot="append" color="black"> mdi-pencil </v-icon>
                    </v-text-field>

                    <v-text-field
                      v-model.trim="party.mobile_no"
                      label="Mobile No."
                    >
                    </v-text-field>

                    <v-text-field
                      v-model.trim="party.pan_no"
                      label="PAN"
                    ></v-text-field>
                    <v-text-field
                      v-model.trim="party.gst_no"
                      label="GST No."
                    ></v-text-field>
                    <v-text-field
                      v-if="accountType === 5"
                      v-model.trim="party.ctc"
                      label="CTC"
                    ></v-text-field>
                    <v-text-field
                      v-if="accountType === 5"
                      v-model.trim="party.attendance_id"
                      label="Attendance Id"
                    ></v-text-field>
                    <v-checkbox
                      v-if="accountType === 5"
                      v-model="party.update_ctc"
                      label="Update CTC ?"
                    ></v-checkbox>
                  </v-container>
                  <v-container v-if="accountType === 6">
                    <v-row>
                      <v-col cols="12" md="3">
                        <v-select
                          v-model="employee.prefix"
                          :items="namePrefix"
                          label="Select Name Prefix"
                        ></v-select>
                      </v-col>
                      <v-col cols="12" md="3">
                        <v-text-field
                          v-model.trim="employee.name"
                          label="First name"
                        >
                          <v-icon slot="append" color="black">
                            mdi-pencil
                          </v-icon>
                        </v-text-field>
                      </v-col>

                      <v-col cols="12" md="3">
                        <v-text-field
                          v-model.trim="employee.middle_name"
                          label="Middle name"
                        ></v-text-field>
                      </v-col>

                      <v-col cols="12" md="3">
                        <v-text-field
                          v-model.trim="employee.last_name"
                          label="Last Name"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" md="4">
                        <v-text-field
                          v-model.trim="employee.email"
                          label="E-mail"
                        >
                          <v-icon slot="append" color="black">
                            mdi-pencil
                          </v-icon>
                        </v-text-field>
                      </v-col>
                      <v-col cols="12" md="4">
                        <v-menu
                          v-model="dobMenu"
                          :close-on-content-click="false"
                          :nudge-right="40"
                          transition="scale-transition"
                          offset-y
                          min-width="auto"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-mask="'####-##-##'"
                              hint="YYYY-MM-DD format"
                              v-model="employee.dob"
                              label="Date of Birth"
                              prepend-icon="mdi-calendar"
                              v-bind="attrs"
                              v-on="on"
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            v-model="employee.dob"
                            @input="dobMenu = false"
                          ></v-date-picker>
                        </v-menu>
                      </v-col>
                      <v-col cols="12" md="4">
                        <v-radio-group v-model="employee.gender" row>
                          <v-radio
                            v-for="(item, index) in genderArr"
                            :key="index"
                            :label="item.text"
                            :value="item.value"
                          ></v-radio>
                        </v-radio-group>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" md="4">
                        <v-text-field
                          v-model.trim="employee.mobile_no"
                          label="Mobile No."
                        >
                          <v-icon slot="append" color="black">
                            mdi-pencil
                          </v-icon>
                        </v-text-field>
                      </v-col>
                      <v-col cols="12" md="4">
                        <v-text-field
                          v-model.trim="employee.emergency_no"
                          label="Emergency Mobile No."
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="4">
                        <v-file-input
                          ref="file"
                          v-model="profile_img"
                          accept="image/png, image/jpeg, image/bmp"
                          label="Profile Image"
                          truncate-length="25"
                        ></v-file-input>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" md="12">
                        <v-text-field
                          v-model.trim="employee.address"
                          label="Address"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" md="4">
                        <v-text-field
                          v-model.trim="employee.ctc"
                          label="CTC"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="4">
                        <v-text-field
                          v-model.trim="employee.position"
                          label="Position"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="4">
                        <v-checkbox
                          v-model="employee.update_ctc"
                          label="Update CTC ?"
                        ></v-checkbox>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" md="6">
                        <v-text-field
                          v-model.trim="employee.pan_no"
                          label="PAN"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="6">
                        <v-file-input
                          accept="image/png, image/jpeg, image/bmp"
                          v-model="pan_img"
                          label="PAN image"
                          truncate-length="25"
                        ></v-file-input>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" md="6">
                        <v-text-field
                          v-model.trim="employee.aadhar_no"
                          label="Aadhar No."
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="6">
                        <v-file-input
                          accept="image/png, image/jpeg, image/bmp"
                          v-model="aadhar_img"
                          label="Aadhar image"
                          truncate-length="25"
                        ></v-file-input>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" md="4">
                        <v-menu
                          ref="joinMenu"
                          v-model="joinMenu"
                          :close-on-content-click="false"
                          :return-value.sync="employee.joining_date"
                          transition="scale-transition"
                          offset-y
                          min-width="auto"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="employee.joining_date"
                              label="Joining Date"
                              prepend-icon="mdi-calendar"
                              readonly
                              v-bind="attrs"
                              v-on="on"
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            v-model="employee.joining_date"
                            no-title
                            scrollable
                          >
                            <v-spacer></v-spacer>
                            <v-btn
                              text
                              color="primary"
                              @click="joinMenu = false"
                            >
                              Cancel
                            </v-btn>
                            <v-btn
                              text
                              color="primary"
                              @click="
                                $refs.joinMenu.save(employee.joining_date)
                              "
                            >
                              OK
                            </v-btn>
                          </v-date-picker>
                        </v-menu>
                      </v-col>
                      <v-col cols="12" md="4">
                        <v-menu
                          ref="endMenu"
                          v-model="endMenu"
                          :close-on-content-click="false"
                          :return-value.sync="employee.ending_date"
                          transition="scale-transition"
                          offset-y
                          min-width="auto"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="employee.ending_date"
                              label="Ending Date"
                              prepend-icon="mdi-calendar"
                              readonly
                              v-bind="attrs"
                              v-on="on"
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            v-model="employee.ending_date"
                            no-title
                            scrollable
                          >
                            <v-spacer></v-spacer>
                            <v-btn
                              text
                              color="primary"
                              @click="endMenu = false"
                            >
                              Cancel
                            </v-btn>
                            <v-btn
                              text
                              color="primary"
                              @click="$refs.endMenu.save(employee.ending_date)"
                            >
                              OK
                            </v-btn>
                          </v-date-picker>
                        </v-menu>
                      </v-col>
                      <v-col cols="12" md="4">
                        <v-text-field
                          type="number"
                          v-model.number="employee.attendance_id"
                          label="Attendance ID"
                        >
                        </v-text-field>
                      </v-col>
                    </v-row>
                  </v-container>
                  <v-container
                    v-if="
                      accountType === 3 ||
                      accountType === 4 ||
                      accountType === 5 ||
                      accountType === 6
                    "
                  >
                    <v-radio-group v-model="is_primary">
                      <v-row
                        style="border-top: 1px solid #ccc"
                        align="center"
                        justify="space-around"
                        v-for="(item, index) in bank_details"
                        :key="index"
                      >
                        <v-col cols="12" md="1">
                          <v-switch
                            v-model="item.is_active"
                            color="success"
                          ></v-switch>
                        </v-col>

                        <v-col
                          cols="12"
                          md="2"
                          v-if="item.hasOwnProperty('is_remove')"
                        >
                          <v-checkbox
                            v-model="item.is_remove"
                            label="Remove"
                          ></v-checkbox>
                        </v-col>

                        <v-col cols="12" md="2">
                          <v-text-field
                            v-model.trim="item.name"
                            label="Bank Name"
                          >
                          </v-text-field>
                        </v-col>

                        <v-col cols="12" md="3">
                          <v-text-field
                            v-model.trim="item.ac_number"
                            label="A\C No."
                          ></v-text-field>
                        </v-col>

                        <v-col cols="12" :md="4">
                          <v-text-field
                            v-model.trim="item.ifsc"
                            label="IFSC Code"
                          ></v-text-field>
                        </v-col>

                        <v-col cols="12" md="2">
                          <v-radio label="is Primary?" :value="index"></v-radio>
                        </v-col>

                        <v-col cols="12" :md="3">
                          <v-text-field
                            v-model.trim="item.swift_code"
                            label="Swift Code"
                          ></v-text-field>
                        </v-col>

                        <v-col cols="12" :md="7">
                          <v-text-field
                            v-model.trim="item.branch"
                            label="Branch"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-radio-group>
                  </v-container>
                  <v-container
                    v-if="
                      accountType === 3 ||
                      accountType === 4 ||
                      accountType === 5 ||
                      accountType === 6
                    "
                  >
                    <v-col cols="12">
                      <v-btn depressed tile block style="border: 1px solid #ccc" @click="addBankAccount"> + Add Bank </v-btn>
                    </v-col>
                  </v-container>
                </v-card-text>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="close">
                    Cancel
                  </v-btn>
                  <v-btn color="blue darken-1" text @click="save"> Save </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-dialog v-model="dialogDelete" max-width="500px">
              <v-card>
                <v-card-title class="headline"
                  >Are you sure you want to delete this item?</v-card-title
                >
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="closeDelete"
                    >Cancel</v-btn
                  >
                  <v-btn color="blue darken-1" text @click="deleteItemConfirm"
                    >OK</v-btn
                  >
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-toolbar>
          <v-row class="pt-8 px-4">
            <v-col cols="3">
              <v-autocomplete
                v-model.number="filterType"
                :items="getAccountTypeFilter"
                label="Select Account Type"
                filled
              >
              </v-autocomplete>
            </v-col>
            <v-col cols="9">
              <v-text-field
                v-model="search"
                label="Search"
                filled
              ></v-text-field>
            </v-col>
          </v-row>
        </template>
        <template v-slot:[`item.id`]="{ item }">
          <v-radio
            v-if="item.account_id != -1"
            :value="item.account_id"
          ></v-radio>
        </template>
        <template v-slot:[`item.name`]="{ item }">
          <span class="font-weight-medium"> {{ item.name }} </span>
        </template>

        <template v-slot:[`item.update_ctc`]="{ item }">
          <v-row v-if="item.typeId == 5 || item.typeId == 6" align="center">
            <v-chip :color="item.update_ctc ? 'green' : 'red'">
              <v-switch
                v-model="item.update_ctc"
                @click="updateCtc(item)"
                :color="item.update_ctc ? 'green lighten-5' : 'red lighten-5'"
              ></v-switch>
              <span class="ml-2 font-weight-bold white--text">{{
                item.ctc
              }}</span>
            </v-chip>
          </v-row>
        </template>
        <template v-slot:[`item.is_activated`]="{ item }">
          <v-switch
            v-if="item.is_activated != -1"
            v-model="item.is_activated"
            inset
            @click="updateStatus(item)"
            color="info"
          ></v-switch>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-icon small class="mr-2" @click="editItem(item)">
            mdi-pencil
          </v-icon>
          <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon>
        </template>
        <template v-slot:no-data>
          <p>No data available.</p>
        </template>
      </v-data-table>
    </v-radio-group>
    <div class="text-right pt-2" v-if="user.role != 1">
      <v-btn
        color="primary"
        class="mr-2"
        depressed
        tile
        @click="setSalaryTransferAccount"
      >
        Save Account
      </v-btn>
    </div>
  </div>
</template>
 

<script>
import { validationMixin } from "vuelidate";
import { required, maxLength, email } from "vuelidate/lib/validators";
import _ from "lodash";

export default {
  mixins: [validationMixin],
  created() {
    let keyPressed = false;
    document.addEventListener("keydown", (e) => {
      if (e.code == "AltLeft") {
        keyPressed = true;
      }
    });
    document.addEventListener("keyup", (e) => {
      if (keyPressed && e.code == "KeyT") {
        this.dialog = !this.dialog;
      }
      keyPressed = false;
    });
    const user = JSON.parse(window.atob(localStorage.getItem("user")));
    this.user = user;
  },
  beforeCreate() {
    if (
      !this.$permission.userPermissions([
        "add-account",
        "view-account",
        "edit-account",
        "delete-account",
        "view-master-account-page",
      ])
    )
      this.$router.push({ name: "dashboard" });
  },
  beforeMount() {
    this.fetchAccountTypes();
    this.fetchMasterAccounts();
  },

  validations: {
    name: { required, maxLength: maxLength(20) },
    email: { required, email },
    accountType: { required },
    password: { required },
    checkbox: {
      checked(val) {
        return val;
      },
    },
  },

  data: () => ({
    user: null,
    filterType: 0,
    isSalaryAccount: 0,
    is_primary: 0,
    search: "",
    dialog: false,
    dialogDelete: false,
    isLoading: true,
    accountType: null,
    joinMenu: false,
    joiningDate: null,
    endMenu: false,
    endingDate: null,
    masterAccounts: [],
    name: "",
    accountTypes: [],
    email: "",
    editedIndex: -1,
    editedItem: {
      name: "",
      type: 0,
      email: "",
    },
    defaultItem: {
      name: "",
      type: 0,
      email: "",
    },
    showPassword: false,
    password: "",
    dobMenu: false,
    dojMenu: false,
    defaultBank: {
      name: "",
      branch: "",
      ifsc: "",
      acc_number: "",
    },
    bank: {
      name: "",
      branch: "",
      ifsc: "",
      acc_number: "",
    },
    defaultCash: {
      name: "",
    },
    cash: {
      name: "",
    },
    defaultParty: {
      name: "",
      middle_name: "",
      last_name: "",
      party_company_name: "",
      party_company_address: "",
      mobile_no: "",
      email: "",
      pan_no: "",
      gst_no: "",
      ctc: "",
      update_ctc: 0,
      attendance_id: null,
    },
    party: {
      name: "",
      middle_name: "",
      last_name: "",
      party_company_name: "",
      party_company_address: "",
      mobile_no: "",
      email: "",
      pan_no: "",
      gst_no: "",
      ctc: "",
      update_ctc: 0,
      attendance_id: null,
    },
    defaultEmployee: {
      name: "",
      middle_name: "",
      last_name: "",
      email: "",
      dob: null,
      doj: null,
      mobile_no: "",
      emergency_no: "",
      address: "",
      ctc: "",
      position: "",
      pan_no: "",
      aadhar_no: "",
      gender: 1,
      prefix: 1,
      attendance_id: null,
      update_ctc: 0,
    },
    employee: {
      name: "",
      middle_name: "",
      last_name: "",
      email: "",
      dob: null,
      doj: null,
      mobile_no: "",
      emergency_no: "",
      address: "",
      ctc: "",
      position: "",
      pan_no: "",
      aadhar_no: "",
      joining_date: "",
      ending_date: "",
      gender: 1,
      prefix: 1,
      attendance_id: null,
      update_ctc: 0,
    },
    profile_img: null,
    pan_img: null,
    aadhar_img: null,
    default_bank_details: [
      {
        name: "",
        ac_number: "",
        ifsc: "",
        swift_code: "",
        branch: "",
        is_active: true,
      },
    ],
    bank_details: [
      {
        name: "",
        ac_number: "",
        ifsc: "",
        swift_code: "",
        branch: "",
        is_active: true,
      },
    ],
    deleteAccount: null,
  }),

  computed: {
    headers() {
      let data = [
        {
          text: "Account Name",
          align: "start",
          value: "name",
        },
        { text: "CTC", value: "update_ctc" },
        { text: "Type", value: "type" },
      ];
      if (this.user.role != 1) {
        data.unshift({ text: "Salary Account", value: "id", sortable: false });
        data.push({ text: "Status", value: "is_activated" });
      }
      data.push({ text: "Actions", value: "actions", sortable: false });
      return data;
    },
    getAccountTypeFilter() {
      let filterData = this.accountTypes;
      filterData.push({ value: 0, text: "All" });
      return filterData;
    },
    getMasterAccounts() {
      let accounts = this.masterAccounts;
      if (this.filterType) {
        accounts = this.masterAccounts.filter((item) => {
          return item.typeId == this.filterType;
        });
      }
      return _.orderBy(
        accounts,
        ["is_activated", "typeId", "name"],
        ["desc", "asc", "asc"]
      );
    },
    genderArr() {
      return [
        {
          text: "Male",
          value: 1,
        },
        {
          text: "Female",
          value: 2,
        },
      ];
    },
    namePrefix() {
      return [
        {
          text: "Mr.",
          value: 1,
        },
        {
          text: "Mrs.",
          value: 2,
        },
        {
          text: "Miss.",
          value: 3,
        },
      ];
    },
    formTitle() {
      return this.editedIndex === -1 ? "New Account" : "Edit Account";
    },
    passwordErrors() {
      const errors = [];
      if (!this.$v.password.$dirty) return errors;
      !this.$v.password.required && errors.push("Password is required.");
      return errors;
    },

    checkboxErrors() {
      const errors = [];
      if (!this.$v.checkbox.$dirty) return errors;
      !this.$v.checkbox.checked && errors.push("You must agree to continue!");
      return errors;
    },
    selectErrors() {
      const errors = [];
      if (!this.$v.accountType.$dirty) return errors;
      !this.$v.accountType.required && errors.push("Item is required.");
      return errors;
    },
    nameErrors() {
      const errors = [];
      if (!this.$v.name.$dirty) return errors;
      !this.$v.name.maxLength &&
        errors.push("Name must be at most 10 characters long");
      !this.$v.name.required && errors.push("Name is required.");
      return errors;
    },
    emailErrors() {
      const errors = [];
      if (!this.$v.email.$dirty) return errors;
      !this.$v.email.email && errors.push("Must be valid e-mail");
      !this.$v.email.required && errors.push("E-mail is required");
      return errors;
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },
  methods: {
    updateCtc(item) {
      this.$http
        .get(`update-ctc/${item.id})`, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("access_token"),
          },
        })
        .then((response) => {
          this.$toast.success(response.data.data.message.join(", "));
        })
        .catch((error) => {
          console.error(error);
          this.$toast.error("Something went wrong");
        });
    },
    updateStatus(item) {
      this.$http
        .get(`update-status/${item.id}`, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("access_token"),
          },
        })
        .then((response) => {
          this.$toast.success(response.data.data.message.join(", "));
        })
        .catch((error) => {
          console.error(error);
          this.$toast.error("Something went wrong");
        });
    },
    setSalaryTransferAccount() {
      if (this.isSalaryAccount) {
        let formData = new FormData();
        formData.append("account_id", this.isSalaryAccount);
        this.$http
          .post("masteraccounts/salary-transfer-account", formData, {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("access_token"),
            },
          })
          .then((response) => {
            if (response.status == 200) {
              this.$toast.success(response.data.data.message.join(" , "));
            }
          })
          .catch(() => {
            this.$toast.error("Something went wrong");
          });
      } else {
        this.$toast.error("Please select account use for salary transfer");
      }
    },
    addBankAccount() {
      this.bank_details.push({
        name: "",
        ac_number: "",
        ifsc: "",
        is_active: true,
      });
    },
    fetchAccountTypes() {
      this.$http
        .get("account/types", {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("access_token"),
          },
        })
        .then((response) => {
          const accountType = response.data.data.accountType;
          accountType.forEach((element) => {
            this.accountTypes.push({ value: element.id, text: element.name });
          });
        })
        .catch((error) => {
          console.log("error", error.response);
        });
    },

    fetchMasterAccounts() {
      this.$http
        .get("masteraccounts", {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("access_token"),
          },
        })
        .then((response) => {
          const masterAccounts = response.data.data.masterAccounts;
          const salaryAccounts = response.data.data.salaryAccounts;
          this.masterAccounts = [];
          masterAccounts.forEach((element) => {
            this.masterAccounts.push({
              id: element.id,
              name: `${element.name} ${element.middle_name || ""} ${
                element.last_name || ""
              }`,
              type: element.account_type.name,
              typeId: element.account_type_id,
              acc_number: element.acc_number,
              // is_activated: element.account ? element.account.is_activate : 0,
              is_activated: element.account ? element.account.is_activate : -1,
              update_ctc: element.update_ctc,
              ctc: element.ctc || 0,
              account_id: element.account ? element.account.id : -1,
            });
          });

          if (salaryAccounts)
            this.isSalaryAccount = parseInt(salaryAccounts.value);
          this.isLoading = false;
        })
        .catch((error) => {
          console.log("error", error);
          this.$toast.error("Something went wrong");
          this.isLoading = false;
        });
    },

    fetchEditItem(id) {
      this.$http
        .get("masteraccounts/" + id + "/edit", {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("access_token"),
          },
        })
        .then((response) => {
          const editAccount = response.data.data.masterAccount;
          switch (this.accountType) {
            case 1: {
              this.bank = JSON.parse(JSON.stringify(editAccount));
              break;
            }
            case 2:
              this.cash = JSON.parse(JSON.stringify(editAccount));
              break;
            case 3:
            case 4:
            case 5: {
              if (editAccount.bank_account.length > 0) {
                const bankAccount = editAccount.bank_account;
                this.editBankDetails(bankAccount);
              }
              delete editAccount.bank_account;
              this.party = JSON.parse(JSON.stringify(editAccount));
              break;
            }
            case 6: {
              if (editAccount.bank_account.length > 0) {
                const bankAccount = editAccount.bank_account;
                this.editBankDetails(bankAccount);
              }
              delete editAccount.bank_account;
              this.employee = JSON.parse(JSON.stringify(editAccount));
              break;
            }
            default:
              break;
          }
          this.isLoading = false;
        })
        .catch((error) => {
          console.log("error", error);
          this.isLoading = false;
        });
    },

    editBankDetails(item) {
      this.bank_details = [];
      item.forEach((el, index) => {
        this.bank_details.push({
          id: el.id,
          name: el.bank_name,
          ac_number: el.bank_account_number,
          ifsc: el.bank_ifsc_code,
          swift_code: el.swift_code,
          branch: el.branch,
          is_remove: false,
          is_active: el.is_active == 1 ? true : false,
        });
        if (el.is_primary) this.is_primary = index;
      });
    },

    getItemName(item) {
      if (item.typeId == 1 && item.acc_number != null)
        return (
          item.name +
          " (" +
          item.acc_number.substring(item.acc_number.length - 4) +
          ")"
        );
      return item.name;
    },

    editItem(item) {
      this.fetchEditItem(item.id);
      this.accountType = this.accountTypes.find(
        (type) => type.text === item.type
      );
      this.accountType = this.accountType.value;
      this.editedIndex = item.id;
      this.name = item.name;
      this.email = item.email;
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    deleteItem(item) {
      this.editedIndex = item.id;
      this.deleteAccount = item;
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      this.deleteMasterAccount(this.deleteAccount);
      this.masterAccounts.splice(this.editedIndex, 1);
      this.closeDelete();
    },

    close() {
      this.dialog = false;
      this.clear();
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.deleteAccount = null;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    prepareFormData() {
      var data = new FormData();
      switch (this.accountType) {
        case 1: {
          const bankDetails = this.bank;
          if (
            bankDetails.acc_number == "" ||
            bankDetails.name == "" ||
            bankDetails.branch == "" ||
            bankDetails.ifsc == ""
          ) {
            return;
          }
          data.append("name", bankDetails.name);
          if (bankDetails.branch) data.append("branch", bankDetails.branch);
          if (bankDetails.ifsc) data.append("ifsc", bankDetails.ifsc);
          if (bankDetails.acc_number)
            data.append("acc_number", bankDetails.acc_number);

          break;
        }
        case 2:
          if (this.cash.name == "") {
            return;
          }
          data.append("name", this.cash.name);
          break;
        case 3: {
          var partyDetails = this.party;
          if (partyDetails.name == "") {
            return;
          }
          partyDetails = this.cleanData(partyDetails);

          data.append("name", partyDetails.name);
          data.append("middle_name", partyDetails.middle_name);
          data.append("last_name", partyDetails.last_name);
          data.append(
            "party_company_name",
            partyDetails.party_company_name
              ? partyDetails.party_company_name
              : null
          );
          data.append(
            "party_company_address",
            partyDetails.party_company_address
              ? partyDetails.party_company_address
              : null
          );
          data.append("mobile_no", partyDetails.mobile_no);
          data.append("email", partyDetails.email);
          data.append("pan_no", partyDetails.pan_no);
          data.append("gst_no", partyDetails.gst_no);
          data.append("is_primary", this.is_primary);
          data.append("bank_details", JSON.stringify(this.bank_details));
          break;
        }
        case 4:
        case 5: {
          var partAccount = this.party;
          if (partAccount.name == "" || partAccount.email == "") {
            return;
          }
          partAccount = this.cleanData(partAccount);

          data.append("name", partAccount.name);
          data.append("middle_name", partAccount.middle_name);
          data.append("last_name", partAccount.last_name);
          data.append("mobile_no", partAccount.mobile_no);
          data.append("email", partAccount.email);
          data.append("pan_no", partAccount.pan_no);
          data.append("gst_no", partAccount.gst_no);
          data.append("is_primary", this.is_primary);
          if (partAccount.attendance_id) data.append("attendance_id", partAccount.attendance_id || null);
          if (partAccount.ctc) data.append("ctc", partAccount.ctc);
          data.append("bank_details", JSON.stringify(this.bank_details));
          if (this.accountType == 5)
            data.append("update_ctc", partAccount.update_ctc ? 1 : 0);
          break;
        }
        case 6: {
          const employeeDetails = this.employee;
          if (
            employeeDetails.name === "" ||
            employeeDetails.email === "" ||
            employeeDetails.mobile_no === ""
          ) {
            return;
          }
          data.append("profile_img", this.profile_img);
          data.append("name", employeeDetails.name);
          data.append("middle_name", employeeDetails.middle_name);
          data.append("last_name", employeeDetails.last_name);
          data.append("email", employeeDetails.email);
          if (employeeDetails.dob) data.append("dob", employeeDetails.dob);
          if (employeeDetails.doj) data.append("doj", employeeDetails.doj);
          data.append("mobile_no", employeeDetails.mobile_no);
          data.append("emergency_no", employeeDetails.emergency_no);
          data.append("address", employeeDetails.address);
          if (employeeDetails.ctc) data.append("ctc", employeeDetails.ctc);
          data.append("position", employeeDetails.position);
          data.append("pan_no", employeeDetails.pan_no);
          data.append("aadhar_no", employeeDetails.aadhar_no);
          data.append("pan_img", this.pan_img);
          data.append("aadhar_img", this.aadhar_img);
          data.append("is_primary", this.is_primary);
          data.append("joining_date", employeeDetails.joining_date || null);
          data.append("ending_date", employeeDetails.ending_date || null);
          data.append("bank_details", JSON.stringify(this.bank_details));
          data.append("gender", employeeDetails.gender);
          data.append("prefix", employeeDetails.prefix);
          data.append("attendance_id", employeeDetails.attendance_id || null);
          data.append("update_ctc", employeeDetails.update_ctc ? 1 : 0);
          break;
        }
        default:
          break;
      }
      data.append("account_type_id", this.accountType);
      return data;
    },

    cleanData(data, exceptkeys = {}) {
      for (const property in data) {
        data[property] =
          data[property] != null && data[property] != "" ? data[property] : "";
      }
      return data;
    },

    save() {
      this.$v.$touch();
      var data = this.prepareFormData();
      if (this.editedIndex > -1) {
        this.updateAccount(data);
      } else {
        this.saveAccount(data);
      }
    },

    saveAccount(data) {
      this.$http
        .post("masteraccounts", data, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("access_token"),
          },
        })
        .then((response) => {
          if (response.data.status === 200) {
            this.close();
            this.clear();
            this.fetchMasterAccounts();
          }
          this.$toast.success(response.data.data.message[0]);
        })
        .catch((error) => {
          this.$toast.error("Something went wrong");
          console.log(error);
        });
    },

    updateAccount(data) {
      data.append("_method", "PUT");
      this.$http
        .post("masteraccounts/" + this.editedIndex, data, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("access_token"),
          },
        })
        .then((response) => {
          if (response.data.status === 200) {
            this.close();
            this.clear();
            this.fetchMasterAccounts();
          }
          this.$toast.success(response.data.data.message[0]);
        })
        .catch((error) => {
          this.$toast.error("Something went wrong");
          console.log(error);
        });
    },

    deleteMasterAccount(item) {
      this.$http
        .delete("masteraccounts/" + item.id, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("access_token"),
          },
        })
        .then((response) => {
          this.fetchMasterAccounts();
          this.$toast.success(response.data.data.message[0]);
        })
        .catch((error) => {
          this.$toast.error("Something went wrong");
          console.log(error);
        });
    },
    clear() {
      this.bank = JSON.parse(JSON.stringify(this.defaultBank));
      this.cash = JSON.parse(JSON.stringify(this.defaultCash));
      this.party = JSON.parse(JSON.stringify(this.defaultParty));
      this.employee = JSON.parse(JSON.stringify(this.defaultEmployee));
      this.bank_details = JSON.parse(JSON.stringify(this.default_bank_details));
      this.is_primary = 0;
      this.profile_img = null;
      this.joiningDate = null;
      this.endingDate = null;
      this.pan_img = null;
      this.aadhar_img = null;
      this.$v.$reset();
    },
  },
};
</script>
