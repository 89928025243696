<template>
  <div class="mr-4 ml-4 mt-4">
    <v-data-table
      :headers="headers"
      :loading="tableLoading"
      :footer-props="{ 'items-per-page-options': [10, 20, 30, 40, 50, -1] }"
      :items-per-page="50"
      :items="expenses"
      class="elevation-1"
      :search="search"
    >
      <template v-slot:top>
        <v-toolbar color="grey lighten-3" flat>
          <v-toolbar-title>Expenses</v-toolbar-title>
          <v-divider class="mx-2" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            dark
            tile
            class="mb-2 mr-2"
            href="/expense"
            v-if="exId"
            >All Expense</v-btn
          >
          <v-dialog v-model="dialog" max-width="50%">
            <template v-slot:activator="{ on }" v-if="![1, 4].includes(user.role)">
              <v-btn color="green" dark tile class="mb-2" v-on="on"
                >+ Add Expense</v-btn
              >
            </template>
            <v-card>
              <v-card-title>
                <span class="headline">{{ formTitle }}</span>
              </v-card-title>

              <v-card-text>
                <v-form>
                  <v-container grid-list-md>
                    <p class="font-weight-bold">Expense from</p>
                    <v-autocomplete
                      dense
                      filled
                      v-model="accountType"
                      :items="accountTypes"
                      :error-messages="accountTypeErrors"
                      label="Select Account Type"
                      @input="fetchTypedAccounts(accountType)"
                      required
                    ></v-autocomplete>
                    <v-autocomplete
                      dense
                      filled
                      v-model="account"
                      :items="accounts"
                      @change="prepareBankAccount"
                      label="Select Account"
                      required
                    ></v-autocomplete>
                    <v-autocomplete
                      dense
                      filled
                      v-model="expenseType"
                      :items="expenseTypes"
                      label="Select Expense Type"
                      required
                    ></v-autocomplete>

                    <!-- RADIO -->
                    <v-row>
                      <v-col cols="12" md="12">
                        <v-radio-group
                          v-model="transactionType"
                          :mandatory="false"
                          class="ml-4 mr-4"
                          @change="clearRadioInputs"
                        >
                          <v-layout>
                            <v-flex>
                              <v-radio
                                label="Cash"
                                :value="0"
                                color="blue"
                              ></v-radio>
                            </v-flex>
                            <v-flex>
                              <v-radio
                                label="cheque"
                                :value="1"
                                color="blue"
                              ></v-radio>
                            </v-flex>
                            <v-flex>
                              <v-radio
                                label="Transfer"
                                :value="2"
                                color="blue"
                              ></v-radio>
                            </v-flex>
                            <v-flex>
                              <v-radio
                                label="Forex"
                                :value="3"
                                color="blue"
                              ></v-radio>
                            </v-flex>
                          </v-layout>
                        </v-radio-group>
                      </v-col>
                    </v-row>

                    <!-- chequeShow -->
                    <v-row v-if="transactionType == 1">
                      <v-col cols="12" md="4">
                        <v-text-field
                          v-model="chequeNo"
                          label="Cheque No"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="4">
                        <v-select
                          v-model="bankName"
                          :items="bankNameList"
                          label="Select Bank Account"
                        ></v-select>
                      </v-col>
                      <v-col cols="12" md="4">
                        <v-menu
                          v-model="chequeMenu"
                          :close-on-content-click="false"
                          :nudge-right="40"
                          transition="scale-transition"
                          offset-y
                          min-width="auto"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="depositedDate"
                              label="Deposited Date"
                              v-mask="'####-##-##'"
                              hint="YYYY-MM-DD format"
                              persistent-hint
                              prepend-icon="mdi-calendar"
                              v-bind="attrs"
                              @blur="depositedDate = parseDate(depositedDate)"
                              v-on="on"
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            v-model="depositedDate"
                            no-title
                            @input="chequeMenu = false"
                          ></v-date-picker>
                        </v-menu>
                      </v-col>
                    </v-row>

                    <!-- tfShow -->
                    <v-row v-else-if="transactionType == 2">
                      <v-col cols="12" md="6">
                        <v-text-field
                          v-model="transferNo"
                          label="Transfer No"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="6">
                        <v-select
                          v-model="bankName"
                          :items="bankNameList"
                          label="Select Bank Account"
                        ></v-select>
                      </v-col>
                    </v-row>

                    <!-- forexShow -->
                    <v-row v-else-if="transactionType == 3">
                      <v-col cols="12" md="6">
                        <v-text-field
                          v-model="transferNo"
                          label="Forex Transfer No"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="6">
                        <v-select
                          v-model="bankName"
                          :items="bankNameList"
                          label="Select Bank Account"
                        ></v-select>
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col cols="12" md="4">
                        <v-text-field
                          label="Amount"
                          v-model.number="amount"
                          prefix="₹"
                          type="number"
                          min="0"
                          :error-messages="amountErrors"
                          @change="$v.amount.$touch()"
                          @blur="$v.amount.$touch()"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="4">
                        <v-file-input
                          accept="image/png, image/jpeg, image/bmp, application/pdf"
                          v-model="invoiceImg"
                          label="Invoice Image"
                          counter
                          truncate-length="25"
                        ></v-file-input>
                      </v-col>
                      <v-col cols="12" md="4">
                        <v-menu
                          v-model="menu"
                          :close-on-content-click="false"
                          :nudge-right="40"
                          transition="scale-transition"
                          offset-y
                          min-width="auto"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="date"
                              label="Date"
                              v-mask="'####-##-##'"
                              hint="YYYY-MM-DD format"
                              persistent-hint
                              prepend-icon="mdi-calendar"
                              v-bind="attrs"
                              @blur="date = parseDate(date)"
                              v-on="on"
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            v-model="date"
                            no-title
                            @input="menu = false"
                          ></v-date-picker>
                        </v-menu>
                      </v-col>

                      <v-row>
                        <v-col cols="12" md="12">
                          <v-textarea
                            v-model="note"
                            outlined
                            name="note"
                            label="note"
                            clearable
                          ></v-textarea>
                        </v-col>
                      </v-row>
                    </v-row>
                  </v-container>
                </v-form>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="close">Cancel</v-btn>
                <v-btn
                  color="blue darken-1"
                  text
                  :loading="loadingExpenseSaveButton"
                  @click="save"
                  >Save</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
              <v-card-title class="headline"
                >Are you sure you want to delete this item?</v-card-title
              >
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="closeDelete"
                  >Cancel</v-btn
                >
                <v-btn color="blue darken-1" text @click="deleteItemConfirm"
                  >OK</v-btn
                >
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
        <v-row v-if="!exId" align="center" class="pa-4">
          <v-col cols="12" md="2">
            <v-autocomplete
              :items="masterAccounts"
              v-model="fromFilterType"
              label="Account"
            >
            </v-autocomplete>
          </v-col>
          <v-col cols="12" md="2">
            <v-autocomplete
              :items="expenseTypes"
              v-model="expenseTypeFilter"
              label="Expense Type"
            >
            </v-autocomplete>
          </v-col>
          <v-col cols="12" md="2">
            <v-dialog
              ref="dialog"
              v-model="filterModal"
              :return-value.sync="dates"
              persistent
              width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="dateRangeText"
                  label="Select date range"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker v-model="dates" range scrollable>
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="filterModal = false">
                  Cancel
                </v-btn>
                <v-btn text color="primary" @click="$refs.dialog.save(dates)">
                  OK
                </v-btn>
              </v-date-picker>
            </v-dialog>
          </v-col>
          <v-col cols="12" md="2">
            <v-btn
              class="ma-2 white--text"
              color="blue-grey"
              small
              tile
              depressed
              @click="initialize"
              :loading="tableLoading"
            >
              Search
            </v-btn>
            <v-btn
              class="ma-2"
              color="blue-grey"
              dark
              tile
              depressed
              small
              outlined
              @click="resetFilterValue"
            >
              Reset
            </v-btn>
          </v-col>
        </v-row>
        <v-text-field
          v-if="!exId"
          v-model="search"
          label="Search"
          class="mx-4 mt-n8"
        ></v-text-field>
      </template>
      <template v-slot:[`item.invoiceImg`]="{ item }">
        <a
          :href="item.invoiceImg"
          v-if="item.invoiceImg"
          target="_blank"
          class="invoice-link"
        >
          <i class="v-icon notranslate mdi mdi-link-variant"></i>
          {{ item.invoiceOriginalName || "Invoice" }}
        </a>
        <i
          v-if="item.invoiceImg"
          class="mdi mdi-delete ml-4 cursor-pointer"
          @click="removeInvoice(item)"
        ></i>
      </template>
      <template v-slot:[`item.type`]="{ item }">
        {{ getTransactionType(item.type) }}
      </template>
      <template v-slot:[`item.expenseType`]="{ item }">
        {{ getExpenseType(item.expenseType) }}
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-icon small class="mr-2" @click="editItem(item)"> mdi-pencil </v-icon>
        <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon>
      </template>
      <template v-slot:[`item.date`]="{ item }">
        <span class="font-weight-medium"> {{ item.date }} </span>
      </template>
      <template v-slot:[`item.amount`]="{ item }">
        <v-chip>{{ item.amount }}</v-chip>
      </template>
      <template v-slot:no-data>
        <p>No data available.</p> </template
      >font-weight-bold
      <template v-slot:body.append>
        <tr class="grey lighten-5">
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td>
            <v-chip color="green" text-color="white">{{
              totalExpenses
            }}</v-chip>
          </td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
      </template>
    </v-data-table>
    <div class="text-center pt-2">
      <v-row justify="end" align="center" class="pa-4">
        <v-btn
          class="auto ml-4"
          depressed
          tile
          color="info"
          v-if="expenses.length > 0"
          :loading="loadingExport"
          @click="exportExpense"
          >Export</v-btn
        >
      </v-row>
    </div>
    <v-overlay style="z-index: 9999" :value="overlay">
      <v-progress-circular indeterminate size="32"></v-progress-circular>
    </v-overlay>
  </div>
</template>
<script>
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
export default {
  props: ["exId"],
  mixins: [validationMixin],
  validations: {
    account: { required },
    accountType: { required },
    transactionType: { required },
    amount: { required },
  },
  beforeCreate() {
    if (!this.$permission.userPermissions(["view-expenses-page"]))
      this.$router.push({ name: "dashboard" });
  },
  async beforeMount() {
    await this.fetchAccountTypes();
    await this.fetchExpenseTypes();
    if (this.exId) {
      this.expenses = this.expenses.filter((item) => {
        this.date = "";
        if (item.id === parseInt(this.exId)) {
          return item;
        }
      });
    }
  },
  data: () => ({
    user: null,
    loadingExpenseSaveButton: false,
    overlay: false,
    checkDate: 1,
    search: "",
    dialog: false,
    headers: [
      { text: "Date", value: "date" },
      { text: "User", value: "user_name" },
      {
        text: "From Account",
        align: "start",
        value: "accountName",
      },
      { text: "Transaction Type", value: "type" },
      { text: "Expense Type", value: "expenseType" },
      { text: "Amount", value: "amount" },
      { text: "Notes", value: "notes" },
      { text: "Invoice", value: "invoiceImg" },
      { text: "Actions", value: "actions", sortable: false },
    ],
    editedIndex: -1,
    accountTypes: [],
    accountType: null,
    accounts: [],
    account: null,
    expenseTypes: [],
    expenseType: null,
    chequeNo: "",
    bankName: null,
    bankNameList: [],
    bankDetails: [],
    chequeMenu: false,
    depositedDate: "",
    transferNo: "",
    amount: null,
    invoiceImg: null,
    note: "",
    expenses: [],
    chequeShow: false,
    tfShow: false,
    forexShow: false,
    transactionType: 0,
    date: new Date().toISOString().substr(0, 10),
    menu: false,
    modal: false,
    menu2: false,
    dialogDelete: false,
    deleteAccount: null,
    dates: [],
    filterModal: false,
    tableLoading: false,
    loadingExport: false,
    masterAccounts: [],
    fromFilterType: 0,
    expenseTypeFilter: 0,
  }),

  computed: {
    accountTypeErrors() {
      const errors = [];
      if (!this.$v.accountType.$dirty) return errors;
      !this.$v.accountType.required && errors.push("Account is required");
      return errors;
    },
    transactionErrors() {
      const errors = [];
      if (!this.$v.transactionType.$dirty) return errors;
      !this.$v.transactionType.required &&
        errors.push("Transaction Type is required");
      return errors;
    },
    amountErrors() {
      const errors = [];
      if (!this.$v.amount.$dirty) return errors;
      !this.$v.amount.required && errors.push("Amount must be greater than 0");
      return errors;
    },
    formTitle() {
      return this.editedIndex === -1 ? "Add Expense" : "Edit Expense";
    },
    dateRangeText() {
      return this.dates.join(" ~ ");
    },
    totalExpenses() {
      let amount = 0;
      this.expenses.forEach((value) => [(amount += value.amount)]);

      return amount;
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  async created() {
    let keyPressed = false;
    document.addEventListener("keydown", (e) => {
      if (e.code == "AltLeft") {
        keyPressed = true;
      }
    });
    document.addEventListener("keyup", (e) => {
      if (keyPressed && e.code == "KeyT") {
        this.dialog = !this.dialog;
      }
      keyPressed = false;
    });
    if (this.exId) this.checkDate = 0;
    const user = JSON.parse(window.atob(localStorage.getItem("user")));
    this.user = user;
    await this.initialize();
    this.fetchMasterAccounts();
  },

  methods: {
    resetFilterValue() {
      this.fromFilterType = 0;
      this.expenseTypeFilter = 0;
      this.dates = [];
      this.initialize();
    },
    fetchMasterAccounts() {
      this.$http
        .get("masteraccounts", {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("access_token"),
          },
        })
        .then((response) => {
          const masterAccounts = response.data.data.masterAccounts;
          this.masterAccounts = [];
          masterAccounts.forEach((element) => {
            if (element.account) {
              this.masterAccounts.push({
                value: element.account.id,
                text: `${element.name} ${element.middle_name || ""} ${
                  element.last_name || ""
                }`,
              });
            }
          });
        })
        .catch((error) => {
          console.log("error", error);
          this.$toast.error("Something went wrong");
        });
    },
    exportExpense() {
      this.loadingExport = true;
      this.$http
        .get("expense-export", {
          params: {
            0: this.dates,
            checkDate: this.checkDate,
            ex_id: this.exId || 0,
            fromId: this.fromFilterType,
            expenseType: this.expenseTypeFilter,
          },
          headers: {
            Authorization: "Bearer " + localStorage.getItem("access_token"),
          },
          responseType: "blob",
        })
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          const fileName = this.dates.join("_to_") + "_expenses.xlsx";
          link.setAttribute("download", fileName.replace(/-/g, "_"));
          document.body.appendChild(link);
          link.click();
          this.loadingExport = false;
        })
        .catch((error) => {
          console.log("error", error.response);
          this.$toast.error("Something went wrong");
          this.loadingExport = false;
        });
    },
    getItemName(item) {
      if (item.account_type_id == 1 && item.acc_number != null)
        return (
          item.name +
          " (" +
          item.acc_number.substring(item.acc_number.length - 4) +
          ")"
        );
      return item.name;
    },
    prepareBankAccount() {
      this.bankName = null;
      this.bankNameList = [];
      if (this.bankDetails.length > 0) {
        let bankNameArr = this.bankDetails.find(
          (item) => item.id === this.account
        );

        if (bankNameArr) {
          this.bankNameList = bankNameArr.bankName.map((el) => {
            if (!this.bankName) {
              this.bankName = el.is_primary === 1 ? el.id : null;
            }
            return {
              text: `${el.bank_name} (${el.bank_account_number.substring(
                el.bank_account_number.length - 4
              )})`,
              value: el.id,
            };
          });
        }
      }
    },
    async initialize() {
      this.tableLoading = true;
      await this.$http
        .get("expenses", {
          params: {
            0: this.dates,
            checkDate: this.checkDate,
            ex_id: this.exId || 0,
            fromId: this.fromFilterType,
            expenseType: this.expenseTypeFilter,
          },
          headers: {
            Authorization: "Bearer " + localStorage.getItem("access_token"),
          },
        })
        .then((response) => {
          const expenses = response.data.data.expenses;
          this.expenses = [];
          expenses.forEach((element) => {
            this.expenses.push({
              id: element.id,
              user_name: element.user ? element.user.name : "(User Deleted)",
              accountName: this.getItemName(element.account.master_account),
              account: element.account_id,
              accountType: element.account.account_type_id,
              expenseType: element.expense_type_id,
              type: element.transaction_type,
              date: element.date,
              amount: element.amount,
              notes: element.notes,
              bankName: element.bank_name || null,
              chequeNo: element.cheque_no || "",
              transferNo: element.transfer_no || "",
              depositedDate: element.cheque_deposited_date || "",
              invoiceImg: element.full_invoice_path,
              invoiceOriginalName: element.invoice_original_name,
            });
          });
          this.tableLoading = false;
        })
        .catch((error) => {
          console.log("error", error.response);
          this.tableLoading = false;
        });
    },

    async fetchAccountTypes() {
      await this.$http
        .get("account/types", {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("access_token"),
          },
        })
        .then((response) => {
          this.accountTypes = [];
          const accountType = response.data.data.accountType;
          accountType.forEach((element) => {
            this.accountTypes.push({ value: element.id, text: element.name });
          });
        })
        .catch((error) => {
          console.log("error", error.response);
        });
    },

    async fetchExpenseTypes() {
      await this.$http
        .get("expense/types", {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("access_token"),
          },
        })
        .then((response) => {
          this.expenseTypes = [];
          const expenseTypes = response.data.data.expenseType;
          expenseTypes.forEach((element) => {
            this.expenseTypes.push({ value: element.id, text: element.name });
          });
        })
        .catch((error) => {
          console.log("error", error.response);
        });
    },

    async fetchTypedAccounts(type) {
      const data = {
        accountTypeId: type,
        isExpense: true,
      };
      await this.$http
        .post("typed/accounts", data, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("access_token"),
          },
        })
        .then((response) => {
          const accountType = response.data.data.accounts;
          this.accounts = [];
          this.bankDetails = [];
          accountType.forEach((element) => {
            this.accounts.push({
              value: element.id,
              text: element.master_account.name,
            });
            if (element.master_account.bank_account.length > 0) {
              this.bankDetails.push({
                id: element.id,
                bankName: element.master_account.bank_account,
              });
            }
          });
        })
        .catch((error) => {
          console.log("error", error.response);
        });
    },

    async editItem(item) {
      this.overlay = true;
      this.editedIndex = item.id;
      this.account = item.account;
      this.accountType = this.accountTypes.find(
        (type) => type.value === item.accountType
      );
      await this.fetchTypedAccounts(item.accountType);
      this.expenseType = this.expenseTypes.find(
        (type) => type.value === item.expenseType
      )?.value;
      this.amount = item.amount;
      this.note = item.notes;
      this.date = item.date;
      this.transactionType = item.type;
      if (item.chequeNo != "") this.chequeNo = item.chequeNo;
      if (item.depositedDate != "") this.depositedDate = item.depositedDate;
      if (item.transferNo != "") this.transferNo = item.transferNo;
      this.prepareBankAccount();
      if (item.bankName != null) this.bankName = item.bankName;
      this.overlay = false;
      this.dialog = true;
    },
    deleteItem(item) {
      this.editedIndex = item.id;
      this.deleteAccount = item;
      this.dialogDelete = true;
    },
    close() {
      this.dialog = false;
      this.clear();
      this.$nextTick(() => {
        this.editedIndex = -1;
      });
    },
    closeDelete() {
      this.dialogDelete = false;
      this.deleteAccount = null;
      this.$nextTick(() => {
        this.editedIndex = -1;
      });
    },
    deleteItemConfirm() {
      this.deleteTransaction(this.deleteAccount);
      this.closeDelete();
    },
    save() {
      this.loadingExpenseSaveButton = true;
      var data = new FormData();
      data.append("account_id", this.account);
      data.append("expense_type_id", this.expenseType);
      data.append("transaction_type", this.transactionType);
      data.append("amount", this.amount);
      if (this.invoiceImg != null) data.append("invoice_img", this.invoiceImg);
      data.append("date", this.date);
      data.append("notes", this.note);
      if (this.chequeNo != "") data.append("cheque_no", this.chequeNo);
      if (this.transactionType != 0 && this.bankName != null)
        data.append("bank_name", this.bankName);
      if (this.depositedDate != "")
        data.append("cheque_deposited_date", this.depositedDate);
      if (this.transferNo != "") data.append("transfer_no", this.transferNo);

      if (this.editedIndex > -1) {
        this.updateExpense(data);
      } else {
        this.saveExpense(data);
      }
    },
    saveExpense(data) {
      this.$http
        .post("expenses", data, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("access_token"),
          },
        })
        .then((response) => {
          if (response.data.status === 200) {
            this.close();
            this.initialize();
            this.$toast.success(response.data.data.message[0]);
          }
          this.loadingExpenseSaveButton = false;
        })
        .catch((error) => {
          this.$toast.error("Something went wrong");
          console.log("error", error.response);
          this.loadingExpenseSaveButton = false;
        });
    },
    updateExpense(data) {
      data.append("_method", "PUT");
      this.$http
        .post("expenses/" + this.editedIndex, data, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("access_token"),
          },
        })
        .then((response) => {
          if (response.data.status === 200) {
            this.close();
            this.clear();
            this.initialize();
            this.$toast.success(response.data.data.message[0]);
          }
          this.loadingExpenseSaveButton = false;
        })
        .catch((error) => {
          console.log(error);
          this.$toast.error("Something went wrong");
          this.loadingExpenseSaveButton = false;
        });
    },
    clearRadioInputs() {
      this.chequeNo = "";
      this.depositedDate = "";
      this.transferNo = "";
    },
    deleteTransaction(item) {
      this.$http
        .delete("expenses/" + item.id, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("access_token"),
          },
        })
        .then((response) => {
          this.initialize();
          this.$toast.success(response.data.data.message[0]);
        })
        .catch((error) => {
          this.$toast.error("Something went wrong");
          console.log(error);
        });
    },

    getTransactionType(id) {
      if (id === 0) return "Cash";
      if (id === 1) return "Cheque";
      if (id === 2) return "Transfer";
      if (id === 3) return "Forex";
    },
    getExpenseType(id) {
      const type = this.expenseTypes.find((type) => type.value == id);
      return type ? type.text : "";
    },

    clear() {
      (this.accountType = null),
        (this.expenseType = null),
        (this.accounts = []),
        (this.account = null);
      this.chequeNo = "";
      this.bankName = null;
      this.bankNameList = [];
      this.chequeMenu = false;
      this.depositedDate = "";
      this.transferNo = "";
      this.amount = null;
      this.invoiceImg = null;
      this.note = "";
      this.$v.$reset();
    },

    removeInvoice(item) {
      this.$http
        .post("expense/remove-invoice", item, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("access_token"),
          },
        })
        .then((response) => {
          if (response.data.status === 200) {
            this.initialize();
            this.$toast.success(response.data.data.message[0]);
          }
        })
        .catch((error) => {
          console.log(error);
          this.$toast.error("Something went wrong");
        });
    },
  },
};
</script>
