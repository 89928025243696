<template>
  <v-app id="inspire">
    <v-navigation-drawer v-model="drawer" app>
      <sidebar></sidebar>
    </v-navigation-drawer>

    <v-app-bar app flat>
      <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>
      <v-toolbar-title></v-toolbar-title>
      <v-text-field
        class="mb-n6 mr-4 rounded-0"
        label="Search..."
        dense
        solo
        v-model="masterSearch"
      ></v-text-field>
      <v-select
        class="mb-n6 rounded-0"
        dense
        solo
        v-model="selectYear"
        :items="getYearList"
        item-text="text"
        item-value="value"
        label="Select Year"
      ></v-select>
      <v-btn
        class="ml-4 primary"
        :loading="loadingSearch"
        dense
        tile
        @click="fetchMasterSearch"
        :disabled="disableSearch"
      >
        Search
      </v-btn>
      <v-row class="text-left menu">
        <v-menu bottom min-width="200px" rounded offset-y>
          <template v-slot:activator="{ on }">
            <v-btn icon x-large v-on="on">
              <v-avatar color="brown" size="48">
                <span class="white--text headline">{{ user.initials }}</span>
              </v-avatar>
            </v-btn>
          </template>
          <v-card>
            <v-list-item-content class="justify-center">
              <div class="mx-auto text-center">
                <v-avatar color="brown">
                  <span class="white--text headline">{{ user.initials }}</span>
                </v-avatar>
                <h3>{{ user.fullName }}</h3>
                <p class="caption mt-1">
                  {{ user.email }}
                </p>
                <v-divider v-if="user.role == 3" class="my-3"></v-divider>
                <v-btn
                  v-if="user.role == 3"
                  depressed
                  text
                  @click="fetchAttachUserDetails"
                >
                  Switch Account
                </v-btn>

                <template v-if="this.$permission.userPermissions(['view-company-profile'])">
                  <v-divider class="my-3"></v-divider>
                  <v-btn depressed text @click="fetchCompanyDetails" >
                    Company Profile
                  </v-btn>
                </template>

                <template v-if="this.$permission.userPermissions(['view-working-days'])">
                  <v-divider class="my-3"></v-divider>
                  <v-btn :to="{ name: 'Setting' }" depressed text>
                    Working Days
                  </v-btn>
                </template>

                
                <v-divider v-if="isAccess()" class="my-3"></v-divider>
                <!-- :href="`${$apiURL}database/export`" -->
                <v-btn v-if="isAccess()" @click="downloadDB" depressed text>
                  Export DB
                </v-btn>
                <v-divider v-if="isAccess()" class="my-3"></v-divider>
                <v-btn v-if="isAccess()" @click="fetchAccessKey" depressed text>
                  Access Key
                </v-btn>
                <v-divider v-if="isAccess()" class="my-3"></v-divider>
                <v-btn
                  v-if="isAccess()"
                  @click="fetchCommandDetails()"
                  depressed
                  text
                >
                  Run Command
                </v-btn>
                <v-divider v-if="isAccess()" class="my-3"></v-divider>
                <!-- :href="`${$apiURL}database/export`" -->
                <v-btn
                  v-if="isAccess()"
                  @click="$refs.googleDrive.getRootFolder()"
                  depressed
                  text
                >
                  My Google Drive
                </v-btn>
                <v-divider class="my-3"></v-divider>
                <v-btn depressed text @click="changePasswordModal = true">
                  Change Password
                </v-btn>

                <template v-if="this.$permission.userPermissions(['view-salary-setting'])">
                  <v-divider class="my-3"></v-divider>
                  <v-btn depressed text :to="{ name: 'SalarySetting' }">
                    Salary Setting
                  </v-btn>
                </template>
                
                <v-divider class="my-3"></v-divider>
                <v-btn depressed text @click="logout"> Logout </v-btn>
              </div>
            </v-list-item-content>
          </v-card>
        </v-menu>
      </v-row>
    </v-app-bar>
    <transition>
      <router-view></router-view>
    </transition>
    <v-overlay style="z-index: 9999" :value="overlay">
      <v-progress-circular indeterminate size="32"></v-progress-circular>
    </v-overlay>
    <v-bottom-sheet v-model="sheet">
      <v-list>
        <v-subheader>
          <!-- :href="`${$apiURL}search/export?key=${masterSearch}`" -->
          <v-btn
            depressed
            tile
            color="success"
            :loading="loadingExport"
            @click="exportSearch"
          >
            Export
          </v-btn>
        </v-subheader>
        <v-subheader v-if="transactions.length > 0 || expenses.length > 0">
          {{ transactions.length + expenses.length }} Results found for "{{
            masterSearch
          }}"</v-subheader
        >
        <v-subheader v-else
          >No record found for "{{ masterSearch }}"</v-subheader
        >
        <hr />
        <v-list-item>
          <v-list-item-title> <b>Type</b> </v-list-item-title>
          <v-list-item-title class="cursor-pointer" @click="filterDate">
            <b>Date</b>
            <small class="badge">{{
              this.dateSorted == 1 ? "DESC" : "ASC"
            }}</small>
          </v-list-item-title>
          <v-list-item-title><b>From Account</b></v-list-item-title>
          <v-list-item-title> <b>To Account</b> </v-list-item-title>
          <v-list-item-title><b>Amount</b></v-list-item-title>
          <v-list-item-title><b>Transaction</b></v-list-item-title>
          <v-list-item-title><b>Purpose</b></v-list-item-title>
          <v-list-item-title><b>Notes</b></v-list-item-title>
        </v-list-item>
        <hr />
        <v-list-item
          v-for="item in transactions"
          :key="-item.id"
          @click="goToTransaction(item, 1)"
        >
          <v-list-item-title> <b>Transaction</b> </v-list-item-title>
          <v-list-item-title v-html="item.date"></v-list-item-title>
          <v-list-item-title
            v-html="getFullName(item.from_account.master_account)"
          ></v-list-item-title>
          <v-list-item-title
            v-html="getFullName(item.to_account.master_account)"
          ></v-list-item-title>
          <v-list-item-title v-html="item.amount"></v-list-item-title>
          <v-list-item-title>{{
            getTransactionType(item.transaction_type)
          }}</v-list-item-title>
          <v-list-item-title>{{
            getPurposeType(item.purpose_type)
          }}</v-list-item-title>
          <v-list-item-title
            v-html="searchHighlight(item.notes)"
          ></v-list-item-title>
        </v-list-item>
        <v-list-item
          v-for="item in expenses"
          :key="item.id"
          @click="goToTransaction(item, 2)"
        >
          <v-list-item-title> <b>Expense</b> </v-list-item-title>
          <v-list-item-title v-html="item.date"></v-list-item-title>
          <v-list-item-title
            v-html="getFullName(item.account.master_account)"
          ></v-list-item-title>
          <v-list-item-title> - </v-list-item-title>
          <v-list-item-title v-html="item.amount"></v-list-item-title>
          <v-list-item-title> - </v-list-item-title>
          <v-list-item-title> - </v-list-item-title>
          <v-list-item-title
            v-html="searchHighlight(item.notes)"
          ></v-list-item-title>
        </v-list-item>
        <hr />
        <v-list-item>
          <v-list-item-title></v-list-item-title>
          <v-list-item-title></v-list-item-title>
          <v-list-item-title></v-list-item-title>
          <v-list-item-title></v-list-item-title>
          <v-list-item-title
            >Total: <b>{{ totalAmount }}</b>
          </v-list-item-title>
          <v-list-item-title></v-list-item-title>
          <v-list-item-title></v-list-item-title>
          <v-list-item-title></v-list-item-title>
        </v-list-item>
      </v-list>
    </v-bottom-sheet>
    <v-row justify="center">
      <v-dialog v-model="editDialog" persistent max-width="600px">
        <v-card>
          <v-card-title>
            <span class="text-h5">Company Profile</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    label="Company Name"
                    required
                    v-model="name"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-file-input
                    accept="image/png, image/jpeg"
                    v-model="companyLogo"
                    label="Company Logo"
                    counter
                    truncate-length="25"
                  ></v-file-input>
                </v-col>
                <v-col cols="12" align="center">
                  <img
                    :src="companyLogoPath"
                    width="100"
                    style="border: 1px solid #1c1c1c"
                    alt=""
                  />
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    label="Address"
                    v-model="address"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    label="Address"
                    v-model="address2"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field label="City" v-model="city"></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field label="State" v-model="state"></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    label="Country"
                    v-model="country"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field label="PAN No" v-model="pan_no"></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field label="TAN No" v-model="tan_no"></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field label="P.F. No." v-model="pf_no"></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    label="E.S.I.C. No."
                    v-model="esic_no"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    label="Contact No. 1"
                    v-model="contact_1"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    label="Contact No. 2"
                    v-model="contact_2"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    label="Website"
                    v-model="website"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    label="Company Email"
                    v-model="company_email"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" align="center">
                  <v-text-field
                    label="TDS (%)"
                    v-model.number="tds_percentage"
                    type="number"
                    min="0"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-file-input
                    accept="image/png, image/jpeg"
                    v-model="letterImg"
                    label="Experience letter Image"
                    counter
                    truncate-length="25"
                  ></v-file-input>
                </v-col>
                <v-col cols="12" align="center">
                  <img
                    :src="letterImgPath"
                    width="100"
                    style="border: 1px solid #1c1c1c"
                    alt=""
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="editDialog = false">
              Close
            </v-btn>
            <v-btn
              color="blue darken-1"
              :loading="saveCompanyDetailsLoading"
              text
              @click="saveCompanyDetails"
            >
              Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
    <v-row justify="center">
      <v-dialog v-model="changePasswordModal" persistent max-width="600px">
        <v-card>
          <v-card-title>
            <span class="text-h5">Change Password</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    label="Current Password"
                    type="password"
                    required
                    v-model="currentPwd"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    label="New Password"
                    type="password"
                    required
                    v-model="newPwd"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    label="Confirm New Password"
                    type="password"
                    required
                    v-model="confNewPwd"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="blue darken-1"
              text
              @click="changePasswordModal = false"
            >
              Close
            </v-btn>
            <v-btn color="blue darken-1" text @click="changePassword">
              Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
    <v-row justify="center">
      <v-dialog v-model="accessKeyModal" persistent max-width="600px">
        <v-card>
          <v-card-title>
            <span class="text-h5">Access Key</span>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="accessKeyModal = false">
              Close
            </v-btn>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    required
                    disabled
                    v-model="accessKey"
                    id="accessKeyInput"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="green"
              dark
              depressed
              tile
              small
              @click="copyAccessKey"
            >
              Copy Acceess Key
            </v-btn>
            <v-btn
              color="primary"
              small
              depressed
              tile
              @click="regenerateAccessKey"
              :loading="loadingKey"
            >
              Re-generate Key
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
    <v-row justify="center">
      <v-dialog v-model="runCommandModal" persistent max-width="600px">
        <v-card>
          <v-card-text>
            <v-container class="py-8">
              <v-row>
                <v-col cols="12" sm="12">
                  <v-select
                    dense
                    v-model="selectCommands"
                    :items="getCommandList"
                    filled
                    label="Select command"
                  ></v-select>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="6">
                  <v-menu
                    ref="fromcMenu"
                    v-model="fromcMenu"
                    :close-on-content-click="false"
                    :return-value.sync="fromCmDate"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="fromCmDate"
                        label="From"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      :max="toCmDate"
                      v-model="fromCmDate"
                      type="month"
                      no-title
                      scrollable
                    >
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="fromcMenu = false">
                        Cancel
                      </v-btn>
                      <v-btn
                        small
                        tile
                        depressed
                        color="primary"
                        @click="$refs.fromcMenu.save(fromCmDate)"
                      >
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-menu>
                </v-col>
                <v-col v-if="selectCommands != 2" cols="12" sm="6">
                  <v-menu
                    ref="tocMenu"
                    v-model="tocMenu"
                    :close-on-content-click="false"
                    :return-value.sync="toCmDate"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="toCmDate"
                        label="To"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      :min="fromCmDate"
                      v-model="toCmDate"
                      type="month"
                      no-title
                      scrollable
                    >
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="tocMenu = false">
                        Cancel
                      </v-btn>
                      <v-btn
                        text
                        color="primary"
                        @click="$refs.tocMenu.save(toCmDate)"
                      >
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-menu>
                </v-col>

                <v-col v-if="selectCommands == 2" cols="12" sm="6">
                  <v-select
                    dense
                    v-model="cmdMasterIds"
                    :items="getMasterAccountsList"
                    filled
                    label="Select command"
                    multiple
                  ></v-select>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="runCommandModal = false"> Close </v-btn>
            <v-btn
              class="ma-2 white--text"
              color="blue-grey"
              tile
              :disabled="disableCmdSaveButton"
              @click="runCommand()"
            >
              Run
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
    <v-row justify="center">
      <v-dialog v-model="switchAccountModal" persistent max-width="600px">
        <v-card>
          <v-card-title>
            <span class="text-h5">Switch Accounts</span>
          </v-card-title>
          <v-card-text>
            <v-simple-table>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">User</th>
                    <th class="text-left">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in switchAccountsUser" :key="item.id">
                    <td>{{ item.name + " - " + item.email }}</td>
                    <td>
                      <v-btn
                        class="ma-2 white--text"
                        color="blue-grey"
                        small
                        tile
                        depressed
                        @click="switchAccount(item)"
                      >
                        Switch
                      </v-btn>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="switchAccountModal = false"> Close </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
    <GoogleDrive
      minimized-popup-title="View Google Drive"
      ref="googleDrive"
    ></GoogleDrive>
  </v-app>
</template>

<script>
import sidebar from "./Sidebar.vue";
import GoogleDrive from "./../google-drive/GoogleDrive.vue";

import _ from "lodash";
export default {
  created() {
    const user = JSON.parse(window.atob(localStorage.getItem("user")));
    this.user.initials = user.name.charAt(0).toUpperCase();
    this.user.fullName = user.name;
    this.user.email = user.email;
    this.user.role = user.role;
  },
  data: () => ({
    cmdMasterIds: [],
    switchAccountModal: false,
    switchAccountsUser: [],
    fromCmDate: null,
    fromcMenu: false,
    toCmDate: null,
    tocMenu: false,
    runCommandModal: false,
    fromRunMonth: null,
    toRunMonth: null,
    commandList: [],
    selectCommands: null,
    saveCompanyDetailsLoading: false,
    loadingSearch: false,
    loadingExport: false,
    accessKeyModal: false,
    accessKey: "",
    loadingKey: false,
    currentPwd: "",
    newPwd: "",
    confNewPwd: "",
    transactions: [],
    expenses: [],
    sheet: false,
    overlay: false,
    drawer: null,
    user: {
      initials: "A",
      fullName: "",
      email: "",
      role: 0,
    },
    masterSearch: "",
    disableSearch: true,
    dateSorted: 1,
    selectYear: 0,
    totalAmount: 0,
    editDialog: false,
    name: "",
    companyLogo: null,
    companyLogoPath: null,
    address: "",
    address2: "",
    city: "",
    state: "",
    country: "",
    pan_no: "",
    tan_no: "",
    gst_no: "",
    pf_no: "",
    esic_no: "",
    company_email: "",
    website: "",
    contact_1: "",
    contact_2: "",
    letterImg: null,
    letterImgPath: null,
    tds_percentage: 0,
    changePasswordModal: false,
    masterAccounts: [],
  }),
  components: {
    sidebar: sidebar,
    GoogleDrive: GoogleDrive,
  },
  computed: {
    getMasterAccountsList() {
      // let accounts = [{ text: "All", value: 0 }];
      let accounts = [];
      this.masterAccounts.forEach((ele) => {
        accounts.push({
          text: this.getItemName(ele.master_account) + " - " + ele.branch.name,
          value: ele.id,
        });
      });
      return accounts;
    },
    disableCmdSaveButton() {
      return this.fromCmDate == null || this.selectCommands == null;
    },
    getCommandList() {
      return this.commandList;
    },
    getYearList() {
      let years = [
        {
          text: "All",
          value: 0,
        },
      ];
      for (let i = 2019; i <= new Date().getFullYear(); i++) {
        years.push({
          text: i,
          value: i,
        });
      }
      return years;
    },
  },
  methods: {
    switchAccount(item) {
      this.overlay = true;
      this.$http
        .post(
          "switch-accounts",
          {
            id: item.id,
          },
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("access_token"),
            },
          }
        )
        .then((response) => {
          localStorage.setItem("access_token", response.data.access_token);
          localStorage.setItem(
            "permission",
            window.btoa(
              JSON.stringify(response.data.permissions.map((item) => item.slug))
            )
          );
          localStorage.setItem(
            "user",
            window.btoa(JSON.stringify(response.data.user))
          );
          window.location.reload();
        })
        .catch(({ response }) => {
          this.$toast.error(response.data.error.message.join(" , "));
          this.overlay = false;
        });
    },
    fetchAttachUserDetails() {
      this.overlay = true;
      this.$http
        .get("get-attach-user", {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("access_token"),
          },
        })
        .then((response) => {
          this.switchAccountsUser = response.data.data.users;
          this.switchAccountModal = true;
          this.overlay = false;
        })
        .catch(({ response }) => {
          this.$toast.error(response.data.error.message.join(" , "));
          this.overlay = false;
        });
    },
    runCommand() {
      this.overlay = true;
      this.$http
        .post(
          "run-commands",
          {
            from_date: this.fromCmDate,
            to_date: this.toCmDate,
            commands: this.selectCommands,
            accountsId: this.cmdMasterIds,
          },
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("access_token"),
            },
          }
        )
        .then((response) => {
          this.$toast.success(response.data.data.message.join(" , "));
          this.overlay = false;
        })
        .catch(({ response }) => {
          this.$toast.error(response.data.error.message.join(" , "));
          this.overlay = false;
        });
    },
    fetchCommandDetails() {
      this.overlay = true;
      this.$http
        .get("get-commands-list", {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("access_token"),
          },
        })
        .then((response) => {
          const data = response.data.data;
          this.commandList = data.commands;
          this.masterAccounts = data.accounts;
          this.overlay = false;
          this.runCommandModal = true;
        })
        .catch(() => {
          this.$toast.error("Error on regenerate access key");
          this.overlay = false;
        });
    },
    copyAccessKey() {
      let copyText = document.getElementById("accessKeyInput");
      copyText.disabled = false;
      copyText.select();
      copyText.setSelectionRange(0, 99999);
      copyText.disabled = true;
      window.document.execCommand("copy");
    },
    regenerateAccessKey() {
      this.loadingKey = true;
      this.$http
        .get("regenerate-access-key", {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("access_token"),
          },
        })
        .then((response) => {
          if (response.status == 200) {
            const accessKey = response.data.data.access_key;
            this.accessKey = accessKey.value;
          }
          this.loadingKey = false;
        })
        .catch(() => {
          this.$toast.error("Error on regenerate access key");
          this.loadingKey = false;
        });
    },
    fetchAccessKey() {
      this.overlay = true;

      this.$http
        .get("access-key", {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("access_token"),
          },
        })
        .then((response) => {
          if (response.status == 200) {
            const accessKey = response.data.data.access_key;
            this.accessKey = accessKey.value ?? "";
          }
          this.overlay = false;
          this.accessKeyModal = true;
        })
        .catch((error) => {
          console.log(error);
          this.$toast.error("Error on fetching Access Key");
          this.overlay = false;
        });
    },
    downloadDB() {
      // search/export?key=${masterSearch}
      this.overlay = true;
      this.$http
        .get(`database/export`, {
          responseType: "blob",
          headers: {
            Authorization: "Bearer " + localStorage.getItem("access_token"),
          },
        })
        .then((response) => {
          this.overlay = false;
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          const fileName =
            new Date().toISOString().substr(0, 10) + "_codexive_database.sql";
          link.setAttribute("download", fileName.replace(/-/g, "_")); //or any other extension
          document.body.appendChild(link);
          link.click();
        })
        .catch((error) => {
          this.overlay = false;
          console.log(error);
        });
    },
    changePassword() {
      this.overlay = true;
      if (this.currentPwd == "" || this.newPwd == "" || this.confNewPwd == "") {
        this.$toast.error("Please fill out all fields");
        return;
      }
      let formData = new FormData();
      formData.append("current_password", this.currentPwd);
      formData.append("new_password", this.newPwd);
      formData.append("confirm_new_password", this.confNewPwd);

      this.$http
        .post("change-password", formData, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("access_token"),
          },
        })
        .then((response) => {
          if (response.status == 200) {
            this.$toast.success(response.data.data.message.join(" , "));
            this.logout();
          }
          this.overlay = false;
        })
        .catch(({ response }) => {
          if (response.status == 422) {
            this.$toast.error(
              response.data.errors.confirm_new_password.join(" , ")
            );
          } else if (response.status == 400) {
            this.$toast.error(response.data.error.message.join(" , "));
          } else {
            this.$toast.error("Something went wrong");
          }
          this.overlay = false;
        });
    },
    exportSearch() {
      // search/export?key=${masterSearch}
      this.loadingExport = true;
      this.$http
        .get(`search/export?key=${this.masterSearch}`, {
          responseType: "blob",
          headers: {
            Authorization: "Bearer " + localStorage.getItem("access_token"),
          },
        })
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          const fileName = "(" + this.masterSearch + ")_search.xlsx";
          link.setAttribute("download", fileName); //or any other extension
          document.body.appendChild(link);
          link.click();
          this.loadingExport = false;
        })
        .catch((error) => {
          console.log(error);
          this.loadingExport = false;
        });
    },
    getItemName(item) {
      if (item.account_type_id == 1 && item.acc_number != null)
        return (
          item.name +
          " " +
          (item.last_name || "") +
          " (" +
          item.acc_number.substring(item.acc_number.length - 4) +
          ")"
        );
      return item.name + " " + (item.last_name || "");
    },
    getFullName(arr) {
      let fullName =
        (this.searchHighlight(arr.name) || "") +
        " " +
        (this.searchHighlight(arr.middle_name) || "") +
        " " +
        (this.searchHighlight(arr.last_name) || "");
      return fullName;
    },
    searchHighlight(str) {
      if (str) {
        let char = str.match(new RegExp(this.masterSearch, "i"));
        if (char) {
          return str.replace(
            char[0],
            `<span style="background: #ffff00">${char[0]}</span>`
          );
        }
      }
      return str;
    },
    isAccess() {
      return this.$permission.userPermissions([
        "add-account",
        "view-account",
        "edit-account",
        "delete-account",
        "view-master-account-page",
      ]);
    },
    filterDate() {
      if (this.dateSorted == 1) {
        this.transactions = _.orderBy(this.transactions, ["date"], ["asc"]);
        this.expenses = _.orderBy(this.expenses, ["date"], ["asc"]);
        this.dateSorted = 0;
      } else {
        this.transactions = _.orderBy(this.transactions, ["date"], ["desc"]);
        this.expenses = _.orderBy(this.expenses, ["date"], ["desc"]);
        this.dateSorted = 1;
      }
    },
    goToTransaction(value, type) {
      let routeData = null;
      if (type == 1) {
        routeData = this.$router.resolve({
          name: "transactions",
          params: { trId: value.id },
        });
      } else if (type == 2) {
        routeData = this.$router.resolve({
          name: "expense",
          params: { exId: value.id },
        });
      }
      if (routeData) {
        window.open(routeData.href, "_blank");
      }
    },
    logout() {
      localStorage.removeItem("access_token");
      localStorage.removeItem("permission");
      localStorage.removeItem("user");
      this.$router.replace("/");
    },
    // dbExport() {
    //   this.overlay = true;
    //   setTimeout(() => {
    //     this.$http
    //       .get("database/export", {
    //         headers: {
    //           Authorization: "Bearer " + localStorage.getItem("access_token"),
    //         },
    //       })
    //       .then(({ data }) => {
    //         if (data.status == 200) {
    //           this.overlay = false;
    //           this.$toast.success(data.data.message.join(", "));
    //         }
    //       })
    //       .catch(({ response }) => {
    //         this.overlay = false;
    //         this.$toast.error("Something went wrong");
    //       });
    //   }, 1000);
    // },
    fetchMasterSearch() {
      this.loadingSearch = true;
      this.$http
        .get("master-search", {
          params: {
            key: this.masterSearch.trim(),
            year: this.selectYear,
          },
          headers: {
            Authorization: "Bearer " + localStorage.getItem("access_token"),
          },
        })
        .then(({ data }) => {
          this.transactions = data.data.masterResult;
          console.log(this.transactions);
          this.expenses = data.data.expenses;
          this.sheet = true;
          this.totalAmount = 0;
          this.transactions.forEach((transaction) => {
            this.totalAmount += transaction.amount;
          });
          this.expenses.forEach((expense) => {
            this.totalAmount += expense.amount;
          });
          this.loadingSearch = false;
        })
        .catch(({ response }) => {
          this.overlay = false;
          this.$toast.error("Something went wrong");
          this.loadingSearch = false;
        });
    },
    fetchCompanyDetails() {
      this.overlay = true;
      this.$http
        .get("company-details", {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("access_token"),
          },
        })
        .then((response) => {
          const companyDetails = response.data.data.companies;
          this.name = companyDetails.name.value;
          this.companyLogo = null;
          this.companyLogoPath = companyDetails.company_logo.company_logo_url;
          this.address = companyDetails.address.value;
          this.pan_no = companyDetails.pan_no.value;
          this.tan_no = companyDetails.tan_no.value;
          this.gst_no = companyDetails.gst_no.value;
          this.pf_no = companyDetails.pf_no.value;
          this.esic_no = companyDetails.esic_no.value;
          this.address2 = companyDetails.address2.value;
          this.contact_1 = companyDetails.contact_1.value;
          this.contact_2 = companyDetails.contact_2.value;
          this.city = companyDetails.city.value;
          this.state = companyDetails.state.value;
          this.country = companyDetails.country.value;
          this.website = companyDetails.website.value;
          this.company_email = companyDetails.company_email.value;
          this.letterImg = null;
          this.letterImgPath = companyDetails.letter_image.image_url;
          this.tds_percentage = companyDetails.tds_percentage ? companyDetails.tds_percentage.value : 0;
          this.editDialog = true;
          this.overlay = false;
        })
        .catch((error) => {
          this.$toast.error("Something went wrong");
          this.overlay = false;
        });
    },
    saveCompanyDetails() {
      this.saveCompanyDetailsLoading = true;
      var data = new FormData();
      data.append("name", this.name || 0);
      data.append("address", this.address || 0);
      data.append("address2", this.address2 || 0);
      data.append("pan_no", this.pan_no || 0);
      data.append("tan_no", this.tan_no || 0);
      data.append("gst_no", this.gst_no || 0);
      data.append("pf_no", this.pf_no || 0);
      data.append("esic_no", this.esic_no || 0);
      data.append("contact_1", this.contact_1 || 0);
      data.append("contact_2", this.contact_2 || 0);
      data.append("website", this.website || 0);
      data.append("city", this.city || 0);
      data.append("state", this.state || 0);
      data.append("country", this.country || 0);
      data.append("company_email", this.company_email || 0);
      data.append("tds_percentage", this.tds_percentage || 0);
      if (this.letterImg != null) data.append("letter_image", this.letterImg);
      if (this.companyLogo != null)
        data.append("company_logo", this.companyLogo);

      this.$http
        .post("company-details", data, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("access_token"),
          },
        })
        .then(({ data }) => {
          if (data.status == 200) {
            this.$toast.success(data.data.message.join(", "));
            this.editDialog = false;
          }
          this.saveCompanyDetailsLoading = false;

          window.location.reload();
        })
        .catch(({ response }) => {
          this.$toast.error("Something went wrong");
          this.saveCompanyDetailsLoading = false;
        });
    },
    getTransactionType(id) {
      if (id === 0) return "Cash";
      if (id === 1) return "Cheque";
      if (id === 2) return "Transfer";
      if (id === 3) return "Forex";
    },
    getPurposeType(id) {
      if (id === 0) return "Normal";
      if (id === 1) return "Salary";
      if (id === 2) return "Upad";
      if (id === 3) return "Income";
      if (id === 4) return "Self Credit";
    },
  },
  watch: {
    masterSearch(value) {
      let val = value.trim();
      if (val !== "") {
        this.disableSearch = false;
      } else {
        this.disableSearch = true;
      }
    },
  },
};
</script>
<style>
.menu {
  display: flex;
  place-content: flex-end;
}

.v-bottom-sheet.v-dialog {
  overflow: auto !important;
}

.cursor-pointer {
  cursor: pointer;
  user-select: none;
}

.badge {
  font-size: 13px;
  margin-left: 10px;
  background: lightgray;
  padding: 2px;
  font-weight: bold;
  border-radius: 4px;
}
</style>
