const roles = {
    admin: 1,
    company: 2,
    user: 3,
    ca: 4,
}

export const defaultRedirectionLink = {
    [roles.admin]: 'home',
    [roles.company]: 'home',
    [roles.user]: 'home',
    [roles.ca]: 'transactions',
}

export const redirectToDefaultRoute = () => {
    const user = JSON.parse(window.atob(localStorage.getItem('user')));
    return defaultRedirectionLink[user.role];
}